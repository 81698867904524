import {Box, Button, Collapse, IconButton, MenuItem, Modal, Radio, Select, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {brown, grey, yellow} from "@mui/material/colors";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setOrderDateTime, setUserInformation} from "../../features/userSlice";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import {orderTypeSelectionModalStyles} from "../../css/checkoutStyles";
import {RestaurantTimeSlotsData} from "../../features/restaurantSlice";

dayjs.extend(isBetween);

const OrderTypeSelectionModal = ({type, setType, setOpenModal, openModal}) => {
    const [selectedValue, setSelectedValue] = useState(type.realType)
    const [time, setTime] = useState('schedule')
    const dispatch = useDispatch()
    const [showCollapser, setShowCollapser] = useState({
        open: false,
        message: ''
    })

    const restaurantData = useSelector((state)=> state.restaurantData.specificRestaurantTimeSlotsData)

    const [dateTime, setDateTime] = useState({})

    const filterSlots = () => {
        const todayDate = dayjs()
        const filteredSlots = restaurantData.map((slot, ind) => {
            const slotDate = dayjs(slot.dbDate)
            const checkDate = slotDate.isSame(todayDate, "day")
            if (checkDate) {
                const filteredLocationSlots = slot.location_slots.filter((item, index)=> {
                    //(todayDate.isBefore(dayjs(`${slot.dbDate} ${item.end_time}`)))
                    return todayDate.isBefore(dayjs(`${slot.dbDate} ${item.end_time}`))
                })
                return {
                    ...slot,
                    location_slots: filteredLocationSlots
                }
            } else {
                return slot
            }
        })
        dispatch(RestaurantTimeSlotsData(filteredSlots));
    }

    const setDefaultValues = () => {
        if (!dateTime.location_time_id && !dateTime.slot) {
            let flag = false;
            let data = {};
            restaurantData.map((item, index) => {
                if (item.location_slots.length > 0 && !flag) {
                    //(item)
                    data = {
                        index: index,
                        date: item.date,
                        slot: item.location_slots[0].location_slot_id,
                        location_time_id: item.location_time_id
                    }
                    flag = true
                }
            })
            //(data)
            setDateTime(data)
        }
    }

    useEffect(() => {
        filterSlots()
        setDefaultValues()
    }, [dateTime]);

    const types = [
        {
            name: 'Delivery',
            label: 'delivery'
        },

        /*{
            name: 'Dinein',
            label: 'dinein'
        },*/

        {
            name: 'Pickup',
            label: 'takeaway'
        }
    ]

    const times = [
        {
            name: "Now",
            label: 'now'
        },
        {
            name: "Schedule for Later",
            label: 'schedule'
        }
    ]

    const handleDateChange = (e) => {
        for (let i = 0; i < restaurantData.length; i++) {
            if (restaurantData[i].location_time_id === e.target.value) {
                setDateTime({...dateTime, index: i, date: restaurantData[i].date, slot: '', location_time_id: e.target.value})
            }
        }
    }

    const handleSlotChange = (e) => {
        for (let i = 0; i < restaurantData[dateTime.index].location_slots.length; i++) {
            if (restaurantData[dateTime.index].location_slots[i].location_slot_id === e.target.value) {
                setDateTime({...dateTime, slot: e.target.value, slot_start_time: restaurantData[dateTime.index].location_slots[i].start_time, slot_end_time: restaurantData[dateTime.index].location_slots[i].end_time})
            }
        }
    }

    const handleSetTime = (label) => {
        const todayDate = dayjs()
        if (label === "now") {
            let flag = false
            restaurantData.map((slot, ind) => {
                const slotDate = dayjs(slot.dbDate)
                const checkDate = slotDate.isSame(todayDate, "day")
                if (checkDate) {
                    const isOverallTimeBetween = todayDate.isBetween(dayjs(`${slot.dbDate} ${slot.start_time}`), dayjs(`${slot.dbDate} ${slot.end_time}`), null, '[]');
                    if (isOverallTimeBetween) {
                        slot.location_slots.map((timeSlot) => {
                            //(time)
                            //(dayjs(`${slot.dbDate} ${timeSlot.start_time}`))
                            const isTimeBetween = todayDate.isBetween(dayjs(`${slot.dbDate} ${timeSlot.start_time}`), dayjs(`${slot.dbDate} ${timeSlot.end_time}`), null, '[]');
                            if (isTimeBetween && !flag) {
                                setDateTime({
                                    ...dateTime,
                                    index: ind,
                                    date: slot.date,
                                    slot: timeSlot.location_slot_id,
                                    location_time_id: slot.location_time_id,
                                    slot_start_time: timeSlot.start_time,
                                    slot_end_time: timeSlot.end_time
                                })
                                (timeSlot)
                                flag = true;
                            }
                        })
                    } else {
                        setShowCollapser({
                            open: true,
                            message: "No Delivery Option Available for Today"
                        })
                    }
                }
            })
        } else {
            const filteredSlots = restaurantData.map((slot, ind) => {
                const slotDate = dayjs(slot.dbDate)
                const checkDate = slotDate.isSame(todayDate, "day")
                if (checkDate) {
                    const filteredLocationSlots = slot.location_slots.filter((item, index)=> {
                        //(todayDate.isBefore(dayjs(`${slot.dbDate} ${item.end_time}`)))
                        return todayDate.isBefore(dayjs(`${slot.dbDate} ${item.end_time}`))
                    })
                    return {
                        ...slot,
                        location_slots: filteredLocationSlots
                    }
                } else {
                    return slot
                }
            })

            //(filteredSlots)
            dispatch(RestaurantTimeSlotsData(filteredSlots));
            setShowCollapser({
                open: false,
                message: ''
            })
        }
        setTime(label)
        //(showCollapser)
        //(dateTime)
    }

    const handleChange = (event) => {
        setSelectedValue(event.target.value)
    }

    const handleSaveProcess = () => {
        setType({...type, ['sendingType']: selectedValue})
        const orderDateTime = {
            location_time_id: dateTime.location_time_id,
            slot: dateTime.slot,
            deliver_date: restaurantData[dateTime.index].dbDate,
            schedule: time,
            start_time: dayjs('1/1/1 ' + dateTime.slot_start_time).format('hh:mm A'),
            end_time: dayjs('1/1/1 ' + dateTime.slot_end_time).format('hh:mm A'),
            formattedDate: restaurantData[dateTime.index].date
        }
        dispatch(setOrderDateTime({
            orderDateTime: orderDateTime
        }))
        dispatch(setUserInformation({
            order_type: selectedValue
        }))
        setOpenModal(false)
    }

    return(
        <Modal open={openModal} onClose={()=> setOpenModal(false)} sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Stack sx={orderTypeSelectionModalStyles.mainStack}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Order Type</Typography>
                    <IconButton size='small' sx={{backgroundColor: grey[500]}} onClick={()=> setOpenModal(false)}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'white'}} />
                    </IconButton>
                </Stack>
                <Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
                <Stack sx={{overflow: 'auto'}}>
                    <Stack>
                        {
                            types.map((item, index)=> (
                                <Stack onClick={()=> setSelectedValue(item.label)} key={index} sx={orderTypeSelectionModalStyles.orderTypeRadioStack}>
                                    <Radio
                                        color='success'
                                        size='small'
                                        checked={selectedValue === item.label}
                                        onChange={handleChange}
                                        value={item.label}
                                    />
                                    <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}>{item.name}</Typography>
                                </Stack>
                            ))
                        }
                    </Stack>
                    <Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
                    <Stack sx={{padding: '0.6rem 0.75rem'}} rowGap='0.5rem'>
                        <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Desired Delivery Time</Typography>
                        <Stack direction='row' sx={{borderRadius: '5px', overflow: 'hidden', width: 'fit-content'}}>
                            {
                                times.map((item, index)=> (
                                    <Box key={index} onClick={()=> handleSetTime(item.label)} sx={{ ...orderTypeSelectionModalStyles.orderTypeItemBox, backgroundColor: (time === item.label ? 'black' : grey[200])}}>
                                        <Typography variant='body2' sx={{fontSize: '0.8rem', color: (time === item.label ? 'white' : 'black'), transition: '0.5s'}}>{item.name}</Typography>
                                    </Box>
                                ))
                            }
                        </Stack>
                        <Collapse in={time === 'schedule' && dateTime && (dateTime.location_time_id || dateTime.slot)} unmountOnExit timeout={750}>
                            <Stack rowGap='0.75rem' sx={{marginTop: '0.5rem'}}>
                                <Select
                                    variant='outlined'
                                    placeholder={"Please, Select a Date"}
                                    value={dateTime.location_time_id}
                                    onChange={(e)=> handleDateChange(e)}
                                >
                                    {
                                        restaurantData.map((item, index)=> (
                                            <MenuItem key={index} name={index} value={item.location_time_id}>{index === 0 ? 'Today,' : index === 1 ? 'Tomorrow,' : ''} {item.date}</MenuItem>
                                        ))
                                    }
                                </Select>
                                { restaurantData[dateTime.index]?.location_slots?.length > 0 && <Select
                                    variant='outlined'
                                    placeholder={"Please, Select a Slot"}
                                    value={dateTime.slot}
                                    onChange={(e)=> handleSlotChange(e)}
                                >
                                    {
                                        restaurantData[dateTime.index]?.location_slots.map((item, ind)=> (
                                            <MenuItem key={ind} value={item.location_slot_id}>{dayjs('1/1/1 ' + item.start_time).format('hh:mm A')} - {dayjs('1/1/1 ' + item.end_time).format('hh:mm A')}</MenuItem>
                                        ))
                                    }
                                </Select>
                                }
                                    <Collapse in={restaurantData[dateTime.index]?.location_slots.length === 0} unmountOnExit>
                                        <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                                            <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>No Delivery Option Available for Today</Typography>
                                        </Stack>
                                    </Collapse>

                            </Stack>
                        </Collapse>
                        <Collapse in={showCollapser.open} unmountOnExit>
                            <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                                <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>{showCollapser.message}</Typography>
                            </Stack>
                        </Collapse>
                    </Stack>
                </Stack>
                <Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
                <Button disabled={showCollapser.open || restaurantData[dateTime.index]?.location_slots.length === 0} sx={orderTypeSelectionModalStyles.saveButton} fullWidth onClick={()=> handleSaveProcess()}>Save</Button>
            </Stack>
        </Modal>
    )
}

export default OrderTypeSelectionModal