import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Button} from "@mui/material";
import {largeScreenCart} from "../../css/restaurantPageStyles";
import dayjs from "dayjs";
import {createDirectOrder} from "../../services/DirectOrderService";
import socketService from "../../services/SocketService";
import {t} from "i18next";
import {useState} from "react";
import {useSelector} from "react-redux";
import {getToken} from "../../services/AuthService";

const Payment = ({setOrderData, setSnackbarParams, setOpenModal, paymentIntent}) => {
    const stripe = useStripe()
    const elements = useElements()
    const [intent, setIntent] = useState(paymentIntent)
    const token = getToken();

    const { cart, totalamount, subtotal, totaltax, loc_id, session_owner } = useSelector((state) => state.allCart);
    const user = useSelector((state) => state.user.user);
    const specificRestaurantData = useSelector((state) => state.restaurantData.specificRestaurantData);

    const handleOrderTypeModalSave = async () => {
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const currentSeconds = currentTime.getSeconds();
        const formattedCurrentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;
        const year = currentTime.getUTCFullYear();
        const month = ("0" + (currentTime.getUTCMonth() + 1)).slice(-2);
        const day = ("0" + currentTime.getUTCDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;

        let finalAmount = 0;
        let delivery_fee = 0;

        if (user.order_type === 'delivery') {
            if (totalamount > specificRestaurantData.amount_threshold) {
                finalAmount = totalamount
                delivery_fee = 0;
            } else {
                finalAmount = totalamount + specificRestaurantData.delivery_fee
                delivery_fee = specificRestaurantData.delivery_fee
            }
        } else {
            finalAmount = totalamount
            delivery_fee = 0;
        }

        let order_owner = []
        if (user.order_type === 'takeaway') {
            order_owner = [
                {
                    name: (token && user && user.loggedInUserInformation && user.loggedInUserInformation.id && user.user_type === 'logged_in') ? user.loggedInUserInformation.name : user.firstName + ' ' + user.lastName,
                    email: (token && user && user.loggedInUserInformation && user.loggedInUserInformation.id && user.user_type === 'logged_in' && user.loggedInUserInformation.email) ? user.loggedInUserInformation.email : null,
                    number: user.phoneNumber,
                    location_slot_id: user.orderDateTime.slot,
                    deliver_date: dayjs().format("YYYY-MM-DD HH:mm"),
                    customer_id: (token && user && user.loggedInUserInformation && user.loggedInUserInformation.id) ? user.loggedInUserInformation.id : null
                }
            ]
        } else if (user.order_type === 'delivery') {
            order_owner = [
                {
                    name: (token && user && user.loggedInUserInformation && user.loggedInUserInformation.id && user.user_type === 'logged_in') ? user.loggedInUserInformation.name : user.firstName + ' ' + user.lastName,
                    email: (token && user && user.loggedInUserInformation && user.loggedInUserInformation.id && user.user_type === 'logged_in' && user.loggedInUserInformation.email) ? user.loggedInUserInformation.email : null,
                    number: user.phoneNumber,
                    address: user.location.address,
                    longitude: user.location.long,
                    latitude: user.location.lat,
                    location_slot_id: user.orderDateTime.slot,
                    deliver_date: user.orderDateTime.deliver_date,
                    customer_id: user.loggedInUserInformation.id ? user.loggedInUserInformation.id : null
                }
            ]
        }

        const orderData = {
            user_id: null,
            order_owner,
            total_items: cart.length,
            order_time: formattedCurrentTime,
            order_date: formattedDate,
            order_type: (user.order_type === 'pickup' ? 'takeaway' : user.order_type),
            start_time: formattedCurrentTime,
            order_type_id: 2,
            order_variant: "small",
            payment_intent_id: intent,
            discount_id: 1,
            total_discount: 0,
            totalPrice: finalAmount,
            comment: "",
            qrcode: "",
            qrcodedata: "",
            promocode_id: null,
            payment_status_id: 2,
            order_tax: totaltax,
            location_id: loc_id,
            delivery_fee: delivery_fee,
            menu_items: cart,
        };

        const orderResponse = await createDirectOrder(orderData);
        if (!orderResponse.error) {
            socketService.emit("order_placed", {
                order_id: orderResponse?.data?.order?.order_id,
            });

            if (orderResponse.data.order.order_status === 'placed') {
                setSnackbarParams({
                    loading: false,
                    message: t("Order Placed Successfully"),
                    severity: "success",
                    open: true,
                    paymentStatus: 'confirmed'
                })
                setOrderData(orderResponse)
            } else {
                setSnackbarParams({
                    loading: false,
                    message: t("Order Placement Failed"),
                    severity: "error",
                    open: true,
                })
            }
        } else {
            setSnackbarParams({
                loading: false,
                message: t(orderResponse.message),
                severity: "error",
                open: true,
            })
        }
        setOpenModal(false)
    };

    const handlePaymentSubmit = async () => {
        if (!stripe || !elements) {
            return
        }


        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {},
                redirect: 'if_required'
            });

            if (error) {
                console.log(error.message);
                setSnackbarParams({
                    open: true,
                    message: "Payment Confirmation Failed",
                    severity: "error",
                    loading: false
                });
            } else if (paymentIntent.status === "requires_capture") {
                await handleOrderTypeModalSave()
            }
        } catch (error) {
            console.error("Error confirming payment:", error);
            setSnackbarParams({
                open: true,
                message: "Payment Confirmation Failed",
                severity: "error",
                loading: false
            });
            setOpenModal(false)
        }
    }

    return(
        <>
            <PaymentElement />
            <Button sx={{...largeScreenCart.checkoutButton, textAlign: 'center', justifyContent: 'center'}} onClick={()=> handlePaymentSubmit()}>
                Proceed
            </Button>
        </>
    )
}

export default Payment;