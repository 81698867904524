import React, {useEffect, useRef, useState} from "react";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {URLS} from "../../utils/constants";
import ItemModal from "./itemModal";
import {gridView} from "../../css/restaurantPageStyles";
import {useSelector} from "react-redux";
import {MAIN_GREEN_COLOR} from "../../colors";
import {calculateTotalPrice} from "../../utils/cartDataHelpers";
import CartFromOtherLocation from "../cartFromOtherLocation";

const ItemsGridView = ({setSelectedTab}) => {
    const [modalItem, setModalItem] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [cartFromOtherLocation, setCartFromOtherLocation] = useState(false)

    const data = useSelector((state)=> state.restaurantData.specificRestaurantMenuData)
    const restaurant = useSelector((state)=> state.restaurantData.specificRestaurantData)

    const handleItemAdd = (item) => {
        setModalItem(item)
        setOpenModal(true)
    }

    const sectionRefs = useRef([]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.27,
        };
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setSelectedTab(entry.target.id);
                }
            });
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        sectionRefs.current.forEach((ref) => {
            observer.observe(ref);
        });
        return () => {
            observer.disconnect();
        };
    }, []);

    return(
        <Stack sx={{rowGap: '1rem'}}>
            {data.map((item, index)=>(
                <Stack key={item.category_id} id={`${item.category_id}`} ref={(el) => (sectionRefs.current[index] = el)}>
                    <Stack sx={{rowGap: '0.5rem'}}>
                        <Typography variant='h6' sx={{fontSize: '16px', fontWeight: '600'}}>
                            {item.name}
                        </Typography>
                        <Grid container spacing={{xs: 2, sm: 2, md: 2, lg: 3, xl: 4}}>
                            {item.items.map((catItems, catItemsIndex)=>(
                                <Grid key={catItems.menu_id} item xs={12} sm={12} md={4} lg={3} xl={3} sx={{display: 'flex', alignItems: 'stretch'}}>
                                    <Stack sx={gridView.itemMainStack}>
                                        <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + catItems.menu_photo}`} alt={catItems.name} sx={gridView.itemImage}/>
                                        <Stack sx={gridView.infoStack}>
                                            <Stack direction='row' sx={{alignItems: 'center'}}>
                                                <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>{catItems.menu_name}</Typography>
                                            </Stack>
                                            <Typography variant='body2' sx={{fontSize: '12px'}}>
                                                {catItems.menu_description}
                                            </Typography>
                                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                                <Typography variant='body2' sx={{fontSize: '15px', fontWeight: '500'}}>
                                                    {restaurant.Currency.currency_symbol + calculateTotalPrice(
                                                        catItems.menu_price,
                                                        catItems.menu_tax,1)}
                                                </Typography>
                                                <IconButton onClick={()=> handleItemAdd(catItems)}>
                                                    <AddCircleIcon sx={{color: MAIN_GREEN_COLOR, height: '30px', width: '30px'}} />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Stack>
            ))
            }
            {modalItem !== null && <ItemModal setCartFromOtherLocation={setCartFromOtherLocation} itemName={modalItem} setModalItem={setModalItem} openModal={openModal} setOpenModal={setOpenModal}/>}
            { cartFromOtherLocation && <CartFromOtherLocation setCartFromOtherLocation={setCartFromOtherLocation} cartFromOtherLocation={cartFromOtherLocation} /> }
        </Stack>
    )
}

export default ItemsGridView