import {Box, Button, Divider, IconButton, Stack, Typography} from "@mui/material";
import {largeScreenCart} from "../../css/restaurantPageStyles";
import {URLS} from "../../utils/constants";
import {blue, grey} from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {clearCart, removeItem} from "../../features/cartSlice";
import CircularProgress from "@mui/material/CircularProgress";
import {t} from "i18next";
import SnackBar from "../snackbar";
import {getOrderPaymentIntent} from "../../services/BuyingService";
import PaymentModal from "./paymentModal";
import placeholder_img from "../../assets/placeholder_restaurant.png";
import {KeyboardArrowRight} from "@mui/icons-material";
import {
    calculateTotalPrice,
    calculateVariantPrice,
    handleCartDecrement,
    handleCartIncrement
} from "../../utils/cartDataHelpers";
import UserCheckoutErrorMessages from "./userCheckoutErrorMessages";
import {getToken} from "../../services/AuthService";

const CheckoutCart = () => {
    const [keys, setKeys] = useState({
        clientSecret: '',
        publicKey: ''
    })
    const [orderData, setOrderData] = useState(null)
    const [openPaymentModal, setPaymentModal] = useState(false)
    const [params, setParams] = useState({
        loading: false,
        paymentStatus: 'ineligible'
    });
    const { cart, totalamount, subtotal, totaltax, loc_id, session_owner } = useSelector((state) => state.allCart);
    const user = useSelector((state) => state.user.user);
    const token = getToken();

    const dispatch = useDispatch();
    const specificRestaurantData = useSelector(
        (state) => state.restaurantData.specificRestaurantData
    );

    const navigate = useNavigate()

    useEffect(() => {
        if (params.paymentStatus === 'confirmed') {
            const timeout = setTimeout(() => {
                handleClearCart()
                navigate('/order_success', {state: {orderData}})
            }, 2500)

            return () => clearTimeout(timeout)
        }
    }, [params.paymentStatus]);

    const getPaymentIntent = async () => {
        setParams({
            ...params,
            loading: true,
        })
        try {
            const data = await getOrderPaymentIntent(loc_id, totalamount)
            if (!data.error) {
                const publicKey = JSON.parse(window.atob(data.data.publicKey.split('.')[1]))
                setKeys({
                    publicKey: publicKey.key,
                    clientSecret: data.data.clientSecret,
                    paymentIntentId: data.data.paymentIntentID
                })
                setPaymentModal(true)
            } else {
                setParams({
                    ...params,
                    loading: false,
                    message: t(data.message),
                    severity: "error",
                    open: true,
                })
            }
        } catch (err) {
            console.error(err)
            setParams({
                ...params,
                loading: false,
                message: t("Order Payment Failed"),
                severity: "error",
                open: true,
            })
        }
    }

    const handleIncrement = (itemIndex) => {
        const updatedCart = handleCartIncrement(itemIndex, cart);
        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleDecrement = (itemIndex) => {
        const updatedCart = handleCartDecrement(itemIndex, cart)
        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleRemoveItem = (itemIndex) => {
        const remainItems = cart.filter((item, index) => index !== itemIndex);
        dispatch(removeItem({ itemIndex, items: remainItems }));
    };

    const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
        const itemPrice = optionPrice || 0;
        const tax = taxPercent || 0;
        const variantPrice =
            tax > 0
                ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
                : itemPrice * itemCount;
        return variantPrice;
    };

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    return(<>
            <Stack sx={{backgroundColor: 'white'}}>
                {(cart.length > 0) && (<>
                    <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem'}}>
                        <Box sx={{textAlign: 'center', overflow: 'hidden', fontSize: '0.6rem', height: '50px', minWidth: '50px', width: '50px', borderRadius: '50%', border: `1px solid ${grey[300]}`, objectFit: 'cover', objectPosition: 'center'}} component='img' src={specificRestaurantData.img !== null ? (`${URLS.BaseUrlWithoutWebsite + "/" + specificRestaurantData.img}`) : (`${placeholder_img}`)} alt={specificRestaurantData.name}/>
                        <Stack>
                            <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.4rem', cursor: 'pointer'}} onClick={()=> navigate(`/restaurants/${specificRestaurantData.slug}`)}>
                                <Typography variant='body2' sx={{fontSize: '0.9rem', fontWeight: '600', color: 'black'}}>{specificRestaurantData.name}</Typography>
                                <KeyboardArrowRight sx={{height: '1.1rem', width: '1.1rem', color: "black"}}/>
                            </Stack>
                            <Typography variant='body2' sx={{fontSize: '0.55rem', backgroundColor: blue[200], color: 'black', borderRadius: '0.25rem', padding: '0.125rem 0.25rem', height: 'fit-content', width: 'fit-content'}}>{specificRestaurantData.category_name}</Typography>
                            <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800]}}>
                                {specificRestaurantData.formattedAddress}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Box sx={{height: '1px', backgroundColor: grey[300], width :'100%', marginY: '1rem'}} />
                </>)}
                <Typography vairant='body2' sx={{fontSize: '16px', fontWeight: '600'}}>Cart</Typography>
                {
                    (cart.length > 0) ? (
                        <Stack sx={{rowGap: '1rem'}}>
                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                <Typography variant='body2' sx={largeScreenCart.summaryText}>Summary</Typography>
                                <Typography variant='body2' sx={largeScreenCart.clearButton} onClick={()=> handleClearCart()}>Clear</Typography>
                            </Stack>
                            <Stack sx={{rowGap: '1rem', height: 'calc(100vh - 390px)', overflow: 'auto'}}>
                                {
                                    cart.map((item, index)=> (
                                        <Stack key={index} direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Stack direction='row' sx={{columnGap: {xs: '1rem', md: '0.5rem', lg: '1rem'}}}>
                                                <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + item.menu_photo}`} sx={largeScreenCart.itemImage}/>
                                                <Stack sx={{rowGap: '0.25rem'}}>
                                                    <Typography variant='body2' sx={largeScreenCart.itemNameText}>{item.menu_name}</Typography>
                                                    <Typography variant='body2' sx={largeScreenCart.itemPriceText}>{specificRestaurantData.currency} {item.menu_price}</Typography>
                                                        {
                                                            item.MenuOptions.length > 0 && (item.MenuOptions.map((menu_option, index) => (
                                                                <React.Fragment key={menu_option.option_id}>
                                                                    {index !== 0 && <Divider />}
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                        sx={{fontSize: '0.75rem', fontWeight: 'bold'}}
                                                                    >
                                                                        {menu_option.option_name}
                                                                    </Typography>
                                                                    {menu_option.Option_Values.map(
                                                                        (menu_option_value) => (
                                                                            <Typography
                                                                                key={menu_option_value.option_id}
                                                                                variant="body2"
                                                                                color="textSecondary"
                                                                                sx={{fontSize: '0.7rem', textTransform: 'capitalize'}}
                                                                            >
                                                                                {`${menu_option_value.optionCount}x ${
                                                                                    menu_option_value.value
                                                                                } = ${
                                                                                    menu_option_value.price === 0
                                                                                        ? "Free"
                                                                                        : specificRestaurantData?.currency +
                                                                                        calculateVariantPrice(
                                                                                            menu_option_value.price,
                                                                                            menu_option_value.order_item_tax_percentage,
                                                                                            menu_option_value.optionCount
                                                                                        ).toFixed(2)
                                                                                }`}
                                                                            </Typography>
                                                                        ))}
                                                                </React.Fragment>
                                                            )))
                                                        }
                                                    <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                                                        <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': { backgroundColor: grey[700] } }} onClick={() => handleIncrement(index)}>
                                                            <AddIcon sx={largeScreenCart.iconStyle} />
                                                        </IconButton>
                                                        <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600'}}>
                                                            {item.itemCount}
                                                        </Typography>
                                                        <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': { backgroundColor: grey[700] }}} onClick={() => handleDecrement(index)}>
                                                            <RemoveIcon sx={largeScreenCart.iconStyle}/>
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <Stack sx={{rowGap: '0.5rem', alignItems: 'center'}}>
                                                <IconButton size='small' sx={{backgroundColor: grey[500], width: 'fit-content', '&:hover': { backgroundColor: grey[700] } }} onClick={() => handleRemoveItem(index)}>
                                                    <CloseIcon sx={largeScreenCart.iconStyle}/>
                                                </IconButton>
                                                <Typography variant='body2' sx={{fontSize: {md: '12px', lg: '14px'}, fontWeight: '400'}}>{
                                                    specificRestaurantData?.currency +
                                                    calculateTotalPrice(
                                                        item.menu_price,
                                                        item.menu_tax_percentage,
                                                        item.itemCount
                                                    )}</Typography>
                                            </Stack>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                            <Box sx={{height: '1px', backgroundColor: grey[300], width :'100%'}} />
                            <Stack>
                                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[700]}}>Sub total</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[700]}}>{specificRestaurantData.currency} {subtotal.toFixed(2)}</Typography>
                                </Stack>
                                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[700]}}>Tax</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[700]}}>{specificRestaurantData.currency} {totaltax.toFixed(2)}</Typography>
                                </Stack>
                                {user.order_type === "delivery" && <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[700]}}>Delivery Charges</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[700]}}>{totalamount > specificRestaurantData.amount_threshold ? specificRestaurantData.currency + ' 0.00' : specificRestaurantData.currency + " " + specificRestaurantData.delivery_fee}</Typography>
                                </Stack>}
                                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography variant='body2' sx={{fontSize: '1rem', color: 'black', fontWeight: '600'}}>Total</Typography>
                                    <Typography variant='body2' sx={{fontSize: '1rem', color: 'black', fontWeight: '600'}}>{specificRestaurantData.currency} {user.order_type === 'delivery' ? (totalamount + (totalamount > specificRestaurantData.amount_threshold ? 0 : specificRestaurantData.delivery_fee)).toFixed(2) : totalamount.toFixed(2)}</Typography>
                                </Stack>
                            </Stack>
                            <Button sx={{...largeScreenCart.checkoutButton, justifyContent: (params.loading ? 'space-between' : 'center'),
                                textTransform: 'capitalize', textAlign: 'center', '&.Mui-disabled': { backgroundColor: grey[500], color: 'white' },
                            }} disabled={
                                cart.length <= 0 ||
                                user.phoneNumber === '' ||
                                user.phoneNumber === undefined ||
                                user.phoneNumber === null ||
                                ((!token && !user.loggedInUserInformation.id && user.user_type === 'guest') ? (
                                    user.firstName === '' ||
                                    user.firstName === undefined ||
                                    user.firstName === null ||
                                    user.lastName === '' ||
                                    user.lastName === undefined ||
                                    user.lastName === null
                                ) : (
                                    user.loggedInUserInformation.name === '' ||
                                    user.loggedInUserInformation.name === undefined ||
                                    user.loggedInUserInformation.name === null
                                ))
                                ||
                                ((
                                    totalamount < specificRestaurantData.min_delivery_amount ||
                                    user.isDeliverable !== 'deliverable' ||
                                    user.location.lat === '' ||
                                    user.location.lat === null ||
                                    user.location.lat === undefined ||
                                    user.location.long === ''||
                                    user.location.long === null ||
                                    user.location.long === undefined ||
                                    user.location === null ||
                                    user.location === undefined ||
                                    user.location.address === null ||
                                    user.location.address === undefined ||
                                    user.location.address === '') && user.order_type === 'delivery') ||
                                user.orderDateTime.formattedDate === null ||
                                user.orderDateTime.formattedDate === undefined ||
                                user.orderDateTime.slot === null ||
                                user.orderDateTime.slot === undefined ||
                                ((specificRestaurantData.loc_id !== loc_id) && cart.length > 0) ||
                                (user.order_type !== 'delivery' && user.order_type !== 'takeaway')
                            } onClick={()=> getPaymentIntent()}>Place Order {params.loading && <CircularProgress size={20} sx={{color: 'white'}}/>}</Button>
                            <UserCheckoutErrorMessages />
                        </Stack>
                    ) : (
                        <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                            <LocalMallIcon sx={{height: '50px', width: '50px', color: 'black', marginBottom: '0.5rem'}} />
                            <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>You don't have any orders here!</Typography>
                            <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '400'}}>Let's change that</Typography>
                        </Stack>
                    )
                }
            </Stack>
            <SnackBar setSnackbarParam={setParams} snackbarParams={params} />
            {openPaymentModal && <PaymentModal setOrderData={setOrderData} openPaymentModal={openPaymentModal} setOpenPaymentModal={setPaymentModal} keys={keys} setSnackbarParams={setParams}/> }
        </>
    )
}

export default CheckoutCart