// restaurantDataSlice.js

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  specificRestaurantData: null,
};

const restaurantDataSlice = createSlice({
  name: 'specificRestaurantData',
  initialState,
  reducers: {
    RestaurantData: (state, action) => {
      state.specificRestaurantData = action.payload;
      // //('checking restaurant reducer working or not', action.payload);
      // Assuming action.payload.loc_id contains the location ID you want to store
      const locationId = action.payload.loc_id;

      // Set the location ID in local storage
      localStorage.setItem('location_id', locationId);

      // //('Location id for local Storage:', locationId);
    },
    RestaurantMenuData: (state, action) => {
      state.specificRestaurantMenuData = action.payload;
    },
    RestaurantTimeSlotsData: (state, action) => {
      state.specificRestaurantTimeSlotsData = action.payload;
    },
    RestaurantOriginalSlots: (state, action) => {
      state.specificOriginalTimeSlotsData = action.payload;
    }
  },
});

export const { RestaurantData, RestaurantMenuData, RestaurantTimeSlotsData, RestaurantOriginalSlots } = restaurantDataSlice.actions;
export default restaurantDataSlice.reducer;
