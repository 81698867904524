import {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import {getToken} from "../../services/AuthService";
import {useSelector} from "react-redux";
import Error500 from "../error500";
import {Stack} from "@mui/material";
import Sidebar from "../../sections/dashboard/sidebar";
import {Outlet} from "react-router-dom";

const Main = () => {
    const [checks, setChecks] = useState({
        initialLoading: true,
        isLoggedInUser: false
    })

    const user = useSelector((state) => state.user.user);

    const checkUser = () => {
        if (getToken() && user && user.loggedInUserInformation && user.loggedInUserInformation.id && user.loggedInUserInformation.id !== '') {
            setChecks({
                initialLoading: false,
                isLoggedInUser: true
            })
        } else {
            setChecks({
                initialLoading: false,
                isLoggedInUser: false
            })
        }
    }

    useEffect(() => {
        checkUser()
    }, []);

    return(
        (checks.initialLoading ?
                <Loader /> :
                checks.isLoggedInUser ?
                    <>
                        <Stack direction='row' sx={{height: {xs: 'calc(100vh - 53px)', md: 'calc(100vh - 53px)'}}}>
                            <Sidebar />
                            <Outlet />
                        </Stack>
                    </>
                    :
                    <Error500 message={"Unauthorized Access"} />
        )
    )
}

export default Main