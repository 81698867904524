import axios from "axios";
import {ApiRoute, CONSTANTS} from "../utils/constants";

export const performReservation = async (loc_id, data) => {
    try {
        const response = await axios.post(`${ApiRoute.reserveRestaurant(loc_id)}`, {...data}, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS
            }
        })
        if (!response || !response.data) {
            return ({error: true, message: 'Cannot perform Reservation'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        return ({error: true, message: 'Cannot perform Reservation'})
    }
}

export const getUserReservations = async () => {
    let token = JSON.parse(localStorage.getItem("servallAuthUserToken"));
    if (token) {
        try {
            const response = await axios.get(ApiRoute.getUserReservationsHistory, {
                headers: {
                    ...CONSTANTS.REQUEST_HEADERS,
                    "x-access-token": token
                }
            })

            if (!response || !response.data) {
                return ({error: true, message: 'Cannot get User Reservations'})
            } else {
                return ({error: false, data: response.data})
            }
        } catch (err) {
            console.log("Error While Getting User Reservations", err);
            return ({error: true, message: 'Cannot get User Reservations'})
        }
    } else {
        return ({error: true, message: 'Cannot get User Reservations'})
    }
}