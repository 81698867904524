import {Box, Button, MenuItem, OutlinedInput, Select, Stack, Typography} from "@mui/material";
import {blue, grey} from "@mui/material/colors";
import {inputFieldsStyle} from "../../css/orderPlacementStyles";
import React, {useState} from "react";
import {MAIN_GREEN_COLOR} from "../../colors";
import {useDispatch, useSelector} from "react-redux";
import {setUserDeliveryOptions, setUserDeliveryStatus, setUserLocation} from "../../features/userSlice";
import {verifyLocation} from "../../services/LocationService";

const MoreLocationInformation = ({location, setLocation, setView}) => {
    const [option, setOption] = useState('door');
    const [address, setAddress] = useState('home')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')

    const dispatch = useDispatch()
    const restaurant_id = useSelector(state => state.restaurantData.specificRestaurantData.loc_id)

    const fields = [
        {
            name: 'Street Name',
            label: 'streetName',
        },
        {
            name: 'Street Number',
            label: 'streetNumber',
        },
        {
            name: 'House',
            label: 'house',
        }
    ]

    const fieldsOptions = [
        {
            label: 'Leave it at my door',
            value: 'door'
        },
        {
            label: 'Hand it to me',
            value: 'handover'
        },
        {
            label: 'Meet outside',
            value: 'meet'
        }
    ]

    const addresses = [
        {
            label: "Home",
            value: 'home'
        },

        {
            label: "Work",
            value: 'work'
        },

        {
            label: "School",
            value: 'school'
        },

        {
            label: "Other",
            value: 'other'
        },
    ]

    const handleSaveAddress = async () => {
        try {
            try {
                const response = await verifyLocation(restaurant_id, location.long, location.lat)
                dispatch(setUserLocation({location: location}))
                dispatch(setUserDeliveryOptions({deliveryOptions: {addressType: address, deliveryInstructions: option, deliveryInstructionDetail: deliveryInstructions}}))
                dispatch(setUserDeliveryStatus({isDeliverable: response.code === 200 ? 'deliverable' : 'not_deliverable'}))
                setView('main')
            } catch (err) {
                console.error(err)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const handleCancelEvent = () => {
        setLocation({})
        setView('main')
    }

    return(
        <>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', padding: '0 2rem 0 0'}}>
                <Stack>
                    <Typography variant='body2' sx={{fontSize: '1.1rem', fontWeight: '600'}}>{location.country}</Typography>
                    <Typography variant='body2' sx={{fontSize: '0.8rem', fontWeight: '400'}}>{location.state} {location.city}</Typography>
                </Stack>
                <Typography variant='body2' sx={{cursor: 'pointer', fontSize: '1rem', textDecoration: 'underline', color: blue[500]}} onClick={()=> setView('mapSelection')}>Edit</Typography>
            </Stack>
            <Stack rowGap='1rem'>
                {
                    fields.map((item, index)=> (
                        <OutlinedInput
                            value={location[item.label]}
                            key={index}
                            sx={inputFieldsStyle}
                            placeholder={item.name}
                            onChange={(e)=> setLocation(prev=> ({...prev, [item.label]: e.target.value}))}
                        />
                    ))
                }
            </Stack>
            <Stack rowGap='0.5rem'>
                <Stack>
                    <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem'}}>Delivery Options</Typography>
                    <Select
                        sx={inputFieldsStyle}
                        variant='outlined'
                        value={option}
                        onChange={(e)=> setOption(e.target.value)}
                    >
                        {fieldsOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Stack>
                    <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem'}}>Add delivery instructions</Typography>
                    <OutlinedInput sx={inputFieldsStyle} multiline minRows={2} placeholder='eg. ring the bell after dropoff, leave next to the porch, call upon arrival, etc' onChange={(e)=> setDeliveryInstructions(e.target.value)}/>
                </Stack>
                <Stack>
                    <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}>Address label</Typography>
                    <Stack direction='row' sx={{borderRadius: '5px', overflow: 'hidden', width: 'fit-content', marginTop: '0.5rem'}}>
                        {
                            addresses.map((item, index)=> (
                                <Box key={index} onClick={()=> setAddress(item.value)} sx={{transition: '0.5s', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: (address === item.value ? 'black' : grey[200]), padding: '0.7rem 1.5rem'}}>
                                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: (address === item.value ? 'white' : 'black'), transition: '0.5s'}}>{item.label}</Typography>
                                </Box>
                            ))
                        }
                    </Stack>
                </Stack>
                <Stack direction='row' sx={{marginTop: '0.5rem', alignItems: 'center', justifyContent: 'space-between', columnGap: '1rem'}}>
                    <Button sx={{backgroundColor: 'black', color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem', width: '50%'}} onClick={()=> handleCancelEvent()}>Cancel</Button>
                    <Button sx={{backgroundColor: MAIN_GREEN_COLOR, color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem', width: '50%'}} onClick={()=> handleSaveAddress()}>Save Address</Button>
                </Stack>
            </Stack>
        </>
    )
}

export default MoreLocationInformation