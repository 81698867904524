import {Box, circularProgressClasses, Grid2, IconButton, Stack, Typography} from "@mui/material";
import {gridView} from "../css/restaurantPageStyles";
import {URLS} from "../utils/constants";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import React, {useEffect, useState} from "react";
import {getPremiumServices} from "../services/PremiumServices";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const PremiumServices = () => {
    const [premiumServicesData, setPremiumServicesData] = useState(null)
    const specificRestaurantData = useSelector((state) => state.restaurantData.specificRestaurantData);

    const loadServicesData = async () => {
        const response = await getPremiumServices(specificRestaurantData.loc_id)
        if (response.error) {
            setPremiumServicesData([])
        } else {
            setPremiumServicesData(response.data.data)
        }
    }

    useEffect(() => {
        loadServicesData()
    }, []);


    if (!premiumServicesData) {
        return <Stack sx={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress color='success' size={60} thickness={5} sx={{ [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },}}/>
        </Stack>;
    } else if (premiumServicesData.length === 0) {
        return <Typography variant='h5' sx={{fontSize: '14px', fontWeight: '600'}}>No Premium Services Available</Typography>
    }

    return(
        <>
            <Grid2 container spacing={3}>
                {premiumServicesData?.map((service, index) => (
                    <Grid2 size={{xs: 12, sm: 12, md: 4, lg: 3, xl: 2.4}} key={index} sx={{display: 'flex', alignItems: 'stretch', cursor: 'pointer'}}>
                        <Stack sx={{...gridView.itemMainStack, backgroundColor: 'white'}} onClick={() => window.open(`https://${service.service_link}`, '_blank')}>
                            <Box component='img' src={`${URLS.BaseUrlWithoutWebsite}${service.service_image}`} alt="Premium Service Image" sx={gridView.itemImage}/>
                            <Stack sx={gridView.infoStack}>
                                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>{service.service_title}</Typography>
                                    <IconButton>
                                        <ErrorOutlineOutlinedIcon sx={{height: '25px', width: '25px', color: 'black'}}/>
                                    </IconButton>
                                </Stack>
                                <Typography variant='body2' sx={{fontSize: '12px'}}>
                                    {service.service_desc}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid2>
                ))}
            </Grid2>
        </>
    )
}

export default PremiumServices