import {Box, Grid2, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, {useEffect, useRef, useState} from "react";
import ItemModal from "./itemModal";
import {URLS} from "../../utils/constants";
import {rowView} from "../../css/restaurantPageStyles";
import {useSelector} from "react-redux";
import MoreProductInformationModal from "./moreProductInformationModal";
import {MAIN_GREEN_COLOR} from "../../colors";
import {calculateTotalPrice} from "../../utils/cartDataHelpers";
import CartFromOtherLocation from "../cartFromOtherLocation";
import placeholderImage from "../../assets/placeholder_restaurant.png";

const ItemsRowView = ({setSelectedTab}) => {
    const [modalItem, setModalItem] = useState(null)
    const [allergicItems, setAllergicItems] = useState([])
    const [infoModal, setInfoModal] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [cartFromOtherLocation, setCartFromOtherLocation] = useState(false)
    const data = useSelector((state)=> state.restaurantData.specificRestaurantMenuData)
    const restaurant = useSelector((state)=> state.restaurantData.specificRestaurantData)

    const handleItemAdd = (item) => {
        setModalItem(item)
        setOpenModal(true)
    }

    const handleMoreInformationModal = (menuAllergicItems) => {
        setAllergicItems(menuAllergicItems)
        setInfoModal(true)
    }

    const sectionRefs = useRef([]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.25,
        };
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setSelectedTab(entry.target.id);
                }
            });
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        sectionRefs.current.forEach((ref) => {
            observer.observe(ref);
        });
        return () => {
            observer.disconnect();
        };
    }, []);

    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    }

    return(
        <Stack sx={{rowGap: '1rem'}}>
            {(data && data.menu_data) && data.menu_data.map((item, index)=>(
                item && (
                    <Stack key={item.Category.category_id} id={`${item.Category.category_id}`} ref={(el) => (sectionRefs.current[index] = el)}>
                        <Stack sx={{rowGap: '0.5rem'}}>
                            <Typography variant='h6' sx={{fontSize: '16px', fontWeight: '600'}}>
                                {item.Category.name}
                            </Typography>
                            <Grid2 container spacing={2}>
                                {item.Menu.map((catItems) => (
                                    <Grid2 key={catItems.menu_id} size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 4}}
                                          sx={{display: 'flex', alignItems: 'stretch'}}>
                                        <Stack sx={rowView.itemMainStack}>
                                            <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                                                <Box component='img'
                                                     src={`${URLS.BaseUrlWithoutWebsite + "/" + catItems.thumb_path}`}
                                                     alt={catItems.menu_name} sx={rowView.itemImage}
                                                     onError={handleImageError}
                                                />
                                                <Stack sx={{justifyContent: 'center'}}>
                                                    <Typography variant='body2' sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600'
                                                    }}>{catItems.menu_name}</Typography>
                                                    <Typography variant='body2' sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '400'
                                                    }}>{catItems.menu_description}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction='row'
                                                   sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                                <Tooltip title={'Allergic Items'} placement={'top'}>
                                                    <IconButton aria-label={`Information for ${catItems.menu_name}`}
                                                                onClick={() => handleMoreInformationModal(catItems.MenuAllergyItems)}
                                                                disabled={catItems.MenuAllergyItems.length <= 0}>
                                                        <ErrorOutlineOutlinedIcon sx={{
                                                            height: '25px',
                                                            width: '25px',
                                                            color: ((catItems.MenuAllergyItems.length <= 0) ? grey[400] : 'black')
                                                        }}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant='body2' sx={{fontSize: '18px', fontWeight: '500'}}>
                                                    {restaurant.currency + " " + calculateTotalPrice(
                                                        catItems.menu_price,
                                                        catItems.menu_tax,
                                                        1
                                                    )}
                                                </Typography>
                                                <IconButton onClick={() => handleItemAdd(catItems)}
                                                            aria-label={catItems.menu_name}>
                                                    <AddCircleIcon
                                                        sx={{color: MAIN_GREEN_COLOR, height: '35px', width: '35px'}}/>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </Grid2>
                                ))}
                            </Grid2>
                        </Stack>
                    </Stack>
                )))
            }
            {modalItem !== null && <ItemModal setCartFromOtherLocation={setCartFromOtherLocation} item={modalItem} setModalItem={setModalItem} openModal={openModal} setOpenModal={setOpenModal}/>}
            {infoModal && <MoreProductInformationModal allergicItems={allergicItems} openModal={infoModal} handleModalClose={setInfoModal}/>}
            { cartFromOtherLocation && <CartFromOtherLocation setCartFromOtherLocation={setCartFromOtherLocation} cartFromOtherLocation={cartFromOtherLocation} /> }

        </Stack>
    )
}

export default ItemsRowView