import {grey} from "@mui/material/colors";
import {MAIN_GREEN_COLOR} from "../colors";

export const checkoutStyles = {
    mainBoxStyle: {
        backgroundColor: grey[200]
    },

    mainContainerStyle: {
        padding: {xs: '0.5rem', sm: '2rem'}
    },

    mainResponsiveStackStyle: {
        marginX: 'auto',
        maxWidth: {sm: '550px', md: '850px', lg: '1150px', xl: '1500px'},
        columnGap: '1rem',
        rowGap: '1rem'
    }
}

export const cartStackStyles = {
    mainStack:
        {
            backgroundColor: 'white',
            width: {xs: '100%', md: '320px', lg: '370px', xl: '400px'},
            borderRadius: '5px',
            height: 'fit-content',
            padding: '1.5rem 1rem',
            position: {xs: 'default', md: 'sticky'},
            top: '1rem',
        }

}

export const informationStackStyles = {
    mainStack: {
        padding: '1.5rem 1rem',
        backgroundColor: 'white',
        flexGrow: 1,
        borderRadius: '5px',
        rowGap: '1rem'
    }
}

export const cardStyle = {
    border: `1px solid ${grey[300]}`,
    padding: '1rem',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '0.5s',
    '&:hover': {
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        cursor: 'pointer'
    }
}

export const digitalWalletCard = {
    mainStack: {
        flexDirection: 'row',
        padding: '1.75rem 2rem',
        borderRadius: '5px',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: '0.5s',
        background: 'linear-gradient(90deg, rgba(253,204,59,1) 65%, rgba(255,243,136,1) 100%)',
        '&:hover': {
            boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        }
    },

    checkBoxStack: {
        alignItems: 'center', justifyContent: 'center', border: '1px solid black', borderRadius: '5px', padding: '0 1rem 0 0'
    }
}

export const courierTipStyles = {
    itemStack: {
        borderRadius: '5px', overflow: 'hidden', width: 'fit-content', marginTop: '0.5rem'
    },

    itemBox: {
        transition: '0.5s', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0.7rem 1.5rem'
    },

    button: {
        backgroundColor: MAIN_GREEN_COLOR, color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem'
    }
}

export const deliveryOptionsStyles = {
    itemBox: {
        transition: '0.5s', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0.7rem 1.5rem'
    },

    button: {
        marginTop: '0.5rem',backgroundColor: MAIN_GREEN_COLOR, color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem'
    }
}

export const dynamicMapsStyles = {
    cancelButton: {
        backgroundColor: 'black', color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem', width: '50%'
    },

    saveButton: {
        backgroundColor: MAIN_GREEN_COLOR, color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem', width: '50%',
        '&:hover': { backgroundColor: '#5fbc5f' }
    }
}

export const locationModalStyles = {
    mainStack: {
        rowGap: '0.75rem', borderRadius: '5px', height: '83vh', width: {xs: '90vw', sm: '550px', md: '850px'}, maxHeight: '90vh', overflow: 'auto', backgroundColor: 'white', padding: '1rem'
    }
}

export const orderTypeSelectionModalStyles = {
    mainStack: {
        rowGap: '0.75rem', borderRadius: '5px', maxHeight: '90vh', height: 'fit-content', width: {xs: '90vw', sm: '400px', md: '500px'}, backgroundColor: 'white', padding: '1rem'
    },

    orderTypeRadioStack:
        {
            flexDirection: 'row', columnGap: '0.25rem', cursor: 'pointer', alignItems: 'center', padding: '0.75rem 0.75rem 0.75rem 0rem', borderRadius: '5px', transition: '0.5s', '&:hover':{ backgroundColor: grey[200] }
        },

    orderTypeItemBox: {
        transition: '0.5s', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0.7rem 1.5rem'
    },

    saveButton: {
        backgroundColor: MAIN_GREEN_COLOR,
        color: 'white',
        padding: '0.75rem 1rem',
        textTransform: 'capitalize',
        fontSize: '0.9rem',
        borderRadius: '8px',
        '&.Mui-disabled': { backgroundColor: grey[500], color: 'white' }
    }

}

export const paymentModalStyles = {
    mainStack: {
        rowGap: '0.75rem', borderRadius: '5px', height: 'fit-content', width: {xs: '90vw', sm: '550px', md: '550px'}, maxHeight: '90vh', overflow: 'auto', backgroundColor: 'white', padding: '1rem'
    }
}

export const phoneModalStyles = {
    mainStack: {
        rowGap: '0.75rem', borderRadius: '5px', maxHeight: '90vh', height: 'fit-content', width: {xs: '90vw', sm: '400px', md: '500px'}, backgroundColor: 'white', padding: '1rem'
    },

    saveButton: {
        backgroundColor: MAIN_GREEN_COLOR, color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '0.9rem', borderRadius: '8px', width: '50%'
    },

    cancelButton: {
        backgroundColor: 'black', color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '0.9rem', borderRadius: '8px', width: '50%'
    },

    resendCodeText: {
        alignSelf: 'start', fontSize: '0.75rem', textDecoration: 'underline', cursor: 'pointer'
    }
}

export const promotionModalStyles = {
    mainStack: {
        rowGap: '0.75rem', borderRadius: '5px', maxHeight: '90vh', height: 'fit-content', width: {xs: '90vw', sm: '400px', md: '500px'}, backgroundColor: 'white', padding: '1rem'
    },

    promoItemStack: {
        direction: 'row', columnGap: '0.25rem', cursor: 'pointer', alignItems: 'center', padding: '0.75rem 0.75rem 0.75rem 0rem', borderRadius: '5px', transition: '0.5s', '&:hover':{ backgroundColor: grey[200] }
    },

    saveButton: {
        backgroundColor: MAIN_GREEN_COLOR, color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '0.9rem', borderRadius: '8px', width: '50%'
    },

    cancelButton: {
        backgroundColor: 'black', color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '0.9rem', borderRadius: '8px', width: '50%'
    }
}