import {useSelector} from "react-redux";
import {Collapse, Stack, Typography} from "@mui/material";
import {brown, red, yellow} from "@mui/material/colors";

const UserCheckoutErrorMessages = () => {
    const user = useSelector((state) => state.user.user);
    const specificRestaurantData = useSelector(
        (state) => state.restaurantData.specificRestaurantData
    );
    const { loc_id, cart, totalamount } = useSelector((state) => state.allCart);

    return(
        <>
            <Collapse in={!user.phoneNumber || user.phoneNumber === "" || user.phoneNumber === null || user.phoneNumber === undefined} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>Please, enter your phone number</Typography>
                </Stack>
            </Collapse>

            <Collapse in={(!user.location.lat || !user.location.long) && user.order_type === 'delivery'} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>Please, select your address</Typography>
                </Stack>
            </Collapse>

            <Collapse in={(!user.location.address || user.location.address === "" || user.location.address === "Select an Address") && user.oder_type === 'delivery'} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>Please, select your address</Typography>
                </Stack>
            </Collapse>

            <Collapse in={!user.orderDateTime.slot || user.orderDateTime.formattedDate === "" || !user.orderDateTime.formattedDate} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>Please, select a time slot</Typography>
                </Stack>
            </Collapse>

            <Collapse in={(user.isDeliverable !== "deliverable") && user.order_type === 'delivery'} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>We cannot deliver to your location</Typography>
                </Stack>
            </Collapse>

            <Collapse in={(totalamount < specificRestaurantData.min_delivery_amount) && user.order_type === 'delivery'} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>cart must have amount of greater than {specificRestaurantData.currency}{specificRestaurantData.min_delivery_amount} for the delivery</Typography>
                </Stack>
            </Collapse>

            <Collapse in={(specificRestaurantData.loc_id !== loc_id) && cart.length > 0} unmountOnExit>
                <Stack sx={{backgroundColor: red[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>Cart contains items from different hotel or restaurant</Typography>
                </Stack>
            </Collapse>

            <Collapse in={(user.order_type !== "delivery" && user.order_type !== "takeaway")} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>Please, select correct order type.i.e delivery or pickup</Typography>
                </Stack>
            </Collapse>

            <Collapse in={(user.user_type === 'logged_in' && user.loggedInUserInformation && user.loggedInUserInformation.id && !user.loggedInUserInformation.name)} unmountOnExit>
                <Stack sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: brown[500]}}>Please, update name in user dashboard</Typography>
                </Stack>
            </Collapse>
        </>
    )
}

export default UserCheckoutErrorMessages