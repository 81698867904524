import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import {circularProgressClasses, Stack} from "@mui/material";

const Loader = () => {
  return (
    <Stack sx={{height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress color='success' size={60} thickness={5} sx={{ [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
            },}}/>
    </Stack>
  );
};

export default Loader;
