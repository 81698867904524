import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Modal,
    Radio,
    RadioGroup,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {green, grey, yellow} from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {itemModal} from "../../css/restaurantPageStyles";
import {URLS} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {addItemsToCart, getCartTotal, getLocationId} from "../../features/cartSlice";
import {useTranslation} from "react-i18next";
import {MAIN_GREEN_COLOR} from "../../colors";
import {filledInputFieldsStyle} from "../../css/orderPlacementStyles";

const ItemModal = ({setModalItem, setOpenModal, openModal, item, setCartFromOtherLocation}) => {
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch();
    const { cart, loc_id } = useSelector((state) => state.allCart);
    const restaurantData = useSelector((state) => state.restaurantData.specificRestaurantData)

    const [quantity, setQuantity] = useState(1);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedRadioValues, setSelectedRadioValues] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectValueCounts, setSelectValueCounts] = useState({});
    const [showMore, setShowMore] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [specialInstruction, setSpecialInstruction] = useState("");

    const [selectedRadioCartItem, setSelectedRadioCartItem] = useState([]);
    const [selectedCheckboxCartItem, setSelectedCheckboxCartItem] = useState([]);
    const [selectedSelectCartItem, setSelectedSelectCartItem] = useState([]);

    const [cartItemsDataList, setCartItemsDataList] = useState(cart);

    const [requiredFieldError, setRequiredFieldError] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarMsg, setOpenSnackBarMsg] = useState("");

    const handleRadioChange = (index, value) => {
        ////(index, value, "handle");

        setSelectedRadioValues((prevValues) => {
            const updatedValues = [...prevValues];

            // If the clicked value is already selected, unselect it
            if (updatedValues[index] === value) {
                updatedValues[index] = "";
            } else {
                // Otherwise, select the clicked value
                updatedValues[index] = value;
            }

            ////("Updated Values:", updatedValues);

            return updatedValues;
        });
    };

    const resetValues = () => {
        setQuantity(1);
        setSelectedValues([]);
        setSelectedRadioValues([]);
        setSelectedCheckboxes([]);
        setSelectValueCounts([]);
        setShowMore();
        setTotalAmount(0);
        setTotalTax(0);
        setSubTotal(0);
        setSelectedRadioCartItem([]);
        setSelectedCheckboxCartItem([]);

        setSelectedSelectCartItem([]);
        setRequiredFieldError("");
        setOpenSnackBar(false);
        setOpenSnackBarMsg("");
        setOpenModal(false);
    };

    useEffect(() => {
        setCartItemsDataList(cart);
    }, [openModal]);

    useEffect(() => {
        let baseprice = 0;
        let subtotal = 0;
        let totalamount = 0;
        let totaltax = 0;
        let menutax = 0;
        if (selectedRadioCartItem.length > 0) {
            selectedRadioCartItem.map((item) => {
                item.Option_Values.map((option) => {
                    if (item.base_price === 1) {
                        baseprice = 1;
                        subtotal +=
                            parseFloat(option.price) * option.optionCount * quantity;
                        totalamount +=
                            parseFloat(option.price) * option.optionCount * quantity;
                        if (option.order_item_tax > 0) {
                            totaltax +=
                                parseFloat(option.order_item_tax) *
                                option.optionCount *
                                quantity;
                            totalamount +=
                                parseFloat(option.order_item_tax) *
                                option.optionCount *
                                quantity;
                        }
                    } else {
                        subtotal += parseFloat(option.price) * option.optionCount;
                        totalamount += parseFloat(option.price) * option.optionCount;
                        if (option.order_item_tax > 0) {
                            totaltax +=
                                parseFloat(option.order_item_tax) * option.optionCount;
                            totalamount +=
                                parseFloat(option.order_item_tax) * option.optionCount;
                        }
                    }
                    ////("TOTAL: 0 " + totalamount);
                    ////("SubTotal: 0 " + subtotal);
                    ////("totaltax: 0 " + totaltax);
                });
            });
        }

        if (selectedCheckboxCartItem.length > 0) {
            selectedCheckboxCartItem.map((item) => {
                item.Option_Values.map((option) => {
                    if (item.base_price === 1) {
                        baseprice = 1;
                        subtotal +=
                            parseFloat(option.price) * option.optionCount * quantity;
                        totalamount +=
                            parseFloat(option.price) * option.optionCount * quantity;
                        if (option.order_item_tax > 0) {
                            totaltax +=
                                parseFloat(option.order_item_tax) *
                                option.optionCount *
                                quantity;
                            totalamount +=
                                parseFloat(option.order_item_tax) *
                                option.optionCount *
                                quantity;
                        }
                    } else {
                        subtotal += parseFloat(option.price) * option.optionCount;
                        totalamount += parseFloat(option.price) * option.optionCount;
                        if (option.order_item_tax > 0) {
                            totaltax +=
                                parseFloat(option.order_item_tax) * option.optionCount;
                            totalamount +=
                                parseFloat(option.order_item_tax) * option.optionCount;
                        }
                    }
                });
            });
        }

        if (selectedSelectCartItem.length > 0) {
            selectedSelectCartItem.map((item) => {
                item.Option_Values.map((option) => {
                    if (item.base_price === 1) {
                        baseprice = 1;
                        subtotal +=
                            parseFloat(option.price) * option.optionCount * quantity;
                        totalamount +=
                            parseFloat(option.price) * option.optionCount * quantity;
                        if (option.order_item_tax > 0) {
                            totaltax +=
                                parseFloat(option.order_item_tax) *
                                option.optionCount *
                                quantity;
                            totalamount +=
                                parseFloat(option.order_item_tax) *
                                option.optionCount *
                                quantity;
                        }
                    } else {
                        subtotal += parseFloat(option.price) * option.optionCount;
                        totalamount += parseFloat(option.price) * option.optionCount;
                        if (option.order_item_tax > 0) {
                            totaltax +=
                                parseFloat(option.order_item_tax) * option.optionCount;
                            totalamount +=
                                parseFloat(option.order_item_tax) * option.optionCount;
                        }
                    }
                });
            });
        }

        ////("TOTAL: m " + totalamount);
        ////("subtotal: m " + subtotal);
        ////("totaltax: m " + totaltax);
        if (baseprice === 0) {
            subtotal += parseFloat(item.menu_price) * quantity;
            totalamount += parseFloat(item.menu_price) * quantity;
            menutax = (item.menu_price / 100) * item.menu_tax;
            menutax = parseFloat(menutax).toFixed(2);
            totalamount += parseFloat(menutax);
        }
        totaltax += parseFloat(menutax);

        ////("TOTAL: 1 " + totalamount);
        ////("subtotal: 1 " + subtotal);
        ////("totaltax: 1 " + totaltax);
        setTotalAmount(totalamount.toFixed(2));
        setSubTotal(subtotal.toFixed(2));
        setTotalTax(totaltax.toFixed(2));
    }, [
        item,
        totalAmount,
        subTotal,
        totalTax,
        selectedSelectCartItem,
        cart,
        quantity,
        selectedCheckboxCartItem,
        selectedRadioCartItem,
    ]);

    const handleSelectValueSelection = (valueId, menuOption, action) => {
        const updatedValues = [...selectedValues];
        const updatedCounts = { ...selectValueCounts };
        ////(updatedCounts, updatedValues, "selected");

        if (updatedValues.includes(valueId)) {
            if (action === "increment") {
                updatedCounts[valueId] = (updatedCounts[valueId] || 0) + 1; // Increment count by 1
            } else if (action === "decrement") {
                if (updatedCounts[valueId] <= menuOption.min) {
                    updatedValues.splice(updatedValues.indexOf(valueId), 1);
                    updatedCounts[valueId] = (updatedCounts[valueId] || 0) - updatedCounts[valueId]; // Decrement count by 1
                } else {
                    updatedCounts[valueId] = Math.max((updatedCounts[valueId] || 0) - 1, 0); // Decrement count by 1, but ensure it doesn't go below 0
                }
            }
        } else {
            updatedValues.push(valueId);
            if (action === "increment") {
                updatedCounts[valueId] = menuOption.min;
            } else if (action === "decrement") {
                //console.error("Attempted to decrement a value that doesn't exist.");
            }
        }

        if (menuOption.max !== 0 && updatedCounts[valueId] > menuOption.max) {
            setOpenSnackBar(true);
            setOpenSnackBarMsg(t("you-have-selected-maximum-option-quantity"));
            return;
        }

        let radioTypeItm = {
            option_id: menuOption.option_id,
            display_type: "select",
            base_price: menuOption.base_price,
            option_name: menuOption.Option.option_name,
            Option_Values: [],
        };

        if (updatedValues.length > 0) {
            updatedValues.map((item) => {
                menuOption.MenuOptionValues.map((option) => {
                    if (option.option_value_id === item) {
                        let price = option.new_price > 0 ? option.new_price : 0;
                        let itemtax = menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
                        if (updatedCounts[item] > 0) {
                            radioTypeItm.Option_Values.push({
                                optionCount: updatedCounts[item],
                                value: option.OptionValue.value,
                                option_value_id: option.option_value_id,
                                price: price,
                                order_item_tax: itemtax.toFixed(2),
                                order_item_tax_percentage:
                                    menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                                menu_option_type: menuOption.option_menu_type,
                            });
                        }
                    }
                });
            });
        }

        let arraySelectedSelectCartItem = [...selectedSelectCartItem];
        let match = false;
        arraySelectedSelectCartItem.map((item, index) => {
            if (item.option_id === radioTypeItm.option_id) {
                match = true;
                arraySelectedSelectCartItem[index] = radioTypeItm;
            }
        });
        if (!match) {
            arraySelectedSelectCartItem.push(radioTypeItm);
        }

        // for removing empty option values
        let selectedItemsArray = [];
        arraySelectedSelectCartItem.map((item) => {
            if (item.Option_Values.length > 0) {
                selectedItemsArray.push(item);
            }
        });

        setSelectedSelectCartItem(selectedItemsArray);
        setSelectedValues(updatedValues);
        setSelectValueCounts(updatedCounts);
    };

    const handleRadioValueSelection = (index, valueId, menuOption) => {
        let selectedOption = null;
        menuOption.MenuOptionValues.map((option) => {
            if (option.option_value_id === valueId) {
                selectedOption = option;
            }
        });
        let price = selectedOption.new_price > 0 ? selectedOption.new_price : 0;
        let itemtax =
            menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
        let radioTypeItm = {
            option_id: selectedOption.option_id,
            display_type: "radio",
            base_price: menuOption.base_price,
            option_name: menuOption.Option.option_name,
            Option_Values: [
                {
                    optionCount: 1,
                    value: selectedOption.OptionValue.value,
                    option_value_id: selectedOption.option_value_id,
                    price: price,
                    order_item_tax: itemtax.toFixed(2),
                    order_item_tax_percentage:
                        menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                    menu_option_type: menuOption.option_menu_type,
                },
            ],
        };
        let arraySelectedRadioCartItem = [...selectedRadioCartItem];
        let match = false;
        if (selectedRadioValues.length > 0) {
            arraySelectedRadioCartItem.map((item, index) => {
                if (item.option_id === radioTypeItm.option_id) {
                    match = true;
                    arraySelectedRadioCartItem[index] = radioTypeItm;
                }
            });
            if (!match) {
                arraySelectedRadioCartItem.push(radioTypeItm);
            }
        } else {
            arraySelectedRadioCartItem.push(radioTypeItm);
        }
        let arrayRadioItems = [...selectedRadioValues];
        arrayRadioItems[index] = valueId;
        setSelectedRadioValues(arrayRadioItems);
        setSelectedRadioCartItem(arraySelectedRadioCartItem);
    };

    const handleCheckboxValueSelection = (valueId, menuOption) => {
        let preSelectedCheckboxes = [];
        if (selectedCheckboxes.includes(valueId)) {
            preSelectedCheckboxes = selectedCheckboxes.filter((id) => id !== valueId);
        } else {
            preSelectedCheckboxes = [...selectedCheckboxes, valueId];
        }

        let radioTypeItm = {
            option_id: menuOption.option_id,
            display_type: "checbox",
            base_price: menuOption.base_price,
            option_name: menuOption.Option.option_name,
            Option_Values: [],
        };
        if (preSelectedCheckboxes.length > 0) {
            preSelectedCheckboxes.map((item) => {
                menuOption.MenuOptionValues.map((option) => {
                    if (option.option_value_id === item) {
                        let price = option.new_price > 0 ? option.new_price : 0;
                        let itemtax =
                            menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
                        radioTypeItm.Option_Values.push({
                            optionCount: 1,
                            value: option.OptionValue.value,
                            option_value_id: option.option_value_id,
                            price: price,
                            order_item_tax: itemtax.toFixed(2),
                            order_item_tax_percentage:
                                menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                            menu_option_type: menuOption.option_menu_type,
                        });
                    }
                });
            });
        }

        let arraySelectedChecboxCartItem = [...selectedCheckboxCartItem];
        let match = false;
        let maxSelectedValuesReached = false;
        arraySelectedChecboxCartItem.map((item, index) => {
            if (item.option_id === radioTypeItm.option_id) {
                match = true;
                arraySelectedChecboxCartItem[index] = radioTypeItm;
                if (
                    menuOption.max !== 0 &&
                    arraySelectedChecboxCartItem[index].Option_Values.length >
                    menuOption.max
                ) {
                    maxSelectedValuesReached = true;
                }
            }
        });
        if (!match) {
            arraySelectedChecboxCartItem.push(radioTypeItm);
        }

        // for removing empty option values
        let selectedItemsArray = [];
        arraySelectedChecboxCartItem.map((item) => {
            if (item.Option_Values.length > 0) {
                selectedItemsArray.push(item);
            }
        });

        if (maxSelectedValuesReached) {
            setOpenSnackBar(true);
            setOpenSnackBarMsg(t("you-have-selected-maximum-options"));
            return;
        }

        setSelectedCheckboxes((prevCheckboxes) => {
            if (prevCheckboxes.includes(valueId)) {
                return prevCheckboxes.filter((id) => id !== valueId);
            } else {
                return [...prevCheckboxes, valueId];
            }
        });
        setSelectedCheckboxCartItem(selectedItemsArray);
    };

    const addToCart = () => {
        ////("add to cart");
        if ((restaurantData.loc_id !== loc_id) && cart.length > 0) {
            setOpenModal(false)
            setCartFromOtherLocation(true)
        } else {

            let selectedValuesForCart = [];
            selectedRadioCartItem.map((item) => {
                selectedValuesForCart.push(item.option_id);
            });

            selectedCheckboxCartItem.map((item) => {
                selectedValuesForCart.push(item.option_id);
            });

            selectedSelectCartItem.map((item) => {
                selectedValuesForCart.push(item.option_id);
            });

            for (let i = 0; i < item.MenuOptions.length; i++) {
                let requiredFound = false;
                if (selectedValuesForCart.length === 0) {
                    if (item.MenuOptions[i].required !== 1) {
                        requiredFound = true;
                    }
                } else {
                    for (let j = 0; j < selectedValuesForCart.length; j++) {
                        if (
                            item.MenuOptions[i].required === 1 &&
                            item.MenuOptions[i].option_id === selectedValuesForCart[j]
                        ) {
                            requiredFound = true;
                        } else if (item.MenuOptions[i].required !== 1) {
                            requiredFound = true;
                        }
                    }
                }

                if (!requiredFound) {
                    setOpenSnackBar(true);
                    setOpenSnackBarMsg(t("please-select-all-required-options"));
                    return;
                }
            }

            for (let i = 0; i < item.MenuOptions.length; i++) {
                let minFound = false;
                //////(item.MenuOptions[i], "item.MenuOptions");
                let errorMessage = [];

                // Check if the display_type is 'select'
                if (item.MenuOptions[i].Option.display_type === "select") {
                    if (selectedSelectCartItem.length === 0) {
                        if (item.MenuOptions[i].min !== 1) {
                            minFound = false;
                        }
                    } else {
                        for (let j = 0; j < selectedSelectCartItem.length; j++) {
                            const selectedItem = selectedSelectCartItem[j];
                            const optionValues = selectedItem.Option_Values?.map(
                                (option) => option.value
                            );

                            ////(selectedItem, "selected");
                            const hasOptionCount = selectedItem.Option_Values?.map(
                                (option) => option.optionCount
                            );
                            const minOptionCount = Math.min(...hasOptionCount);
                            if (
                                minOptionCount < item.MenuOptions[i].min &&
                                item.MenuOptions[i].option_id === selectedItem.option_id
                            ) {
                                minFound = true;
                                // errorMessage = `${optionValues} (${minOptionCount}) `;
                                errorMessage.push({
                                    value: optionValues,
                                    // optionCount: minOptionCount,
                                });
                                // ////(errorMessage.map((item) => ////(item.value)));
                            } else {
                                minFound = false;
                            }
                        }
                    }
                    errorMessage.forEach((item) => {
                        item.value.forEach((value) => {
                            ////(value);
                        });
                    });
                    if (minFound) {
                        const errorMessageString = errorMessage
                            .map((item) => item.value.join(", "))
                            .join("; ");
                        setOpenSnackBar(true);
                        setOpenSnackBarMsg(
                            (
                                `Minimum-Quantity-of these Varients- ${errorMessageString} must be ${item.MenuOptions[i].min}`
                            )
                        );
                        return;
                    }
                }
            }

            let cartItems = JSON.parse(JSON.stringify(cartItemsDataList));
            let menuItemsOptions = [];
            if (selectedRadioCartItem.length > 0) {
                menuItemsOptions = [...menuItemsOptions, ...selectedRadioCartItem];
            }

            if (selectedCheckboxCartItem.length > 0) {
                menuItemsOptions = [...menuItemsOptions, ...selectedCheckboxCartItem];
            }

            if (selectedSelectCartItem.length > 0) {
                menuItemsOptions = [...menuItemsOptions, ...selectedSelectCartItem];
            }

            let menuTax = (item.menu_price / 100) * item.menu_tax * quantity;
            if (cartItems.length === 0) {
                cartItems.push({
                    menu_id: item.menu_id,
                    menu_name: item.menu_name,
                    MenuOptions: menuItemsOptions,
                    menu_type: item.menu_type,
                    itemCount: quantity,
                    menu_price: item.menu_price,
                    comment: specialInstruction,
                    order_menu_tax: parseFloat(menuTax.toFixed(2)),
                    menu_tax_percentage: item.menu_tax,
                    menu_photo: item.menu_photo,
                });

                dispatch(getLocationId(item.loc_id));
                dispatch(addItemsToCart(cartItems));
            } else {
                updateCartItemList(cartItems, menuItemsOptions, menuTax);
            }
            const payload = {
                subTotal: subTotal,
                totalTax: totalTax,
                totalAmount: totalAmount,
            };

            ////("PAYLOAD", payload);

            dispatch(getCartTotal(payload)); // Wrap payload in an array
            resetValues();
        }
    };

    const calculateTotalPrice = (menuPrice, taxPercent) => {
        const itemPrice = menuPrice || 0;
        const tax = taxPercent || 0;

        // Check if taxPercent is greater than 0 before multiplying
        return tax > 0 ? itemPrice + (itemPrice * tax) / 100 : itemPrice;
    };

    const updateCartItemList = async (cartItems, menuItemsOptions, menuTax) => {
        let found = false;
        let itemQuantity = 0;

        let newMenuItemOptionsArray = JSON.parse(JSON.stringify(menuItemsOptions));

        for (let i = 0; i < cartItems.length; i++) {
            if (cartItems[i].menu_id === item.menu_id) {
                if (cartItems[i].comment === specialInstruction) {
                    found = true;
                } else {
                    found = false;
                    break;
                }

                if (
                    newMenuItemOptionsArray.length === cartItems[i].MenuOptions.length
                ) {
                    found = true;
                    for (let j = 0; j < cartItems[i].MenuOptions.length; j++) {
                        //0 //6
                        for (let k = 0; k < newMenuItemOptionsArray.length; k++) {
                            //6 //6
                            if (
                                cartItems[i].MenuOptions[j].option_id ===
                                newMenuItemOptionsArray[k].option_id
                            ) {
                                found = true;
                                if (
                                    cartItems[i].MenuOptions[j].Option_Values.length ===
                                    newMenuItemOptionsArray[k].Option_Values.length
                                ) {
                                    found = true;
                                    if (cartItems[i].MenuOptions[j].Option_Values.length > 0) {
                                        for (
                                            let l = 0;
                                            l < cartItems[i].MenuOptions[j].Option_Values.length;
                                            l++
                                        ) {
                                            for (
                                                let m = 0;
                                                m < newMenuItemOptionsArray[k].Option_Values.length;
                                                m++
                                            ) {
                                                if (
                                                    cartItems[i].MenuOptions[j].Option_Values[l]
                                                        .option_value_id ===
                                                    newMenuItemOptionsArray[k].Option_Values[m]
                                                        .option_value_id
                                                ) {
                                                    found = true;
                                                    newMenuItemOptionsArray[k].Option_Values[
                                                        m
                                                        ].optionCount += parseInt(
                                                        cartItems[i].MenuOptions[j].Option_Values[l]
                                                            .optionCount
                                                    );
                                                    newMenuItemOptionsArray[k].Option_Values[
                                                        m
                                                        ].order_item_tax =
                                                        parseFloat(
                                                            newMenuItemOptionsArray[k].Option_Values[m]
                                                                .order_item_tax
                                                        ) +
                                                        parseFloat(
                                                            cartItems[i].MenuOptions[j].Option_Values[l]
                                                                .order_item_tax
                                                        ) *
                                                        parseInt(
                                                            cartItems[i].MenuOptions[j].Option_Values[l]
                                                                .optionCount
                                                        );
                                                } else {
                                                    found = false;
                                                }
                                            }
                                            if (found) {
                                                break;
                                            }
                                        }
                                    }
                                } else {
                                    found = false;
                                }
                            } else {
                                found = false;
                            }

                            if (found) {
                                break;
                            }
                        }
                        if (!found) {
                            break;
                        }
                    }

                    if (found) {
                        itemQuantity =
                            parseInt(quantity) + parseInt(cartItems[i].itemCount);
                        cartItems[i].MenuOptions = newMenuItemOptionsArray;
                        cartItems[i].itemCount = itemQuantity;
                        cartItems[i].order_menu_tax =
                            parseFloat(cartItems[i].order_menu_tax) * itemQuantity;
                        break;
                    }
                } else {
                    found = false;
                }
            }
        }

        if (!found) {
            cartItems.push({
                menu_id: item.menu_id,
                menu_name: item.menu_name,
                MenuOptions: menuItemsOptions,
                menu_type: item.menu_type,
                itemCount: quantity,
                menu_price: item.menu_price,
                comment: specialInstruction,
                order_menu_tax: menuTax.toFixed(2) * quantity,
                menu_tax_percentage: item.menu_tax,
                menu_photo: item.menu_photo,
            });
        }
        // setCartItemsDataList(cartItems);
        dispatch(addItemsToCart(cartItems));
    };

    const handleModalClose = () => {
        setOpenModal(false)
        setModalItem(null)
    }

    return(
        <>
            <Modal open={openModal} onClose={()=> handleModalClose()} sx={itemModal.modalStyle}>
                <Stack sx={itemModal.mainStack}>
                    <Stack sx={{padding: '0.5rem', alignItems: 'end', justifyContent: 'center'}}>
                        <IconButton size='small' onClick={()=> handleModalClose()}>
                            <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                        </IconButton>
                    </Stack>
                    <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />

                    <Stack sx={{padding: '1rem', flex: 1, overflow: 'auto', rowGap: '1rem'}}>
                        <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + item.menu_photo}`} sx={{width: '100%', height: '200px', backgroundColor: grey[300]}} />
                        <Typography variant='h5' sx={{margin: 0}}>{item.menu_name}</Typography>
                        <Typography variant='body2'>{item.menu_description}</Typography>
                        <Typography variant='body2'>Buy this item to earn 4 points</Typography>
                        <Stack direction='row' columnGap='1rem'>
                            <Typography sx={itemModal.priceSelectionButton}>Price incl. Tax {restaurantData.currency}{calculateTotalPrice(item.menu_price, item.menu_tax).toFixed(2)}</Typography>
                            {/*<Typography sx={itemModal.priceSelectionButton}>Price excl. Tax {restaurantData.currency}{(item.menu_price).toFixed(2)}</Typography>*/}
                        </Stack>

                        <Stack>
                            {item.MenuOptions.map((menuOption, index) => (////("menuOptionTAX", menuOption.item_tax),
                                (
                                    <Box key={index}>
                                        {menuOption.MenuOptionValues.some((optionValue) => optionValue?.OptionValue?.status === 1) &&
                                            (
                                                <Box mt={2}>
                                                    <Stack style={{background: grey[100], borderRadius: '5px', padding: "10px"}}>
                                                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                                            <Typography variant='body2' sx={{color: 'black', fontSize: '17px', fontWeight: '500'}}>Include Add-ons</Typography>
                                                            <Typography variant='body2' sx={{color: 'black', fontSize: '17px', fontWeight: '500'}}>{menuOption.Option.option_name}</Typography>
                                                        </Stack>
                                                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}} columnGap='1rem'>
                                                            {menuOption.Option.display_type === "select" || (menuOption.Option.display_type === "checkbox" && (menuOption.min !== 0 || menuOption.max !== 0)) ? (
                                                                <Box style={{display: "flex", alignItems: "center"}}>
                                                                    {menuOption.Option.display_type === "select" ? (
                                                                        <Typography variant='body2' sx={{color: grey[500], fontSize: '12px'}}>
                                                                            {t("you-need-to-select-maximum")}{" "}
                                                                            {menuOption.max}{" "}
                                                                            {t("quantity-from-each-option")}{" "}
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography variant='body2' sx={{color: grey[500], fontSize: '12px'}}>
                                                                            {t("you-need-to-select-maximum-0")}{" "}
                                                                            {menuOption.max} {("options")}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            ) : null}
                                                            {menuOption.required ? (<Typography variant="body2" sx={{backgroundColor: yellow[200], padding: '0.25rem 0.5rem', borderRadius: '5px'}}>required</Typography>) : <Typography variant='body2' sx={{marginLeft: 'auto', padding: '0.25rem 0', borderRadius: '5px'}}>Optional</Typography>}
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            )}
                                        <Box>{menuOption.Option.display_type === "select" && (
                                            <FormControl variant="outlined" fullWidth sx={{rowGap: '1rem', marginTop: '0.5rem'}}>
                                                {menuOption.MenuOptionValues.map((menuOptionValue, idx) => (
                                                        <>
                                                            {menuOptionValue?.OptionValue?.status === 1 && (
                                                                <Stack key={idx} direction='row' sx={{justifyContent: "start", alignItems: "center", columnGap: '2rem'}}>
                                                                    {selectValueCounts[menuOptionValue.option_value_id] >= menuOption.min ? (
                                                                        <Stack direction='row' sx={{alignItems: "center", columnGap: '1rem'}}>
                                                                            <IconButton sx={{backgroundColor: MAIN_GREEN_COLOR, '&:hover': { backgroundColor: green[400] } }} size='small' onClick={() => handleSelectValueSelection(menuOptionValue.option_value_id, menuOption, "decrement")}>
                                                                                <RemoveIcon sx={{color: 'white', height: '15px', width: '15px'}} />
                                                                            </IconButton>
                                                                            <Typography variant="body2">
                                                                                {selectValueCounts[menuOptionValue.option_value_id] || menuOption.min}
                                                                            </Typography>
                                                                            <IconButton sx={{backgroundColor: MAIN_GREEN_COLOR, '&:hover': { backgroundColor: green[400] } }} size='small' onClick={() => handleSelectValueSelection(menuOptionValue.option_value_id, menuOption, "increment")}>
                                                                                <AddIcon sx={{color: 'white', height: '15px', width: '15px'}} />
                                                                            </IconButton>
                                                                        </Stack>
                                                                    ) : (
                                                                        <IconButton sx={{backgroundColor: MAIN_GREEN_COLOR, '&:hover': { backgroundColor: green[400] } }} size='small' onClick={() => handleSelectValueSelection(menuOptionValue.option_value_id, menuOption, "increment")}>
                                                                            <AddIcon sx={{color: 'white', height: '15px', width: '15px'}}/>
                                                                        </IconButton>
                                                                    )}

                                                                    <Stack sx={{alignItems: 'start', justifyContent: 'center'}}>
                                                                        <Typography variant='body2' sx={{fontWeight: "600", fontSize: "14px"}}>
                                                                            {menuOptionValue.OptionValue.value}
                                                                        </Typography>

                                                                        <Typography variant="body2" sx={{fontSize: '12px', color: grey[500]}}>
                                                                            {menuOptionValue.new_price > 0 ? '+' + restaurantData.currency + calculateTotalPrice(menuOptionValue.new_price, menuOption.item_tax).toFixed(2) : t("free-0")}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Stack>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </FormControl>
                                        )}
                                            {menuOption.Option.display_type === "radio" && menuOption.MenuOptionValues.some((optionValue) => optionValue?.OptionValue?.status === 1) && (
                                                <>
                                                    {menuOption.MenuOptionValues.map((menuOptionValue, idx) => menuOptionValue?.OptionValue?.status === 1 && (
                                                            <Stack key={idx} sx={{alignItems: "start"}}>
                                                                <FormControl component="fieldset" className="w-100" sx={{rowGap: '1rem', marginTop: '0.5rem'}}>
                                                                    <RadioGroup aria-label={menuOption.Option.option_name}
                                                                                name={menuOption.Option.option_name}
                                                                                value={selectedRadioValues[index] || ""}
                                                                                onChange={(e) => handleRadioValueSelection(index, e.target.value, menuOption)}
                                                                                className="w-100">
                                                                        <FormControlLabel value={menuOptionValue.option_value_id}
                                                                                          control={<Radio sx={{'&.Mui-checked': { color: MAIN_GREEN_COLOR }}} checked={selectedRadioValues[index] === menuOptionValue.option_value_id} onChange={() => handleRadioChange(index, menuOptionValue.option_value_id)}/>}
                                                                                          label={
                                                                                              <Stack sx={{marginLeft: '1.5rem'}}>
                                                                                                  <Typography variant='body2' sx={{fontWeight: "600", fontSize: "14px"}}>
                                                                                                      {menuOptionValue.OptionValue.value}
                                                                                                  </Typography>
                                                                                                  <Typography variant="body2" sx={{fontSize: '12px', color: grey[500]}}>
                                                                                                      {menuOptionValue.new_price > 0 ? '+' + restaurantData.currency + calculateTotalPrice(menuOptionValue.new_price, menuOption.item_tax).toFixed(2) : t("free-1")}
                                                                                                  </Typography>
                                                                                              </Stack>
                                                                                          }/>
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Stack>
                                                        )
                                                    )}
                                                </>
                                            )}
                                            {menuOption.Option.display_type === "checkbox" && menuOption.MenuOptionValues.some((optionValue) => optionValue?.OptionValue?.status === 1) && (
                                                <>
                                                    {menuOption?.MenuOptionValues.map((menuOptionValue, idx) => menuOptionValue?.OptionValue?.status === 1 && (
                                                            <Stack key={idx} sx={{alignItems: "start", rowGap: '1rem', marginTop: '0.5rem'}}>
                                                                <FormControlLabel control={
                                                                    <Checkbox sx={{ '&.Mui-checked': { color : MAIN_GREEN_COLOR } }} checked={selectedCheckboxes.includes(menuOptionValue.option_value_id)}
                                                                              onChange={() => handleCheckboxValueSelection(menuOptionValue.option_value_id, menuOption)}
                                                                    />
                                                                }
                                                                                  label={
                                                                                      <Stack sx={{marginLeft: '1.5rem'}}>
                                                                                          <Typography sx={{fontWeight: "600", fontSize: "14px"}}>
                                                                                              {menuOptionValue.OptionValue.value}
                                                                                          </Typography>
                                                                                          <Typography variant="body2" sx={{fontSize: '12px', color: grey[500]}}>
                                                                                              {menuOptionValue.new_price > 0 ? restaurantData.currency + calculateTotalPrice(menuOptionValue.new_price, menuOption.item_tax).toFixed(2) : t("free-2")}
                                                                                          </Typography>
                                                                                      </Stack>
                                                                                  }
                                                                />
                                                            </Stack>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                )))}
                        </Stack>
                        <Stack>
                            <Typography variant='h6' sx={{fontSize: '18px', fontWeight: '600'}}>Special Instructions</Typography>
                            <TextField
                                fullWidth
                                sx={filledInputFieldsStyle}
                                label={"Add a Note"}
                                variant="filled"
                                size='small'
                                minRows={2}
                                maxRows={10}
                                draggable={true}
                                multiline
                                onChange={(e) => setSpecialInstruction(e.target.value)}
                            />
                        </Stack>
                    </Stack>


                    <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />

                    <Stack direction='row' sx={{padding: '1rem', columnGap: '1rem'}}>
                        {/*<Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                            <IconButton size='small' sx={{backgroundColor: green[700]}}>
                                <AddIcon sx={{height: '20px', width: '20px', color: 'white'}} />
                            </IconButton>
                            <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600'}}>
                                3
                            </Typography>
                            <IconButton size='small' sx={{backgroundColor: green[700]}}>
                                <RemoveIcon sx={{height: '20px', width: '20px', color: 'white'}}/>
                            </IconButton>
                        </Stack>*/}
                        <Stack direction='row' onClick={()=> addToCart()} sx={itemModal.addCartButton} >
                            <Typography variant='body2' sx={{fontSize: '16px', textAlign: 'center'}}>
                                Add to Cart - {restaurantData.currency} {totalAmount}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                message={openSnackBarMsg}
                onClose={() => {
                    setOpenSnackBar(false);
                    setOpenSnackBarMsg("");
                }}
            />
        </>
    )
}

export default ItemModal