import {getSessionHistory} from "../services/HistoryService";

const fetchData = async (location, session_id, hotel_session_id) => {
    const restaurantParam = location.pathname;

    try {
        let response;
        if (restaurantParam.includes("/restaurants/")) {
            response = await getSessionHistory(session_id);
        } else {
            response = await getSessionHistory(hotel_session_id);
        }

        if (response.error) {
            return (response)
        } else {
            response = response.data

            const total = calculateTotal(response.sessionInfo.Orders)
            return ({
                    sessionHistory: {
                        locationHistory: response.sessionInfo.Location,
                        orderHistory: response.sessionInfo.Orders,
                        currency: response.sessionInfo.Location.Currency,
                        orderItem: response.menuPrices
                    },
                    total: total,
                    error: false
                }
            )
        }
    } catch (error) {
        console.error("Error fetching Session History:", error);
        return ({error: true})
    }
};

export const calculateTotal = (order) => {
    let total = 0;
    for (let i = 0; i < order.length; i++) {
        for (let j = 0; j < order[i].OrderTotals.length; j++) {
            if (order[i].OrderTotals[j].code === "order_total") {
                total += order[i].OrderTotals[j].value;
            }
        }
    }
    return total
};

export default fetchData