import {Box, Stack, Tab, Tabs, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {searchSection} from "../../css/restaurantPageStyles";
import {useSelector} from "react-redux";
import {inputFieldsStyle} from "../../css/orderPlacementStyles";

const SearchSection = ({selectedTab ,setSelectedTabCategory}) => {

    const menu = useSelector((state)=> state.restaurantData.specificRestaurantMenuData)
    //(menu)

    const handleChange = (event, newValue) => {
        setSelectedTabCategory(newValue)

        const element = document.getElementById(`${newValue}`)
        if(element){
            const y = element.getBoundingClientRect().top + window.pageYOffset + (-50);

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const handleSearch = (value) => {

    }

    return(
        <Stack sx={searchSection.mainStack}>
            <TextField
                sx={{width: {md: '225px', lg: '225px', xl: '250px'}, ...inputFieldsStyle}}
                placeholder={'Search in menu'}
                variant='outlined'
                size='small'
                slotProps={{
                    input: {
                        startAdornment: <SearchIcon sx={{marginRight: '0.5rem'}}/>
                    }}}
                onChange={(e) => handleSearch(e.target.value)}
            />

            <Box>
                <Tabs
                    scrollButtons
                    value={selectedTab} onChange={handleChange}
                    sx={searchSection.tabsStyle} variant={'scrollable'}>
                    {
                        menu.map((item, index)=> (
                            <Tab label={item.name} key={item.category_id} value={item.category_id} sx={searchSection.tabStyle}/>
                        ))
                    }
                </Tabs>
            </Box>

        </Stack>
    )
}

export default SearchSection