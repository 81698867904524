import React, {useEffect, useState} from "react";
import {CONSTANTS, URLS} from "../../utils/constants";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Box, Grid, IconButton, Stack, Typography,} from "@mui/material";
import {useDispatch} from "react-redux";
import {getSessionRoom} from "../../services/MenuService";
import {setSessionId} from "../../features/sessionSlice";
import {getRoomId} from "../../features/cartSlice";
import Loader from "../Loader";
import {gridView} from "../../css/restaurantPageStyles";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const Restaurants = () => {
  const [locationData, setLocationData] = useState(null);
  const [roomId, setRoomId] = useState([]);
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const BASE_URL = `${URLS.BaseUrl}hotel/`;
        const response = await fetch(`${BASE_URL}${slug}`, {
          headers: CONSTANTS.REQUEST_HEADERS,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch location details");
        }

        const data = await response.json();
        setLocationData(data);
      } catch (error) {
        console.error("Error fetching location data:", error);
        setLocationData(null);
      }
    };

    fetchLocations();
  }, [slug]);

  useEffect(() => {

    // Extract room_id from URL parameters
    const params = new URLSearchParams(location.search);
    const roomIdFromParams = params.get("room_id");
    setRoomId(roomIdFromParams);

    if (roomIdFromParams) {
      // localStorage.setItem("roomId", roomIdFromParams);
      dispatch(getRoomId(roomIdFromParams));
      getSessionRoom(roomIdFromParams)
          .then((data) => {
            dispatch(setSessionId(data.session_id));
          })
          .catch((error) => {
            console.error("Error fetching session data:", error);
          });
    }
  }, [slug, location]);
  if (!locationData) {
    return (
        <div>
          {" "}
          <Loader />
        </div>
    );
  }

  const handleNavigation = (slug, roomid) => {
    navigate(`/restaurants/${slug}?room_id=${roomid}`)
  }

  return (
      <>
        <Grid container spacing={3}>
          {locationData?.restaurants?.map((restaurant, index) => (
              <Grid onClick={()=> handleNavigation(restaurant.slug, roomId)} key={index} item xs={12} sm={12} md={4} lg={3} xl={2.4} sx={{display: 'flex', alignItems: 'stretch', cursor: 'pointer'}}>
                <Stack sx={{...gridView.itemMainStack, backgroundColor: 'white'}}>
                  <Box component='img' src={`${URLS.BaseUrlWithoutWebsite}${restaurant.img}`} alt="Restaurant Image" sx={gridView.itemImage}/>
                  <Stack sx={gridView.infoStack}>
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                      <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>{restaurant.name}</Typography>
                      <IconButton>
                        <ErrorOutlineOutlinedIcon sx={{height: '25px', width: '25px', color: 'black'}}/>
                      </IconButton>
                    </Stack>
                    <Typography variant='body2' sx={{fontSize: '12px'}}>
                      {restaurant.category_name}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
          ))}
        </Grid>
      </>
  );
};

export default Restaurants;
