import {Box, Stack, TextField, Typography} from "@mui/material";
import {filledInputFieldsStyle, inputFieldsStyle} from "../../css/orderPlacementStyles";
import {useState} from "react";
import LocationModal from "./locationModal";
import {useDispatch, useSelector} from "react-redux";
import {setUserLocation} from "../../features/userSlice";
import DynamicMaps from "./dynamicMaps";
import LocationAutoComplete from "../generic/locationAutoComplete";

const MapSelection = () => {
    const [openLocationModal, setOpenLocationModal] = useState(false)
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch()

    const fields = [
        {
            name: 'Street Name',
            label: 'streetName',
        },
        {
            name: 'Street Number',
            label: 'streetNumber',
        },
        {
            name: 'House',
            label: 'house',
        }
    ]

    const handleAddressChange = (e, label) => {
        dispatch(setUserLocation({
            location: {
                [label]: e.target.value
            }
        }))
    }

    return(
        <Stack>
            <Stack rowGap='0.5rem'>
                <Typography variant='body2' sx={{fontSize: '0.95rem', fontWeight: '600'}}>Delivery Address</Typography>
                <Box sx={{height: 'fit-content', width: '100%', borderRadius: '5px'}}>
                    {/*<StaticMap usage={"fromRedux"} />*/}
                    <LocationAutoComplete usage={"checkout"} />
                    <DynamicMaps />
                </Box>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', padding: '0 2rem 0 0', minHeight: 'fit-content'}}>
                    <Stack>
                        <Typography variant='body2' sx={{fontSize: '1.1rem', fontWeight: '600'}}>{!user.location.streetName || user.isDeliverable === 'not_defined' ? "" : user.location.streetName}</Typography>
                        <Typography variant='body2' sx={{fontSize: '0.8rem', fontWeight: '400'}}>{!user.location.streetNumber || user.isDeliverable === 'not_defined' ? "" : user.location.streetNumber} {!user.location.house || user.isDeliverable === 'not_defined' ? "" : user.location.house}</Typography>
                    </Stack>
                    {/*<Typography variant='body2' sx={{cursor: 'pointer', fontSize: '1rem', textDecoration: 'underline', color: blue[500]}} onClick={()=> setOpenLocationModal(true)}>Edit</Typography>*/}
                </Stack>
                <Stack rowGap='1rem'>
                    {
                        fields.map((item, index)=> (
                            <TextField
                                variant='filled'
                                key={index}
                                sx={filledInputFieldsStyle}
                                label={item.name}
                                value={user.location[item.label]}
                                onChange={(e)=> handleAddressChange(e, item.label)}
                            />
                        ))
                    }
                </Stack>
            </Stack>
            {openLocationModal && <LocationModal openLocationModal={openLocationModal} setOpenLocationModal={setOpenLocationModal}/>}
        </Stack>
    )
}

export default MapSelection