import {grey} from "@mui/material/colors";
import {Avatar, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {MAIN_GREEN_COLOR} from "../../colors";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {LogoutOutlined} from "@mui/icons-material";

const sidebarButtons = [
    {
        icon: <PersonOutlineOutlinedIcon />,
        name: "Manage My Account",
        route: '/dashboard/profile',
    },

    {
        icon: <ShoppingBagOutlinedIcon />,
        name: "Orders",
        route: '/dashboard/orders'
    },

    {
        icon: <RoomServiceOutlinedIcon />,
        name: "Reservations",
        route: '/dashboard/reservations'
    },

    {
        icon: <LogoutOutlined />,
        name: "Logout",
    }
]

const Sidebar = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user.loggedInUserInformation);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleSelectTab = (index, route) => {
        setSelectedTab(index);
        navigate(route);
    }

    return(
        <>
            {!smallScreen &&
                <Stack sx={{rowGap: '2rem', maxWidth: '199px', width: '199px', minWidth: '199px', backgroundColor: 'white', borderRight: `1px solid ${grey[300]}`, height: 'inherit', padding: '0 20px'}}>
                    <Stack direction='row' sx={{columnGap: '0.5rem', alignItems: 'center', marginTop: '20px'}}>
                        <Avatar src={user.image ? user.image : <PersonOutlineOutlinedIcon/> } alt={user.name} sx={{height: '40px', width: '40px', borderRadius: '50%'}} />
                        <Stack>
                            <Typography variant='h6' sx={{fontSize: '14px', color: 'black', fontWeight: '600'}}>{user.name}</Typography>
                            <Typography variant='body2' sx={{fontSize: '12px', color: grey[500]}}>{user.number}</Typography>
                            <Typography variant='body2' sx={{fontSize: '12px', color: grey[500]}}>{user.email}</Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{rowGap: '0.5rem', marginBottom: '20px'}}>
                        {sidebarButtons.map((item, index) =>(
                            <Stack direction='row' sx={{cursor: 'pointer', columnGap: '1rem', alignItems: 'center', paddingY: '0.5rem'}} onClick={()=> handleSelectTab(index, item.route)}>
                                {item.icon}
                                <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '600', color: selectedTab === index ? MAIN_GREEN_COLOR : grey[800], transition: '0.3s'}} >{item.name}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            }
        </>
    )
}

export default Sidebar