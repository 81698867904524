// orderDataSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  packageData: null,
};

const orderDataSlice = createSlice({
  name: 'orderData',
  initialState,
  reducers: {
    setPackageData: (state, action) => {
      state.packageData = action.payload;
    },
  },
});

export const { setPackageData } = orderDataSlice.actions;

export default orderDataSlice.reducer;
