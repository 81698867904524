import {useSelector} from "react-redux";
import {Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import {useNavigate} from "react-router-dom";

const LocationInfo = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user.user);
    const destination = useSelector((state)=> state.restaurantData.specificRestaurantData)
    const GOOGLE_MAPS_API_KEY = 'AIzaSyBlNITR7jNNamUPFBzPa8q7r4sz9yJ43IU'

    const [directions, setDirections] = useState(null);

    useEffect(()=> {
        if (!user || !user.order_type) {
            navigate('/')
        }

        if (user.order_type !== "takeaway") {
            if (!user.location || !user.location.lat || !user.location.long) {
                navigate('/')
            }
        }

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin: {
                    lat: user.location.lat * 1,
                    lng: user.location.long * 1
                },
                destination: {
                    lat: destination.latitude * 1,
                    lng: destination.longitude * 1
                },
                travelMode: 'DRIVING'
            },
            (result, status) => {
                if (status === 'OK') {
                    setDirections(result);
                } else {
                    console.error(`Directions request failed due to ${status}`);
                }
            }
        );
    }, [])

    const [loc, setLoc] = useState({
        lat: user.location.lat,
        lng: user.location.long
    })

    useEffect(() => {
        setLoc({
            lat: user.location.lat,
            lng: user.location.long
        })
    }, [user.location]);

    const handleMapChange = (e) => {
        const newLocation = {
            lat: e.map.center.lat(),
            lng: e.map.center.lng()
        }
        setLoc(newLocation)
    }
    return(
        <Stack sx={{alignItems: 'stretch', flexGrow: 1, width: '100%'}}>
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
                <Map
                    style={{width: '100%', height: '100%'}}
                    defaultCenter={loc}
                    center={loc}
                    defaultZoom={15}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    onCenterChanged={(e) => handleMapChange(e)}
                >
                    <Marker position={{
                        lat: user.location.lat,
                        lng: user.location.long
                    }}>
                        {/*{directions && (*/}
                        {/*    <DirectionsRenderer*/}
                        {/*        directions={directions}*/}
                        {/*    />*/}
                        {/*)}*/}
                    </Marker>
                </Map>
            </APIProvider>
        </Stack>
    )
}

export default LocationInfo