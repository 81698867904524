import {ApiRoute, CONSTANTS} from "../utils/constants";
import axios from "axios";

export const createOrder = async ({
  user_id,
  table_id,
  location_id,
  session_date,
  start_time,
  end_time,
  session_id,
  order_time,
  order_date,
  order_type_id,
  order_variant,
  discount_id,
  total_discount,
  totalPrice,
  comment,
  qrcode,
  table_no,
  total_items,
  qrcodedata,
  promocode_id,
  hotel_id,
  room_id,
  payment_status_id,
  order_tax,
  session_owner,
  menu_items,
}) => {
  let data = {
    user_id,
    table_id,
    location_id,
    session_date,
    start_time,
    end_time,
    session_id,
    order_time,
    hotel_id,
  room_id,
    order_date,
    order_type_id,
    order_variant,
    discount_id,
    total_discount,
    totalPrice,
    comment,
    qrcode,
    table_no,
    total_items,
    qrcodedata,
    promocode_id,
    payment_status_id,
    order_tax,
    session_owner,
    menu_items: menu_items,
  };

  let response = await fetch(`${ApiRoute.createOrder}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });

  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Create Order: ${response}`);
};

export const getUserOrderHistory = async () => {
  let token = JSON.parse(localStorage.getItem("servallAuthUserToken"));
  if (token) {
    try {
      const response = await axios.get(ApiRoute.getUserOrderHistory, {
        headers: {
          ...CONSTANTS.REQUEST_HEADERS,
          "x-access-token": token
        },
      });

      if (!response || !response.data) {
        return ({error: true, message: 'Cannot Get User Order History'})
      } else {
        return ({error: false, data: response.data})
      }
    } catch (err) {
      return ({error: true, message: 'Cannot Get User Order History'})
    }
  } else {
    return ({error: true, message: 'Cannot Get User Order History'})
  }
}
