import {Drawer, IconButton, Stack, Tab, Tabs} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import MobileHistoryItems from "./mblHistoryItems";
import MobileCartCurrentItems from "./mblCartCurrentItems";
import {searchSection} from "../../css/restaurantPageStyles";
import {useDispatch, useSelector} from "react-redux";
import {setOpenCartDrawer} from "../../features/variablesSlice";

const MobileCart = () => {
    const dispatch = useDispatch();
    const [switchSelection, setSwitchSelection] = useState(false)
    const openCartDrawer = useSelector(state => state.variablesData.openCartDrawer);
    const handleChange = (event, newValue) => {
        setSwitchSelection(newValue)
    }

    return(
        <Drawer open={openCartDrawer} onClose={()=> dispatch(setOpenCartDrawer(false))} anchor={'right'}>
            <Stack sx={{width: {xs: '90vw', sm: '320px'}, maxWidth: '320px', padding: '1rem', rowGap: '0.5rem'}}>
                <Stack sx={{display: 'flex', alignItems: 'end', justifyContent: 'center'}}>
                    <IconButton size='small' onClick={()=> dispatch(setOpenCartDrawer(false))}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}} />
                    </IconButton>
                </Stack>
                <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem'}}>
                    <Tabs
                        value={switchSelection}
                        onChange={handleChange}
                        sx={searchSection.tabsStyle} variant={'fullWidth'}>
                        <Tab label={'Cart'} key={1} value={'cart'} sx={searchSection.tabStyle}/>
                        <Tab label={'History'} key={2} value={'history'} sx={searchSection.tabStyle}/>
                    </Tabs>
                </Stack>
                {
                    switchSelection === 'history' ? (<MobileHistoryItems />) : (<MobileCartCurrentItems /> )
                }
            </Stack>
        </Drawer>
    )
}

export default MobileCart