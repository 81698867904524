import {Button, Stack, TextField, Typography} from "@mui/material";
import {filledInputFieldsStyle, inputFieldsStyle} from "../../css/orderPlacementStyles";

const CodeVerification = ({setFlow, data, setData}) => {

    const handleInputChange = (e, label) => {
        setData({
            ...data,
            [label]: e.target.value
        })
    }

    return(
        <>
            <Stack width='100%'>
                <Typography variant='body2' sx={{fontSize: '12px'}}>Enter the code sent to {data.phone}</Typography>
                <TextField variant={'filled'} sx={filledInputFieldsStyle} label={'Code'} fullWidth type={'phone'} value={data.email} onChange={(e)=> handleInputChange(e, "code")}/>
            </Stack>
            <Typography variant='body2' sx={{fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', textAlign: 'start', alignSelf: 'start'}}>Resend Code</Typography>
            <Button sx={{backgroundColor: '#3ece8e', color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '16px', borderRadius: '10px'}} fullWidth onClick={()=> setFlow('infoForm')}>Next</Button>
        </>
    )
}

export default CodeVerification