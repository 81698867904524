import React, {useEffect, useState} from "react";
import {Button, Stack} from "@mui/material";
import {AdvancedMarker, APIProvider, Map} from "@vis.gl/react-google-maps";
import {useDispatch, useSelector} from "react-redux";
import {setUserDeliveryStatus, setUserLocation} from "../../features/userSlice";
import {getLocationData, verifyLocation} from "../../services/LocationService";
import {dynamicMapsStyles} from "../../css/checkoutStyles";

const DynamicMaps = () => {
    const location = useSelector((state) => state.user.user.location);
    const dispatch = useDispatch()
    const restaurant = useSelector((state) => state.restaurantData.specificRestaurantData)
    const GOOGLE_MAPS_API_KEY = 'AIzaSyBlNITR7jNNamUPFBzPa8q7r4sz9yJ43IU'

    const [loc, setLoc] = useState({
        lat: location.lat,
        lng: location.long
    })

    useEffect(() => {
        setLoc({
            lat: location.lat,
            lng: location.long
        })
    }, [location]);

    const handleChangeLocation = (e) => {
        const newLocation = {
            lat: e.map.center.lat(),
            lng: e.map.center.lng()
        }
        setLoc(newLocation)
    }

    const handleCancelEvent = () => {
        //setLoc({})
        //setOpenLocationModal(false)
    }

    const handleSaveAddress = async () => {
        try {
            const response = await getLocationData(loc.lat, loc.lng, GOOGLE_MAPS_API_KEY)
            const userLocation = {
                locationName: response.data.results[0].formatted_address,
                address: response.data.results[0].formatted_address,
                lat: response.data.results[0].geometry.location.lat,
                long: response.data.results[0].geometry.location.lng,
                country: '',
                state: '',
                city: '',
                streetName: '',
                streetNumber: '',
                postal_code: '',
                place_id: response.data.results[0].place_id
            }

            response.data.results[0].address_components.map((item) => {
                if (item.types.includes('country')) {
                    userLocation.country = item.long_name
                }
                if (item.types.includes('administrative_area_level_1')) {
                    userLocation.state = item.long_name
                }
                if (item.types.includes('locality')) {
                    userLocation.city = item.long_name
                }
                if (item.types.includes('route')) {
                    userLocation.streetName = item.long_name
                }
                if (item.types.includes('street_number')) {
                    userLocation.streetNumber = item.long_name
                }
                if (item.types.includes('postal_code')) {
                    userLocation.postal_code = item.long_name
                }
            })
            dispatch(setUserLocation({
                location: {...userLocation}
            }))
            const res = await verifyLocation(restaurant.loc_id, loc.lng, loc.lat)
            dispatch(setUserDeliveryStatus({isDeliverable: res.delivery === true ? 'deliverable' : 'not_deliverable'}))
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Stack sx={{width: '100%', rowGap: '1rem', position: 'relative', marginTop: '0.5rem'}}>
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
                <Map
                    mapId={'513e271a218029d4'}
                    center={loc}
                    style={{width: '100%', height: '350px'}}
                    defaultCenter={loc}
                    defaultZoom={15}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    onCenterChanged={(e) => handleChangeLocation(e)}
                >
                    <AdvancedMarker position={loc} />
                </Map>
            </APIProvider>
            <Button sx={{...dynamicMapsStyles.saveButton, position: 'absolute', bottom: '0.5rem', left: '0.5rem', width: '20%', height: '45px'}} onClick={()=> handleSaveAddress()}>Set Pin</Button>
            {/*<Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', columnGap: '1rem'}}>
                <Button sx={dynamicMapsStyles.cancelButton} onClick={()=> handleCancelEvent()}>Cancel</Button>
                <Button sx={dynamicMapsStyles.saveButton} onClick={()=> handleSaveAddress()}>Set Pin</Button>
            </Stack>*/}
        </Stack>
    );
}

export default DynamicMaps