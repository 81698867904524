import {ApiRoute, CONSTANTS} from '../utils/constants';
import axios from "axios";

export const getSessionHistory = async (sessionId) => {
  try {
    let response = await axios.get(`${ApiRoute.getSessionHistory}/${sessionId}`, {
      headers: {...CONSTANTS.REQUEST_HEADERS},
    });

    if (!response || !response.data) {
      return ({error: true, message: 'Cannot get Session History Data'})
    } else {
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot get Session History Service'})
  }
};
