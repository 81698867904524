import {Button, Divider, Modal, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import {MAIN_GREEN_COLOR} from "../../colors";
import {deleteAccount} from "../../services/AuthService";
import {setUser} from "../../features/userSlice";
import {useDispatch} from "react-redux";

const DeleteAccountModal = ({openModal, setOpenModal}) => {
    const dispatch = useDispatch();

    const handleAccountDeletion = async () => {
        const response = await deleteAccount();

        if(!response.error) {
            localStorage.removeItem('servallAuthUserToken');
            dispatch(setUser({
                user_type: 'guest',
                phoneNumber: '',
                order_type: 'non',
                location: {
                    lat: 51.509865,
                    long: -0.118092,
                    streetName: '',
                    streetNumber: '',
                    house: '',
                    city: '',
                    country: '',
                    address: 'Select an Address'
                },
                deliveryOptions: {},
                orderDateTime: {
                    schedule: 'now',
                    date: '',
                    timeSlot: '',
                    start_time: '',
                    end_time: '',
                    formattedDate: ''
                },
                firstName: '',
                lastName: '',
                email: '',
                isDeliverable: 'not_defined',
                loggedInUserInformation: {}
            }));
            setOpenModal(false);
            window.location.reload();
        }
    }

    return(
        <Modal open={openModal} onClose={()=> setOpenModal(false)} sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Stack sx={{backgroundColor: 'white', borderRadius: '0.25rem', padding: '1rem', width: {xs: '300px', md: '400px'}, rowGap: '1rem'}}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: 'bold'}}>
                        Confirm Account Deletion
                    </Typography>
                    <IconButton size='small' sx={{backgroundColor: grey[500]}} onClick={()=> setOpenModal(false)}>
                        <Close sx={{height: '1rem', width: '1rem', color: 'white'}} />
                    </IconButton>
                </Stack>
                <Divider />
                <Typography variant='body2' sx={{fontSize: '0.8rem', fontWeight: '400'}}>
                    Are you sure you want to delete your account and customer data from Servall?
                </Typography>
                <Typography variant='body2' sx={{fontSize: '0.8rem'}}>
                    This action is permanent and cannot be undone.
                </Typography>
                <Divider />
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'end', columnGap: '0.5rem'}}>
                    <Button onClick={()=> setOpenModal(false)} sx={{backgroundColor: 'black', fontSize: '0.8rem', color: 'white', textTransform: 'capitalize'}}>
                        Cancel Deletion
                    </Button>
                    <Button onClick={()=> handleAccountDeletion()} sx={{backgroundColor: MAIN_GREEN_COLOR, fontSize: '0.8rem', color: 'white', textTransform: 'capitalize'}}>
                        Confirm Deletion
                    </Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default DeleteAccountModal;