import {ApiRoute, CONSTANTS} from "../utils/constants";
import axios from "axios";

export const updateUserProfile = async (data) => {
    try {
        const response = await axios.put(ApiRoute.updateUserProfileInfo, {...data}, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS,
                "x-access-token" : JSON.parse(localStorage.getItem('servallAuthUserToken'))
            },
        })

        if (!response || !response.data) {
            return ({error: true, message: 'Cannot Update User Profile Info'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        console.log("Error While Updating User Profile", err)
        return ({error: true, message: 'Cannot Update User Profile Info'})
    }
}

export const updatePassword = async (data) => {
    try {
        const response = await axios.post(ApiRoute.updatePassword, {...data}, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS,
                'x-access-token': JSON.parse(localStorage.getItem('servallAuthUserToken'))
            },
        })

        if (!response || !response.data) {
            return ({error: true, message: 'Cannot Update Password'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        console.log("Error While Updating Password", err)
        return ({error: true, message: 'Cannot Update Password'})
    }
}