import Container from "@mui/material/Container";
import {Box, Button, Stack, Typography} from "@mui/material";
import {joinUsStyles} from "../../css/homePageStyles";

const JoinUs = () => {
    return(
        <Box>
            <Container maxWidth='xl'>
                <Stack sx={joinUsStyles.mainImageStackStyle}>
                    <Typography variant='body2' sx={{color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: '16px'}}>Are you a restaurant owner?</Typography>
                    <Typography variant='h3' sx={{color: 'white', fontWeight: '400', fontSize: {xs: '26px', sm: '30px', md: '45px'}}}>Join us and reach new customers</Typography>
                    <Typography variant='body2' sx={{color: 'white', fontSize: '16px'}}>Join us and reach new customers</Typography>
                    <Button variant='contained' sx={joinUsStyles.buttonStyle} onClick={()=> window.open('https://www.servall.be')}>Join</Button>
                </Stack>
            </Container>
        </Box>
    )
}

export default JoinUs