import FacebookIcon from "@mui/icons-material/Facebook";
import {Instagram, Twitter} from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {Box, Grid2, IconButton, OutlinedInput, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";
import logo from "../assets/Servall_Logo.svg";

const Footer = () => {
    return(
        <>
            <Box sx={{backgroundColor: '#f8f8f8'}}>
                <Container maxWidth='xl'>
                    <Stack sx={{marginX: 'auto', maxWidth: {sm: '550px',md: '850px', lg: '1150px', xl: '1500px'}, alignItems: 'center', justifyContent: 'center', padding: '2rem 0'}}>
                        <Grid2 container rowGap={'1rem'} sx={{width: '100%'}}>
                            <Grid2 size={{xs: 12, sm: 6, md: 4}} sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <Box component='img' src={logo} alt={'logo'} sx={{height: '90px', width: '175px'}} />
                            </Grid2>
                            <Grid2 size={{xs: 12, sm: 6, md: 4}}>
                                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem', height: '100%'}}>
                                    <FacebookIcon sx={{height: '25px', width: '25px', color: 'black', cursor: 'pointer'}}/>
                                    <Instagram sx={{height: '25px', width: '25px', color: 'black', cursor: 'pointer'}}/>
                                    <LinkedInIcon sx={{height: '25px', width: '25px', color: 'black', cursor: 'pointer'}}/>
                                    <Twitter sx={{height: '25px', width: '25px', color: 'black', cursor: 'pointer'}}/>
                                    <YouTubeIcon sx={{height: '25px', width: '25px', color: 'black', cursor: 'pointer'}}/>
                                </Stack>
                            </Grid2>
                            <Grid2 size={{xs: 12, md: 4}}>
                                <Stack direction='row' columnGap={'1rem'} sx={{alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                                    <OutlinedInput
                                        sx={{flexGrow: 0}}
                                        placeholder="Email"
                                    />
                                    <IconButton sx={{backgroundColor: green[500], borderRadius: '5px', padding: '0.5rem 0.75rem', '&:hover' :{backgroundColor: green[700]}, height: 'auto'}}>
                                        <Typography sx={{color: 'white', height: '25px'}}>Subscribe</Typography>
                                    </IconButton>
                                </Stack>
                            </Grid2>
                        </Grid2>

                        <Grid2 container sx={{width: '100%'}}>
                            <Grid2 size={{xs: 6, sm: 6, md: 4, lg: 3, xl: 2.4}}>
                                <Stack sx={{rowGap: '0.5rem'}}>
                                    <Typography sx={{fontWeight: '700', fontSize: '14px', marginY: '1rem'}}>1234567</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Privacy Policy</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Term & Conditions</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Privacy Policy</Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 size={{xs: 6, sm: 6, md: 4, lg: 3, xl: 2.4}}>
                                <Stack sx={{rowGap: '0.5rem'}}>
                                    <Typography sx={{fontWeight: '700', fontSize: '14px', marginY: '1rem'}}>TnS</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Privacy Policy</Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 size={{xs: 6, sm: 6, md: 4, lg: 3, xl: 2.4}}>
                                <Stack sx={{rowGap: '0.5rem'}}>
                                    <Typography sx={{fontWeight: '700', fontSize: '14px', marginY: '1rem'}}>Test</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Google</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Terms & Conditions</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Privacy Policy</Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 size={{xs: 6, sm: 6, md: 4, lg: 3, xl: 2.4}}>
                                <Stack sx={{rowGap: '0.5rem'}}>
                                    <Typography sx={{fontWeight: '700', fontSize: '14px', marginY: '1rem'}}>Company</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Careers</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Investors</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Press</Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 size={{xs: 6, sm: 6, md: 4, lg: 3, xl: 2.4}}>
                                <Stack sx={{rowGap: '0.5rem'}}>
                                    <Typography sx={{fontWeight: '700', fontSize: '14px', marginY: '1rem'}}>Terms</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Privacy Policy</Typography>
                                    <Typography sx={{fontSize: '13px'}}>Term & Conditions</Typography>
                                </Stack>
                            </Grid2>
                        </Grid2>

                        <Stack direction={{xs: 'column', md: 'row'}} sx={{rowGap: '0.5rem', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '2rem'}}>
                            <Stack>
                                <Typography variant='body2'>
                                    © Servall
                                </Typography>
                            </Stack>
                            <Stack direction={{xs: 'column', md: 'row'}} columnGap='1rem' sx={{color: 'white', justifyContent: 'center', rowGap: '1rem'}}>
                                <Stack direction='row' sx={{padding: '0rem 0.5rem', borderRadius: '3px', alignItems: 'center', justifyContent: 'center', backgroundColor: 'black', cursor: 'pointer'}}>
                                    <AppleIcon sx={{height: '30px', width: '30px', color: 'white'}} />
                                    <Stack sx={{padding: '0.5rem 0.75rem'}}>
                                        <Typography variant='body2' sx={{fontSize: '10px'}}>
                                            Download on the
                                        </Typography>
                                        <Typography variant='body2'>
                                            App Store
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction='row' sx={{padding: '0rem 0.5rem', borderRadius: '3px', alignItems: 'center', justifyContent: 'center', backgroundColor: 'black', cursor: 'pointer'}}>
                                    <ShopIcon sx={{height: '30px', width: '30px', color: 'white'}} />
                                    <Stack sx={{padding: '0.5rem 0.75rem', borderRadius: '3px'}}>
                                        <Typography variant='body2' sx={{fontSize: '10px'}}>
                                            Get it on
                                        </Typography>
                                        <Typography variant='body2'>
                                            Google Play
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Typography variant='body2'>
                                    Website: demo.servall.com
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </>
    )
}

export default Footer