// sessionSlice.js
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  session_id: null,
  hotel_session_id: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionId(state, action) {
      state.session_id = action.payload;
    },
    setHotelSessionId(state, action) {
      state.hotel_session_id = action.payload;
    },
  },
});

export const { setSessionId, setHotelSessionId } = sessionSlice.actions;
export default sessionSlice.reducer;
