import {IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import {locationModalStyles} from "../../css/restaurantPageStyles";
import MoreLocationInformation from "./moreLocationInformation";
import DynamicMaps from "./dynamicMaps";
import LocationAutoComplete from "../generic/locationAutoComplete";
import StaticMap from "../generic/staticMap";

const LocationModal = ({openLocationModal, setOpenLocationModal}) => {
    const [location, setLocation] = useState({});
    const [view, setView] = useState('normal')

    return(
        <Modal open={openLocationModal} onClose={()=> {
            setOpenLocationModal(false);
            setLocation({})
        }} sx={locationModalStyles.modalStyle}>
            <Stack sx={{...locationModalStyles.mainStack, width: {xs: '90vw', sm: '550px', md: '850px'}, maxHeight: '90vh', overflow: 'auto'}}>
                <Stack sx={{alignItems: 'center', justifyContent: 'space-between'}} direction='row'>
                    <Typography variant='h5'>Delivery Address</Typography>
                    <IconButton size='small' onClick={()=> {
                        setOpenLocationModal(false);
                        setLocation({})
                    }}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}} />
                    </IconButton>
                </Stack>
                {view === 'normal' ? <>
                    {(!location || !location.lat && !location.long ) &&
                        <LocationAutoComplete usage={'mainRestaurant'} location={location} setLocation={setLocation}/>}
                    {(location && location.lat && location.long) && (<>
                        <StaticMap latitude={location.lat} longitude={location.long} usage={"fromProps"}/>
                        <MoreLocationInformation setOpenLocationModal={setOpenLocationModal} setView={setView} location={location} setLocation={setLocation}/>
                    </>)}
                </> : <DynamicMaps setView={setView} location={location} setLocation={setLocation}/>
                }
            </Stack>
        </Modal>
    )
}

export default LocationModal