import TopInformation from "../sections/restaurant/topInformation";
import SearchSection from "../sections/restaurant/searchSection";
import {Box, Container, Slide, Stack, Tab, Tabs, Typography, useMediaQuery, useTheme} from "@mui/material";
import {cartMainBox, cartSmallScreenButton, responsiveStack, searchSection} from "../css/restaurantPageStyles";
import React, {useEffect, useState} from "react";
import MenuSection from "../sections/restaurant/menuSection";
import Cart from "../sections/restaurant/cart";
import MobileCart from "../sections/restaurant/mblCart";
import {getLocationDetail} from "../services/LocationService";
import {
    RestaurantData,
    RestaurantMenuData,
    RestaurantOriginalSlots,
    RestaurantTimeSlotsData
} from "../features/restaurantSlice";
import {getLocationMenu, getSession, getSessionRoom} from "../services/MenuService";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import History from "../sections/restaurant/history";
import DeliveryTakeawayCart from "../sections/restaurant/deliveryTakeawayCart";
import DeliveryPickupMobileCart from "../sections/restaurant/deliveryPickupMobileCart";
import {getTimeSlots} from "../services/TimeSlotsService";
import dayjs from "dayjs"
import SnackBar from "../components/snackbar";
import {setHotelSessionId, setSessionId} from "../features/sessionSlice";
import {getRoomId, getTableId, getTableName} from "../features/cartSlice";
import RestaurantPageSkeleton from "../skeletons/restaurantPageSkeleton";
import ReservationForm from "../sections/restaurant/reservationForm";
import Error500 from "./error500";
import {setOpenCartDrawer} from "../features/variablesSlice";

const Restaurant = () => {
    const dispatch = useDispatch();
    const restaurantData = useSelector(state => state.restaurantData.specificRestaurantData)
    const [switchSelection, setSwitchSelection] = useState('cart')
    const [showReservation, setShowReservations] = useState(false)
    const [selectedTabCategory, setSelectedTabCategory] = useState(1);
    const [selectedOption, setSelectedOption] = useState(1);
    const location = useLocation();
    const queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const roomId = searchParams.get("room_id");
    const tableId = searchParams.get("table_id");
    const tableName = searchParams.get("table_name");
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        severity: 'success',
        message: '',
    });
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()

    const {cart} = useSelector((state) => state.allCart);
    const {slug} = useParams()
    const restaurantParam = location.pathname;

    const fetchRestaurantData = async () => {
        const worker = new Worker(new URL('../workers/fetchRestaurantDataWorker.js', import.meta.url));
        worker.postMessage({ slug, roomId, tableId, tableName, restaurantParam });

        worker.onmessage = async (event) => {
            const {error, data, message} = event.data;
            if (error) {
                setIsError({error: true, message});
            } else {
                dispatch(RestaurantData(data.restaurantData));
                dispatch(RestaurantMenuData(data.menuData));
                dispatch(RestaurantOriginalSlots(data.originalSlots));
                dispatch(RestaurantTimeSlotsData(data.timeSlotsArray));

                if (roomId) {
                    dispatch(getRoomId(roomId));

                    const roomData = await getSessionRoom(roomId, data.restaurantData.loc_id);
                    if (restaurantParam.includes("/restaurants/") && roomData.session_id && roomData) {
                        // dispatch(setSessionId(data.session_id));
                        dispatch(setSessionId(roomData.session_id));
                        dispatch(setHotelSessionId(null));
                    }
                    dispatch(getTableId(null));
                    dispatch(getTableName(null));
                } else {
                    if (tableId) {
                        dispatch(getTableId(tableId));
                        dispatch(getTableName(tableName));
                        dispatch(getRoomId(null));

                        getSession(tableId)
                            .then((data) => {
                                dispatch(setSessionId(data.session_id));
                            })
                            .catch((error) => {
                                console.error("Error fetching session data:", error);
                            });
                    }
                }
            }
            setLoading(false);
        };

        worker.onerror = (error) => {
            setIsError({ error: true, message: error.message });
            setLoading(false);
        };

    }

    useEffect(() => {
        fetchRestaurantData()
    }, [dispatch, roomId]);

    const handleChange = (event, newValue) => {
        setSwitchSelection(newValue)
    }

    return(
        (loading ? (
            <>
                <RestaurantPageSkeleton />
            </>
        ) : (!isError.error ?
                <>
                    <TopInformation selectedOption={selectedOption} setSelectedOption={setSelectedOption} setShowReservation={setShowReservations} showReservation={showReservation}/>
                    <Container maxWidth='xl' sx={{padding: {xs: '1rem', md: '2rem'}}}>
                        {!showReservation ? <Stack direction='row' columnGap='0.75rem' sx={responsiveStack}>
                                <Stack rowGap='1rem'>
                                    <SearchSection selectedTab={selectedTabCategory} setSelectedTabCategory={setSelectedTabCategory}/>
                                    <MenuSection setSelectedTab={setSelectedTabCategory}/>
                                </Stack>
                                {
                                    selectedOption === 0 || selectedOption === 2 ? (
                                            (
                                                sm ?
                                                    <DeliveryPickupMobileCart></DeliveryPickupMobileCart>
                                                    :
                                                    <Box>
                                                        <Stack sx={cartMainBox}>
                                                            <DeliveryTakeawayCart />
                                                        </Stack>
                                                    </Box>
                                            )
                                        ) :
                                        (
                                            sm ?
                                                <MobileCart/>
                                                :
                                                <Box>
                                                    <Stack sx={cartMainBox}>
                                                        <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem'}}>
                                                            <Tabs
                                                                value={switchSelection}
                                                                onChange={handleChange}
                                                                sx={searchSection.tabsStyle} variant={'fullWidth'}>
                                                                <Tab label={'Cart'} key={1} value={'cart'} sx={searchSection.tabStyle}/>
                                                                <Tab label={'History'} key={2} value={'history'} sx={searchSection.tabStyle}/>
                                                            </Tabs>
                                                        </Stack>
                                                        {
                                                            switchSelection === 'history' ? (<History />) : (<Cart />)
                                                        }
                                                    </Stack>
                                                </Box>
                                        )
                                }
                            </Stack> :
                            ((restaurantData && restaurantData.reservation) && <Stack sx={{responsiveStack}}>
                            <ReservationForm setShowReservation={setShowReservations}/>
                            </Stack>)
                        }
                            </Container>
                        {sm &&
                            <Slide direction='up' in={cart.length > 0 && sm} timeout={500}>
                        <Stack direction='row' onClick={()=> dispatch(setOpenCartDrawer(true))} sx={cartSmallScreenButton}>
                            <Stack sx={{marginLeft: '2rem'}}>
                                <Typography variant='body2' sx={{color: 'white', fontSize: '12px'}}>View Order</Typography>
                                <Typography variant='body2' sx={{color: 'white', fontSize: '14px', fontWeight: '600px'}}>{restaurantData.name}</Typography>
                            </Stack>
                            <Typography variant='body2' sx={{marginRight: '2rem', color: 'white', fontSize: '14px', fontWeight: '600px'}}>{cart.length}</Typography>
                        </Stack>
                    </Slide>
                    }

                    <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
                </> :
                <Error500 message={isError.message} />
        ))
    )
}

export default Restaurant