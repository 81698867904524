import React, {useEffect, useState} from "react";
import {Link as RouterLink, useLocation, useParams} from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import WifiIcon from "@mui/icons-material/Wifi";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import PoolIcon from "@mui/icons-material/Pool";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import HotelIcon from "@mui/icons-material/Hotel";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import {AccessTime, LocalLaundryService} from "@mui/icons-material";
import SpaIcon from "@mui/icons-material/Spa";
import IconButton from "@mui/material/IconButton";
import {getRoomId} from "../features/cartSlice";
import Restaurants from "./Hotel Features/Restaurants";

import {
  Box,
  Button,
  CardContent,
  Container,
  createTheme,
  Grid, Grid2,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {CONSTANTS, URLS} from "../utils/constants";
import Loader from "./Loader";
import {useDispatch} from "react-redux";
import {getSessionRoom} from "../services/MenuService";
import {setSessionId} from "../features/sessionSlice";
import GuestBook from "./Hotel Features/GuestBook";
import {RestaurantData} from "../features/restaurantSlice";
import News from "./Hotel Features/News";
import UpcomingEvents from "./Hotel Features/UpcomingEvents";
import {grey} from "@mui/material/colors";
import {largeScreenCart, searchSection, topInformationStyles} from "../css/restaurantPageStyles";
import AmenitiesModal from "./Hotel Features/amenitiesModal";
import {mainStyles} from "../css/hotelStyles";
import PremiumServices from "./premiumServices";
import StaticMap from "./generic/staticMap";

const ameties = [
  { icon: <PoolIcon />, label: "Pool" },
  { icon: <LocalParkingIcon />, label: "Free Parking" },
  { icon: <FitnessCenterIcon />, label: "Gym" },
  { icon: <RestaurantIcon />, label: "Restaurant" },
  { icon: <LocalBarIcon />, label: "Bar" },
  { icon: <WifiIcon />, label: "Free WiFi" },
  {
    icon: <AirportShuttleIcon />,
    label: "Airport Transfer",
  },
  { icon: <SpaIcon />, label: "Spa" },
  {
    icon: <LocalLaundryService />,
    label: "Laundry Facilities",
  },
  { icon: <AcUnitIcon />, label: "Air Conditioning" },
  {
    icon: <HotelIcon />,
    label: "Connecting Rooms Available",
  },
  { icon: <AccessTime />, label: "24/7 Front Desk" },
]

const LinkWrapper = styled(RouterLink)({
  textDecoration: "none",
  color: (theme) => theme.palette.text.primary,
});
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  padding: theme.spacing(2),

  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "skyblue", // Change background color on hover
    color: "white",
  },
}));

// Override default theme
const theme = createTheme();

const HotelPage = () => {
  const [locationData, setLocationData] = useState(null);
  const [roomId, setRoomId] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const [userLocation, setUserLocation] = useState(null);
  const dispatch = useDispatch();

  const { slug } = useParams();
  const location = useLocation();

  useEffect(() => {
    const loadTomorrowScript = () => {
      if (document.getElementById("tomorrow-sdk")) {
        if (window.__TOMORROW__) {
          window.__TOMORROW__.renderWidget();
        }
        return;
      }

      const script = document.createElement("script");
      script.id = "tomorrow-sdk";
      script.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
        if (window.__TOMORROW__) {
          window.__TOMORROW__.renderWidget();
        }
      };
      script.onerror = () => {
        console.error("Failed to load the Tomorrow.io script");
      };
      document.body.appendChild(script);
    };

    loadTomorrowScript();

    // Fetch user's location
    try {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
      );
    } catch (err) {
      console.error(err)
    }

    return () => {
      const script = document.getElementById("tomorrow-sdk");
      if (script && script.parentNode === document.body) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {

    // Extract room_id from URL parameters
    const params = new URLSearchParams(location.search);
    const roomIdFromParams = params.get("room_id");
    setRoomId(roomIdFromParams);

    if (roomIdFromParams) {
      // localStorage.setItem("roomId", roomIdFromParams);
      dispatch(getRoomId(roomIdFromParams));
      getSessionRoom(roomIdFromParams)
          .then((data) => {
            dispatch(setSessionId(data.session_id));
          })
          .catch((error) => {
            console.error("Error fetching session data:", error);
          });
    }
  }, [slug, location]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const BASE_URL = `${URLS.BaseUrl}hotel/`;
        const response = await fetch(`${BASE_URL}${slug}`, {
          headers: CONSTANTS.REQUEST_HEADERS,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch location details");
        }

        const data = await response.json();
        dispatch(RestaurantData(data?.hotel));

        setLocationData(data);
      } catch (error) {
        console.error("Error fetching location data:", error);
        setLocationData(null);
      }
    };

    fetchLocations();
  }, [slug]);
  if (!locationData) {
    return (
        <div>
          {" "}
          <Loader />
        </div>
    );
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <>
        <Container maxWidth='xl' sx={{ backgroundColor: 'white', padding: '0.5rem'}}>
          {userLocation && (
              <Box
                  className="tomorrow"
                  data-latitude={userLocation.latitude}
                  data-longitude={userLocation.longitude}
                  data-language="EN"
                  // data-unit-system="METRIC"
                  // data-skin="dark"
                  // data-widget-type="summary"
                  data-unit-system="METRIC"
                  data-skin="light"
                  data-widget-type="upcoming"
                  style={{
                    // width: "500px", // Adjust the width here
                    paddingBottom: "22px",
                    position: "relative",
                  }}
              >
                <Box component='a'
                    href="https://www.tomorrow.io/weather-api/"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    style={{
                      position: "absolute",
                      bottom: 0,
                      transform: "translateX(-50%)",
                      left: "50%",
                    }}
                >
                  <Box component='img'
                      alt="Powered by the Tomorrow.io Weather API"
                      src="https://weather-website-client.tomorrow.io/img/powered-by.svg"
                      width="250"
                      height="18"
                  />
                </Box>
              </Box>
          )}

          <Box sx={topInformationStyles.responsiveStack}>
            <Stack sx={topInformationStyles.mainStack}>
              <Stack sx={topInformationStyles.storeInfoStack}>
                <Box sx={topInformationStyles.imageContainer}>
                  <Box component='img' src={`${URLS.BaseUrlWithoutWebsite}${locationData?.hotel?.img}`} alt={'hotel'} sx={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: '5px'}} />
                </Box>
                <Stack sx={{rowGap: '0.5rem', alignItems: 'start', justifyContent: 'space-between'}}>
                  <Typography variant='h4' sx={{textTransform: 'capitalize'}}>
                    {locationData?.hotel?.name}
                  </Typography>
                  <Stack direction={{xs: 'column', sm: 'row'}} sx={{alignItems: 'start'}}>
                    <Stack sx={topInformationStyles.ratingMainStack}>
                      <HotelIcon sx={{height: '18px', width: '18px', color: 'black'}} />
                      <Typography variant='body2' sx={{color: grey[800], fontSize: {xs: '12px', md: '14px'}}} >
                        {locationData?.hotel?.formattedAddress} .
                      </Typography>
                    </Stack>

                    <Stack sx={topInformationStyles.ratingMainStack}>
                      <MailOutlineIcon sx={{height: '18px', width: '18px', color: 'black'}} />
                      <Typography variant='body2' sx={{color: grey[800], fontSize: {xs: '12px', md: '14px'}}} >
                        {locationData?.hotel?.location_email} .
                      </Typography>
                    </Stack>

                    <Stack sx={topInformationStyles.ratingMainStack}>
                      <PhoneIcon sx={{height: '18px', width: '18px', color: 'black'}} />
                      <Typography variant='body2' sx={{color: grey[800], fontSize: {xs: '12px', md: '14px'}}} >
                        {locationData?.hotel?.location_telephone}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Typography variant="body2" color="text.secondary" sx={{fontSize: '0.8rem', fontWeight: '600'}}>
                    {showMore ? "All Amenities:" : "Popular Amenities:"}
                  </Typography>
                  <Grid2 container spacing={0.5}>
                    {ameties.slice(0, showMore ? undefined : 4)
                        .map((amenity, index) => (
                            <Grid2 size={{xs: 6, sm: 6, md: 4, lg: 3}} sx={{textAlign: 'center',flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              {amenity.icon}
                              <Typography variant="body2" color="text.secondary" sx={{fontSize: '0.8rem'}}>
                                {amenity.label}
                              </Typography>
                            </Grid2>
                        ))}
                  </Grid2>

                  <Button variant='contained' onClick={handleOpenModal} sx={{...largeScreenCart.checkoutButton, padding: '0.5rem 0.75rem', width: 'fit-content', justifyContent: 'center', fontSize: '0.7rem'}}>
                    {showMore ? "Show Less" : "Show More"}
                  </Button>
                </Stack>
              </Stack>
              <Box sx={{...topInformationStyles.sliderMainBox, width: '100%'}}>
                {(locationData && locationData.hotel.latitude && locationData.hotel.longitude) && <StaticMap usage={"fromProps"} latitude={locationData.hotel.latitude} longitude={locationData.hotel.longitude} />}
              </Box>
            </Stack>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="black"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                sx={mainStyles.tabsStyle}
            >
              <Tab
                  label={`${locationData?.hotel?.name} Restaurants`}
                  sx={searchSection.tabStyle}
              />
              <LinkWrapper
                  indicatorColor="primary"
                  style={{ fontWeight: "bold" }}
                  to={`/services/${locationData?.hotel?.slug}?room_id=${roomId}`}>
                <Tab
                    label={`Room Services`}
                    textColor="primary"
                    sx={searchSection.tabStyle}
                />
              </LinkWrapper>

              <Tab label="News" sx={searchSection.tabStyle} />
              <Tab label="GuestBook" sx={searchSection.tabStyle} />
              <Tab label="Upcoming Events" sx={searchSection.tabStyle} />
              <Tab label="Premium Services" sx={searchSection.tabStyle} />

            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Restaurants />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CardContent>
              <Grid2
                  container
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
              >
                <GuestBook />
              </Grid2>
            </CardContent>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CardContent>
              <Grid2
                  container
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
              >
                <News />
              </Grid2>
            </CardContent>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <UpcomingEvents />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <PremiumServices />
          </TabPanel>
        </Container>
        {openModal && <AmenitiesModal open={openModal} setOpen={setOpenModal} amenities={ameties} />}
      </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
  );
}

export default HotelPage;
