import {tabsClasses} from "@mui/material/Tabs";
import {MAIN_GREEN_COLOR} from "../colors";

export const mainStyles = {
    tabsStyle: {
        [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': {
                opacity: 0.3
            },
        },
        '& .MuiTabs-indicator': {
            backgroundColor: MAIN_GREEN_COLOR,
        },
        width: '90vw',
        maxWidth: 'fit-content'
    },
}