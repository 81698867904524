import {IconButton, Radio, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {cardStyle} from "../../css/checkoutStyles";
import {DeleteForever, Edit} from "@mui/icons-material";

const DeliveryAddressLocations = ({user, setView}) => {
    return(
        <Stack sx={cardStyle} direction='row'>
            <Stack direction='row' columnGap='1rem' sx={{alignItems: 'center'}}>
                <Radio color='success'/>
                <Stack sx={{rowGap: '0.0625rem'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600', color: 'black', textTransform: 'capitalize'}}>{user.deliveryOptions.addressType}</Typography>
                    <Typography variant='body2' sx={{fontSize: '0.8rem', textTransform: 'capitalize', color: grey[600]}}>{user.location.address}</Typography>
                    <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[600]}}>Delivery Instructions: {user.deliveryOptions.deliveryInstructionDetail}</Typography>
                </Stack>
            </Stack>
            <Stack direction='row' sx={{rowGap: '0.5rem'}}>
                <IconButton onClick={()=> setView('detailedView')}>
                    <Edit sx={{height: '20px', width: '20px', color: 'black'}} />
                </IconButton>
                <IconButton onClick={()=> setView('detailedView')}>
                    <DeleteForever sx={{height: '20px', width: '20px', color: 'black'}} />
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default DeliveryAddressLocations