import {getLocationData, getUserLocation, verifyLocation} from "../services/LocationService";
import {setUserDeliveryStatus, setUserLocation} from "../features/userSlice";
const GOOGLE_MAPS_API_KEY = "AIzaSyBlNITR7jNNamUPFBzPa8q7r4sz9yJ43IU";

export const handleAutocompleteChange = (event, newValue, props, options, setValue, setOptions, dispatch, setSnackbarParams, restaurant) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue) {
        const request = {
            placeId: newValue.place_id,
            fields: ['geometry']
        };
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
        placesService.getDetails(request, async (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const {lat, lng} = place.geometry.location;
                if (props.usage !== "hero" && props.usage !== "checkout") {
                    props.setLocation({
                        lat: lat(),
                        long: lng()
                    })
                }
                try {
                    const response = await getLocationData(lat(), lng(), GOOGLE_MAPS_API_KEY)
                    const userLocation = {
                        locationName: newValue.description,
                        address: response.data.results[0].formatted_address,
                        lat: lat(),
                        long: lng(),
                        country: '',
                        state: '',
                        city: '',
                        streetName: '',
                        streetNumber: '',
                        postal_code: '',
                        place_id: response.data.results[0].place_id
                    }

                    response.data.results[0].address_components.map((item) => {
                        if (item.types.includes('country')) {
                            userLocation.country = item.long_name
                        }
                        if (item.types.includes('administrative_area_level_1')) {
                            userLocation.state = item.long_name
                        }
                        if (item.types.includes('locality')) {
                            userLocation.city = item.long_name
                        }
                        if (item.types.includes('route')) {
                            userLocation.streetName = item.long_name
                        }
                        if (item.types.includes('street_number')) {
                            userLocation.streetNumber = item.long_name
                        }
                        if (item.types.includes('postal_code')) {
                            userLocation.postal_code = item.long_name
                        }
                    })

                    if (props.usage === "hero" || props.usage === "checkout"){
                        dispatch(setUserLocation({
                            location: {...userLocation}
                        }))
                    }
                    if (props.usage === "checkout") {
                        const res = await verifyLocation(restaurant.loc_id, lng(), lat())
                        dispatch(setUserDeliveryStatus({isDeliverable: res.delivery === true ? 'deliverable' : 'not_deliverable'}))
                    }

                    if (props.usage === "hero") {
                        setSnackbarParams({
                            open: true,
                            message: "Address Updated Successfully",
                            severity: 'success'
                        })
                    }
                    if (props.usage !== "hero" && props.usage !== "checkout") {
                        props.setLocation({...props.location, ...userLocation})
                    }
                    if (props.usage === "navbar") {
                        props.setView("moreInfo")
                    }
                } catch (err) {
                    console.error(err)
                }
            } else {
                console.error('Error fetching place details:', status);
            }
        });
    }
}


export const getLocation = async (dispatch, setSnackbarParams) => {
    try {
        const res = await getUserLocation()
        if (res && res.coords && res.coords.latitude && res.coords.longitude) {
            const response = await getLocationData(res.coords.latitude, res.coords.longitude, GOOGLE_MAPS_API_KEY)
            const userLocation = {
                locationName: response.data.results[0].formatted_address,
                address: response.data.results[0].formatted_address,
                lat: res.coords.latitude,
                long: res.coords.longitude,
                country: '',
                state: '',
                city: '',
                streetName: '',
                streetNumber: '',
                postal_code: '',
                place_id: response.data.results[0].place_id
            }

            response.data.results[0].address_components.map((item) => {
                if (item.types.includes('country')) {
                    userLocation.country = item.long_name
                }
                if (item.types.includes('administrative_area_level_1')) {
                    userLocation.state = item.long_name
                }
                if (item.types.includes('locality')) {
                    userLocation.city = item.long_name
                }
                if (item.types.includes('route')) {
                    userLocation.streetName = item.long_name
                }
                if (item.types.includes('street_number')) {
                    userLocation.streetNumber = item.long_name
                }
                if (item.types.includes('postal_code')) {
                    userLocation.postal_code = item.long_name
                }
            })
            dispatch(setUserLocation({
                location: {...userLocation}
            }))
            setSnackbarParams({
                open: true,
                message: 'Your location has been set successfully',
                severity: 'success'
            })
        }
    } catch (error) {
        console.error(error)
        setSnackbarParams({
            open: true,
            message: 'Something went wrong. Please try again',
            severity: 'error'
        })
    }
}
