import axios from "axios";
import {ApiRoute, CONSTANTS} from "../utils/constants";

export const getPremiumServices = async (loc_id) => {
    try {
        const response = await axios.get(`${ApiRoute.getPremiumServices(loc_id)}`, {
            headers: {...CONSTANTS.REQUEST_HEADERS},
        })

        if (!response || !response.data) {
            return ({error: true, message: 'Cannot Get Premium Services Data'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        console.log("Here", err)
        return ({error: true, message: 'Cannot Get Premium Services Data'})
    }
}