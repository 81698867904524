import './variables.css';

export const PRIMARY_COLOR = 'var(--primary-color)';
export const SECONDARY_COLOR = 'var(--secondary-color)';
export const TERTIARY_COLOR = 'var(--tertiary-color)';
export const ACCENT_PRIMARY_COLOR = 'var(--accent-primary-color)';
export const ACCENT_SECONDARY_COLOR = 'var(--accent-secondary-color)';
export const ACCENT_SECONDARY_LIGHT_COLOR =
  'var(--accent-secondary-light-color)';
export const THEME_COLOR_1 = `var(--theme-color1)`;
export const THEME_COLOR_2 = `var(--theme-color2)`;
export const THEME_COLOR_3 = 'var(--theme-color3)';
export const MAIN_GREEN_COLOR = '#208a20';
export const MAIN_ORANGE_COLOR = '#fca500';