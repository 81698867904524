import {Button, Stack, Typography} from "@mui/material";
import {MAIN_GREEN_COLOR} from "../colors";
import {useNavigate} from "react-router-dom";

const Error500 = ({message}) => {
    const navigate = useNavigate();
    return(
        <Stack sx={{height: '90vh', width: '100vw', alignItems: 'center', justifyContent: 'center', rowGap: '1rem'}}>
            <Typography variant='body2' sx={{fontSize: '2rem', fontWeight: '600', textTransform: 'capitalize'}}>
                {message !== "" ? message : "Internal Server Error"}
            </Typography>
            <Button variant={'contained'} sx={{backgroundColor: MAIN_GREEN_COLOR, color: 'white', padding: '0.25rem 1rem', textTransform: 'capitalize', fontSize: '1rem', borderRadius: '0.25rem', '&:hover': {backgroundColor: '#5fbc5f'}}} onClick={()=> navigate('/')}>Back to home</Button>
        </Stack>
    )
}

export default Error500;