import {Button, Collapse, Grid2, Stack, TextField, Typography} from "@mui/material";
import {filledInputFieldsStyle, inputFieldsStyle} from "../../css/orderPlacementStyles";
import {MAIN_GREEN_COLOR} from "../../colors";
import {green, red} from "@mui/material/colors";
import {useEffect, useState} from "react";
import {register} from "../../services/AuthService"
import {useLocation, useNavigate} from "react-router-dom";
import SnackBar from "../../components/snackbar";

const InformationForm = ({data, setData, setFlow, setPage}) => {
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: '',
        severity: 'success',
        location: 'center'
    })
    const location = useLocation();
    const goBack = location.state?.goBack;
    const navigate = useNavigate();
    const information = "By clicking \"Submit,\" you agree to Servall General Terms and Conditions and acknowledge you have read the Privacy Policy. "

    const inputs = [
        // {
        //     name: 'First Name',
        //     label: 'firstName'
        // },
        // {
        //     name: 'Last Name',
        //     label: 'lastName'
        // },
        {
            name: 'Email/Phone',
            label: 'email/phone'
        },
        {
            name: 'Password',
            label: 'password'
        },
        {
            name: 'Confirm Password',
            label: 'confirmPassword'
        }
    ]

    const handleInputChange = (e, label) => {
        setData({
            ...data,
            [label]: e.target.value
        })
    }

    const submitForm = async () => {
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!data["email/phone"] || data["email/phone"] === '') {
            setSnackbarParams({
                open: true,
                message: 'Please, enter your email or phone number',
                severity: 'error',
                location: 'center'
            })
        } else if (!data["password"] || data["password"] === '') {
            setSnackbarParams({
                open: true,
                message: 'Please, enter your password',
                severity: 'error',
                location: 'center'
            })
        } else if (!data["confirmPassword"] || data["confirmPassword"] === '') {
            setSnackbarParams({
                open: true,
                message: 'Please, confirm your password',
                severity: 'error',
                location: 'center'
            })
        } else if (data["password"] !== data["confirmPassword"]) {
            setSnackbarParams({
                open: true,
                message: 'The password and confirm password do not match',
                severity: 'error',
                location: 'center'
            })
        } else {
            let payload = {};

            if (phoneRegex.test(data["email/phone"])) {
                payload = { number: data["email/phone"] };
            } else if (emailRegex.test(data["email/phone"])) {
                payload = { email: data["email/phone"] };
            } else {
                setSnackbarParams({
                    open: true,
                    message: 'Please, enter a valid email or phone number',
                    severity: 'error',
                    location: 'center'
                })
                return;
            }

            payload = {
                ...payload,
                password: data.password
            }

            const response = await register(payload);
            if (response.error) {
                setSnackbarParams({
                    open: true,
                    message: response.message,
                    severity: 'error',
                    location: 'center'
                })
            } else {
                setSnackbarParams({
                    open: true,
                    message: "User Registered Successfully",
                    severity: 'success',
                    location: 'center'
                })
            }
        }
    }

    return(
        <>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Typography variant='body2' sx={{fontSize: '12px'}}>Fill your information</Typography>
                <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '600', cursor: 'pointer'}} onClick={()=> navigate('/account/signin')}>Sign In</Typography>
            </Stack>
            <Grid2 container sx={{width: '100%'}} spacing={2}>
                {
                    inputs.map((input, index)=> (
                        <Grid2 size={{xs: 12}} key={index} sx={{width: '100%'}}>
                            <TextField
                                label={input.name}
                                variant='filled'
                                sx={filledInputFieldsStyle}
                                type={'phone'}
                                value={data[input.label]}
                                fullWidth
                                onChange={(e)=> handleInputChange(e, input.label)}/>
                        </Grid2>
                    ))
                }
            </Grid2>
            <Typography variant='body2' sx={{fontSize: '12px', alignSelf: 'start'}}>{information}</Typography>
            <Button sx={{backgroundColor: MAIN_GREEN_COLOR, color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '16px', borderRadius: '8px', '&:hover': {backgroundColor: green[500]}}} fullWidth onClick={()=> submitForm()}>Submit</Button>
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default InformationForm