import {itemModal} from "../../css/restaurantPageStyles";
import {Box, IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {grey} from "@mui/material/colors";
import React from "react";
import CircleIcon from '@mui/icons-material/Circle';

const MoreProductInformationModal = ({openModal, handleModalClose, allergicItems}) => {

    return(
        <>
            <Modal open={openModal} onClose={()=> handleModalClose()} sx={itemModal.modalStyle}>
                <Stack sx={{padding: '1rem 0', backgroundColor: 'white', width: {xs: '90vw', sm: '400px', md: '500px'}, borderRadius: '5px', rowGap: '0.5rem'}}>
                    <Stack sx={{padding: '0 1rem', alignItems: 'center', justifyContent: 'space-between'}} direction='row'>
                        <Typography sx={{fontWeight: '600', fontSize: '18px'}}>More Product Information</Typography>
                        <IconButton size='small' onClick={()=> handleModalClose()}>
                            <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                        </IconButton>
                    </Stack>
                    <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />
                    <Typography sx={{color: grey[500], fontSize: '18px', fontWeight: '600', padding: '0 1rem'}}>Allergen</Typography>
                    <Stack sx={{rowGap: '0.5rem', padding: '0 1rem', maxHeight: '400px', overflow: 'auto'}}>
                        {
                            allergicItems.map((item, index)=> (
                                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '0.5rem'}}>
                                    <CircleIcon sx={{height: '10px', width: '10px', color: grey[500]}}/>
                                    <Typography variant='body2'>{item.Allergy_Item.item_name}</Typography>
                                </Stack>
                            ))
                        }
                    </Stack>
                </Stack>
            </Modal>
        </>
    )
}

export default MoreProductInformationModal