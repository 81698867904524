import {configureStore} from "@reduxjs/toolkit";
import cartReducer from "../features/cartSlice";
import restaurantDataReducer from "../features/restaurantSlice";
import hotelDataReducer from "../features/hotelSlice";
import sessionReducer from "../features/sessionSlice";
import hotelSessionReducer from "../features/hotelSessionSlice";
import userReducer from "../features/userSlice";
import orderReducer from "../features/orderSlice";
import variableReducer from "../features/variablesSlice";
import storage from "redux-persist/lib/storage";
import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";

// import thunk from 'redux-thunk';

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ['allCart', 'restaurantData', 'user'], // Add the keys you want to persist
};
const reducers = combineReducers({
  allCart: cartReducer,
  restaurantData: restaurantDataReducer,
  hotelData: hotelDataReducer,
  session: sessionReducer,
  hotelSession: hotelSessionReducer,
  user: userReducer,
  orderData: orderReducer,
  variablesData: variableReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  // middleware: [thunk],
});

// export const store = configureStore({
//   reducer: {
//     allCart: cartReducer,
//   },
// });
