import {useEffect, useState} from "react";
import {Alert, Box, Button, Grid2, IconButton, Snackbar, Stack, TextField, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {filledInputFieldsStyle} from "../css/orderPlacementStyles";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserInformation} from "../features/userSlice";
import {MAIN_GREEN_COLOR} from "../colors";

const GuestInformation = () => {
    const information = "By clicking \"Submit,\" you agree to Servall General Terms and Conditions and acknowledge you have read the Privacy Policy. "
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState({
        data: '',
        open: false
    });

    const navigate = useNavigate()

    const [guestData, setGuestData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const dispatch = useDispatch()

    const inputs = [
        {
            name: 'First Name',
            label: 'firstName'
        },
        {
            name: 'Last Name',
            label: 'lastName'
        },
        {
            name: 'Phone Number',
            label: 'phoneNumber'
        },
        {
            name: 'Email',
            label: 'email'
        },
        {
            name: 'Password',
            label: 'password'
        },
        {
            name: 'Confirm Password',
            label: 'confirmPassword'
        }
    ]

    useEffect(() => {
        const timeout = setTimeout(() => {
            setMessage({
                open: false,
            })
        }, 2000)

        return ()=> clearTimeout(timeout)
    }, [message.open]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (e, label) => {
        setGuestData({
            ...guestData,
            [label]: e.target.value
        })
    }

    const submitForm = () => {
        if(guestData.firstName === "" || guestData.firstName === null || guestData.firstName === undefined){
            setMessage({
                data: "Please, Enter First Name",
                open: true
            })
        } else if (guestData.lastName === "" || guestData.lastName === null || guestData.lastName ===undefined){
            setMessage({
                data: "Please, Enter Last Name",
                open: true
            })
        } else if (guestData.phoneNumber === "" || guestData.phoneNumber === null || guestData.phoneNumber === undefined){
            setMessage({
                data: "Please, Enter Phone Number",
                open: true
            })
        } else {
            const data = {
                firstName: guestData.firstName,
                lastName: guestData.lastName,
                phoneNumber: guestData.phoneNumber,
            }
            dispatch(setUserInformation({...data}))
            navigate('/account/checkout')
        }
    }

    return(
        <Box>
            <Container maxWidth='xl' sx={{padding: '3rem 0', minHeight: 'calc(90vh - 52px)', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                <Stack sx={{alignItems: 'center', justifyContent: 'start', rowGap: '1rem', width: 'fit-content', maxWidth: '335px', marginX: 'auto'}}>
                    <Typography variant='h5' sx={{fontSize: '16px', fontWeight: '600'}}>Guest Information</Typography>
                    <Grid2 container spacing={2}>
                        {
                            inputs.map((input, index)=> (
                                <Grid2 size={{xs: 12, md: (index === 0 || index === 1 ? 6 : 12)}} key={index}>
                                    <Stack>
                                        {!(index === 4 || index === 5) ? (<>
                                            {index === 3 && <Typography variant='h6' sx={{fontSize: '16px', fontWeight: '600'}}>Create Account (<Box component='span' sx={{fontWeight: '400'}}>Optional</Box>)</Typography>}
                                            <TextField
                                                variant={'filled'}
                                                sx={filledInputFieldsStyle}
                                                type={'phone'}
                                                value={guestData[input.label]}
                                                fullWidth
                                                label={input.name}
                                                onChange={(e)=> handleInputChange(e, input.label)}/>
                                        </>) : (
                                            <TextField
                                                variant={'filled'}
                                                sx={filledInputFieldsStyle}
                                                endAdornment={
                                                (index === 4 && (<InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>))
                                            } label={input.name} type={(showPassword && index === 4) ? 'text' : 'password'} value={guestData[input.label]} onChange={(e)=> handleInputChange(e, input.label)}/>
                                        )}
                                    </Stack>
                                </Grid2>
                            ))
                        }
                    </Grid2>
                    <Typography variant='body2' sx={{fontSize: '12px', alignSelf: 'start'}}>{information}</Typography>
                    <Button sx={{backgroundColor: MAIN_GREEN_COLOR, color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '16px', borderRadius: '10px'}} fullWidth onClick={()=> submitForm()}>Proceed</Button>
                    <Snackbar open={message.open} onClose={() => setMessage({open: false})}>
                        <Alert onClose={() => setMessage({open: false})} severity="error" sx={{width: '100%'}}>
                            {message.data}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Container>
        </Box>
    )
}

export default GuestInformation