import {Box, Button, IconButton, Modal, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import {cardStyle} from "../../css/checkoutStyles";
import {useSelector} from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {largeScreenCart} from "../../css/restaurantPageStyles";
import PlaceIcon from "@mui/icons-material/Place";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DeliveryDetailsModal from "./deliveryDetailsModal";

const LocationModal = ({setOpenLocationModal, openLocationModal}) => {
    const user = useSelector((state) => state.user.user);
    const [view, setView] = useState('main')

    return(
        <Modal open={openLocationModal} onClose={()=> setOpenLocationModal(false)} sx={{alignItems: {xs: (view === 'main' ? 'end' : 'center'), md: 'center'}, justifyContent: 'center', display: 'flex'}}>
            <Stack sx={{rowGap: '0.75rem', borderRadius: {xs: (view === 'main' ? '0' : '5px'), md: '5px'}, height: 'fit-content', maxHeight: '90vh', width: {xs: (view === 'main' ? '100vw' : '90vw'), sm: (view === 'main' ? '100vw' : '550px'), md: '650px', lg: '850px'}, overflow: 'auto', backgroundColor: 'white', padding: '1rem'}}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>{(view === 'main' || view === 'moreInfo') ? 'Delivery Details' : view === 'autoComplete' ? 'What\'s your exact location?' : '' }</Typography>
                    <IconButton size='small' sx={{backgroundColor: grey[500]}} onClick={()=> setOpenLocationModal(false)}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'white'}} />
                    </IconButton>
                </Stack>
                { view === 'main' ? <>
                <Stack sx={cardStyle} direction='row' onClick={()=> setView('autoComplete')}>
                    <Stack direction='row' columnGap='1rem' sx={{alignItems: 'center'}}>
                        <PlaceIcon sx={{height: '25px', width: '25px', color: 'black'}} />
                        <Stack sx={{rowGap: '0.0625rem'}}>
                            <Typography variant='body2' sx={{fontSize: '0.9rem', textTransform: 'capitalize', color: 'black'}}>{user.location.streetName}</Typography>
                            <Typography variant='body2' sx={{fontSize: '0.8rem', textTransform: 'capitalize', color: grey[600]}}>{user.location.address}</Typography>
                        </Stack>
                    </Stack>
                    <KeyboardArrowRightIcon sx={{height: '30px', width: '30px', color: 'black'}} />
                </Stack>
                <Stack sx={cardStyle} direction='row' onClick={()=> setView('dateTime')}>
                    <Stack direction='row' columnGap='1rem' sx={{alignItems: 'center'}}>
                        <HistoryToggleOffIcon sx={{height: '25px', width: '25px', color: 'black'}} />
                        <Stack sx={{rowGap: '0.0625rem'}}>
                            { user.orderDateTime.schedule === 'now' ?
                                <Typography variant='body2' sx={{fontSize: '0.9rem', textTransform: 'capitalize', color: 'black'}}>{user.orderDateTime.schedule} {user.orderDateTime.formattedDate} {user.orderDateTime.start_time} - {user.orderDateTime.end_time}</Typography> :
                                <Typography variant='body2' sx={{fontSize: '0.9rem', textTransform: 'capitalize', color: 'black'}}>{user.orderDateTime.formattedDate} {user.orderDateTime.start_time} - {user.orderDateTime.formattedDate} {user.orderDateTime.end_time}</Typography>
                            }
                        </Stack>
                    </Stack>
                    <KeyboardArrowRightIcon sx={{height: '30px', width: '30px', color: 'black'}} />
                </Stack>
                <Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
                <Button sx={{...largeScreenCart.checkoutButton, justifyContent: 'center',
                    textTransform: 'capitalize', textAlign: 'center', backgroundColor: 'black'
                }} onClick={()=> setOpenLocationModal(false)}>Done</Button>
                </> : (view === 'autoComplete' || view === 'moreInfo' || view === 'mapSelection') ?
                    <DeliveryDetailsModal setView={setView} view={view}/> : <></>
                }
            </Stack>
        </Modal>
    )
}

export default LocationModal