import React, {useState} from "react";
import {Grid, Grid2, Tab, Tabs} from "@mui/material";

const News = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Grid2 container justifyContent="center">
        <Grid2>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="news tabs"
          >
            <Tab
              label="CryptoCurrency"
              sx={selectedTab === 0 ? { fontWeight: "bold" } : {}}
            />
            <Tab
              label="World News"
              sx={selectedTab === 1 ? { fontWeight: "bold" } : {}}
            />
          </Tabs>
        </Grid2>
      </Grid2>
      <div style={{ marginTop: "10px" }}>
        {selectedTab === 0 && (
          <iframe
            width="1000"
            height="1000"
            style={{ maxWidth: "100%" }}
            src="https://rss.app/embed/v1/wall/tUuOvh0lE6nZPXXb"
            frameBorder="0"
          ></iframe>
        )}
        {selectedTab === 1 && (
          <iframe
            width="1000"
            height="1000"
            style={{ maxWidth: "100%" }}
            src="https://rss.app/embed/v1/wall/tAIvcp1s0vewLevi"
            frameBorder="0"
          ></iframe>
        )}
      </div>
    </>
  );
};

export default News;
