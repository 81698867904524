import {Button, Modal, Stack, Typography} from "@mui/material";
import {MuiOtpInput} from "mui-one-time-password-input";
import React from "react";
import {MAIN_GREEN_COLOR, MAIN_ORANGE_COLOR} from "../../colors";

const OtpModal = ({showOtpModal, setShowOtpModal, setOtpInput, handleVerifyOtp, otpInput}) => {

    const handleChange = (newValue) => {
        // setOtpInput(newValue);
        const uppercaseValue = newValue.toUpperCase();
        // Filter out non-capital letter characters and non-numeric characters
        const filteredValue = uppercaseValue.replace(/[^A-Z0-9]/g, "");
        setOtpInput(filteredValue);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleVerifyOtp();
        }
    };

    return(
        <Modal open={showOtpModal} onClose={() => {setShowOtpModal(false);setOtpInput("")}} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Stack sx={{backgroundColor: 'white', width: 'fit-content', padding: '1.5rem', rowGap: '1rem', borderRadius: '5px'}}>
                <Typography variant="h6" fontWeight="bold">
                    Enter OTP
                </Typography>
                <MuiOtpInput
                    gap={1}
                    sx={{width: {xs: 200, sm: 300},
                        '& .MuiOutlinedInput-root': { // Normal state
                            '& fieldset': {
                                borderColor: 'black', // Normal border color
                            },
                            '&:hover fieldset': {
                                borderColor: MAIN_GREEN_COLOR, // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: MAIN_ORANGE_COLOR, // Border color when focused
                            },
                        },
                    }}
                    length={4} autoFocus onChange={handleChange} value={otpInput}/>
                <Button fullWidth onKeyDown={handleKeyPress} onClick={() => handleVerifyOtp()} variant="contained"
                    sx={{borderRadius: "5px", width: "100%", backgroundColor: MAIN_GREEN_COLOR, padding: '0.75rem'}}>
                    Verify
                </Button>
            </Stack>
        </Modal>
    )
}

export default OtpModal