import {Box, IconButton, Modal, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {Email} from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import {URLS} from "../../utils/constants";

const GuestBookModal = ({open, setOpen, review}) => {
    return(
        <Modal open={open} onClose={()=> setOpen(false)} sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Stack sx={{rowGap: '0.75rem', borderRadius: '5px', height: 'fit-content', width: {xs: '90vw', sm: '550px', md: '550px'}, maxHeight: '90vh', overflow: 'auto', backgroundColor: 'white', padding: '1rem'}}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Review</Typography>
                    <IconButton size='small' sx={{backgroundColor: grey[500]}} onClick={()=> setOpen(false)}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'white'}} />
                    </IconButton>
                </Stack>
                <Box component='img' src={`${URLS.BaseUrlForGuestBook}hotel_data/reviews/${review?.image}`} alt={"review"} sx={{marginX: 'auto', width: '75%', height: '200px', borderRadius: '5px'}}/>
                <Stack rowGap={'1rem'} marginTop={'1rem'}>
                    <Typography variant='body2' textAlign={'center'} sx={{fontSize: '0.9rem'}}>{review.review_text}</Typography>
                    {(review.user_email && review.phone_number) &&
                        <Stack direction='row' sx={{justifyContent: 'space-between'}}>
                            {review.user_email && <Stack direction='row' sx={{alignItems: 'center'}} columnGap={'0.5rem'}>
                                <Email sx={{height: '1.25rem', width: '1.25rem', color: 'black'}} />
                                <Typography variant='body2' sx={{fontSize: '0.8rem'}}>{review.user_email}</Typography>
                            </Stack>}
                            {review.phone_number && <Stack direction='row' sx={{alignItems: 'center'}} columnGap={'0.5rem'}>
                                <PhoneIcon sx={{height: '1.25rem', width: '1.25rem', color: 'black'}} />
                                <Typography variant='body2' sx={{fontSize: '0.8rem'}}>{review.phone_number}</Typography>
                            </Stack>}
                        </Stack>
                        }
                </Stack>
            </Stack>
        </Modal>
    )
}

export default GuestBookModal