import {Box, Stack} from "@mui/material";
import Container from "@mui/material/Container";
import OrderInfo from "../components/orderSuccess/orderInfo";
import LocationInfo from "../components/orderSuccess/locationInfo";
import {grey} from "@mui/material/colors";

const OrderSuccess = () => {

    return(
        <Box>
            <Container maxWidth='xl' sx={{padding: {xs: '0 1rem', md: '0rem 2rem'}, borderBottom: `1px solid ${grey[300]}`}}>
                <Stack direction={{xs: 'column', md: 'row'}} columnGap='1rem' sx={{height: 'calc(90vh - 52px)', marginX: 'auto', maxWidth: {sm: '550px', md: '850px', lg: '1150px', xl: '1500px'}}}>
                    <OrderInfo />
                    <LocationInfo />
                </Stack>
            </Container>
        </Box>
    )
}

export default OrderSuccess