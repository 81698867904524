// sessionSlice.js
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  hotel_session_id: null,
};

const hotelSessionSlice = createSlice({
  name: "hotel_session",
  initialState,
  reducers: {
    setHotelSessionId(state, action) {
      state.hotel_session_id = action.payload;
    },
  },
});

export const { setHotelSessionId } = hotelSessionSlice.actions;
export default hotelSessionSlice.reducer;
