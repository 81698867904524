// @ts-nocheck
import {createSlice} from "@reduxjs/toolkit";

const currentTime = new Date();

// Get the current hours, minutes, and seconds
const currentHours = currentTime.getHours();
const currentMinutes = currentTime.getMinutes();
const currentSeconds = currentTime.getSeconds();

// Format the current time as 'hh:mm:ss'
const formattedCurrentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;

const initialState = {
  cart: [],
  totalQuantity: 0,
  totalamount: 0,
  subtotal: 0,
  totaltax: 0,
  loc_id: null,
  user_id: null,
  table_no: null,
  table_name: null,
  session_id: null,
  total_items: 3,
  order_time: new Date().toLocaleTimeString(),
  order_date: new Date().toLocaleDateString(),
  order_type_id: null,
  order_variant: "small",
  discount_id: 0,
  total_discount: 0,
  totalPrice: 0,
  table_id: null,
  room_id: null,
  session_date: new Date().toLocaleDateString(),
  start_time: formattedCurrentTime,
  comment: "",
  end_time: null,
  qrcode: "",
  qrcodedata: "",
  promocode_id: null,
  payment_status_id: null,
  order_tax: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemsToCart: (state, action) => {
      return { ...state, cart: action.payload };
    },
    getCartTotal: (state, action) => {
      let totalamount =
        parseFloat(state.totalamount) + parseFloat(action.payload.totalAmount);
      let subtotal =
        parseFloat(state.subtotal) + parseFloat(action.payload.subTotal);
      let totaltax =
        parseFloat(state.totaltax) + parseFloat(action.payload.totalTax);
      return {
        ...state,
        totalamount: totalamount,
        subtotal: subtotal,
        totaltax: totaltax,
      };
    },

    clearCart: (state) => {
      return {
        ...state,
        cart: [],
        totalQuantity: 0,
        totalamount: 0,
        subtotal: 0,
        totaltax: 0,
        loc_id: null,
        user_id: null,
        table_no: null,
        total_items: null,
        order_time: null,
        order_date: null,
        order_type_id: null,
        order_variant: "small",
        discount_id: 0,
        total_discount: 0,
        totalPrice: 0,

        session_date: null,
        start_time: null,
        comment: "",
        end_time: null,
        qrcode: null,
        qrcodedata: "",
        promocode_id: null,
        payment_status_id: null,
        order_tax: null,
      };
    },
    //     removeItem: (state, action) => {
    //   let subtotal = 0;
    //   let totalamount = 0;
    //   let totaltax = 0;

    //   ('Data received in INCREMENT/DECREMENT reducer:', action.payload);

    //   if (action.payload.items.length > 0) {
    //     action.payload.items.forEach((item) => {
    //       if (item.MenuOptions.length > 0) {
    //         item.MenuOptions.forEach((menuOptions) => {
    //           // Check if MenuOptions's base_price is 0
    //           if (menuOptions.base_price == 0) {
    //             // Include the prices of MenuOptions in calculations
    //             menuOptions.Option_Values.forEach((option) => {
    //               subtotal += parseFloat(option.price) * option.optionCount;
    //               totalamount += parseFloat(option.price) * option.optionCount;
    //               if (option.order_item_tax > 0) {
    //                 totaltax += parseFloat(option.order_item_tax) * option.optionCount;
    //                 totalamount += parseFloat(option.order_item_tax) * option.optionCount;
    //               }
    //             });
    //           } else {
    //             // Use the original logic for items with base_price 1
    //             menuOptions.Option_Values.forEach((option) => {
    //               if (menuOptions.base_price == 1) {
    //                 subtotal += parseFloat(option.price) * item.itemCount;
    //                 totalamount += parseFloat(option.price) * item.itemCount;
    //                 if (option.order_item_tax > 0) {
    //                   totaltax += parseFloat(option.order_item_tax) * item.itemCount;
    //                   totalamount += parseFloat(option.order_item_tax) * item.itemCount;
    //                 }
    //               } else {
    //                 subtotal += parseFloat(option.price) * option.optionCount;
    //                 totalamount += parseFloat(option.price) * option.optionCount;
    //                 if (option.order_item_tax > 0) {
    //                   totaltax += parseFloat(option.order_item_tax) * option.optionCount;
    //                   totalamount += parseFloat(option.order_item_tax) * option.optionCount;
    //                 }
    //               }
    //             });
    //           }
    //         });
    //       } else {
    //         // If there are no MenuOptions, calculate based on item information
    //         subtotal += parseFloat(item.menu_price) * item.itemCount;
    //         totalamount += parseFloat(item.menu_price) * item.itemCount;

    //         if (item.menu_tax_percentage > 0) {
    //           const menutax =
    //             (item.menu_price / 100) * item.menu_tax_percentage * item.itemCount;

    //           totaltax += parseFloat(menutax);
    //           totalamount += parseFloat(menutax);
    //         }
    //       }
    //     });

    //     ('Before 1: ', totalamount, subtotal, totaltax);

    //     if (action.payload.items.length === 0) {
    //       subtotal = totaltax = totalamount = 0;
    //     }
    //   }

    //   return {
    //     ...state,
    //     totalamount: totalamount,
    //     subtotal: subtotal,
    //     totaltax: totaltax,
    //     cart: action.payload.items,
    //   };
    // },

    removeItem: (state, action) => {
      let baseprice = 0;
      let subtotal = 0;
      let totalamount = 0;
      let totaltax = 0;
      let menutax = 0;

      if (action.payload.items.length > 0) {
        action.payload.items.forEach((item) => {
          let currentItemBasePrice = 0; // Variable to track base price for the current item

          if (item.MenuOptions.length > 0) {
            item.MenuOptions.forEach((menuOptions) => {
              menuOptions.Option_Values.forEach((option) => {

                if (menuOptions.base_price == 1) {
                  currentItemBasePrice = 1;
                  subtotal += parseFloat(option.price) * item.itemCount;
                  totalamount += parseFloat(option.price) * item.itemCount;
                  if (option.order_item_tax > 0) {
                    totaltax +=
                      parseFloat(option.order_item_tax) * option.optionCount;
                    totalamount +=
                      parseFloat(option.order_item_tax) * item.itemCount;
                  }
                } else {
                  subtotal += parseFloat(option.price) * option.optionCount;
                  totalamount += parseFloat(option.price) * option.optionCount;
                  if (option.order_item_tax > 0) {
                    totaltax +=
                      parseFloat(option.order_item_tax) * option.optionCount;
                    totalamount +=
                      parseFloat(option.order_item_tax) * option.optionCount;
                  }
                }
              });
            });
          }

          if (currentItemBasePrice == 0) {
            // Handle items without menu options
            subtotal += parseFloat(item.menu_price) * item.itemCount;
            totalamount += parseFloat(item.menu_price) * item.itemCount;
            menutax =
              (item.menu_price / 100) *
              item.menu_tax_percentage *
              item.itemCount;
            menutax = parseFloat(menutax).toFixed(2);
            totalamount += parseFloat(menutax);
          }
          totaltax += parseFloat(menutax);
        });

        if (action.payload.items.length === 0) {
          subtotal = totaltax = totalamount = 0;
        }
      }

      return {
        ...state,
        totalamount: totalamount,
        subtotal: subtotal,
        totaltax: totaltax,
        cart: action.payload.items,
      };
    },
    getLocationId: (state, action) => {
      state.loc_id = action.payload;
    },
    getTableId: (state, action) => {
      state.table_id = action.payload;
    },
    getRoomId: (state, action) => {
      state.room_id = action.payload;
    },
    getTableName: (state, action) => {
      state.table_name = action.payload;
    },
  },
});
export const {
  addItemsToCart,
  getCartTotal,
  removeItem,
  getItemsFromCart,
  clearCart,
  getLocationId,
  getTableId,
  getRoomId,
  getTableName,
} = cartSlice.actions;
export default cartSlice.reducer;
