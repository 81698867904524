import {Box, IconButton, Modal, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import LocationAutoComplete from "./locationAutoComplete";
import DynamicMaps from "./dynamicMaps";
import {locationModalStyles} from "../../css/checkoutStyles";

const LocationModal = ({openLocationModal, setOpenLocationModal}) => {

    return(
        <Modal open={openLocationModal} onClose={()=> setOpenLocationModal(false)} sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Stack sx={locationModalStyles.mainStack}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Pin Location</Typography>
                    <IconButton size='small' sx={{backgroundColor: grey[500]}} onClick={()=> setOpenLocationModal(false)}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'white'}} />
                    </IconButton>
                </Stack>
                <Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
                <LocationAutoComplete />
                <Stack sx={{width: '100%', borderRadius: '5px', border: `1px solid ${grey[200]}`}}>
                    <DynamicMaps openLocationModal={openLocationModal} setOpenLocationModal={setOpenLocationModal}/>
                </Stack>
            </Stack>
        </Modal>
    )
}


export default LocationModal