import {Box, Button, Collapse, IconButton, Stack, TextField, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useEffect, useState} from "react";
import {green, grey, red} from "@mui/material/colors";
import GoogleIcon from '@mui/icons-material/Google';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import {useLocation, useNavigate} from "react-router-dom";
import {filledInputFieldsStyle, inputFieldsStyle} from "../css/orderPlacementStyles";
import GoToTop from "../components/goToTop";
import {login} from "../services/AuthService";
import {useDispatch} from "react-redux";
import {setUserLoggedInInformation} from "../features/userSlice";
import {MAIN_GREEN_COLOR} from "../colors";

const SignInPage = ({setPage}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const goBack = location.state?.goBack;
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [fields, setFields] = useState({
        phone_email: '',
        password: '',
    })
    const [collapser, setCollapser] = useState({
        showCollapser: false,
        collapserMessage: '',
        error: true
    })

    useEffect(() => {
        if (collapser.showCollapser) {
            const timeOut = setTimeout(() => {
                if (!collapser.error) {
                    navigate("/dashboard/profile")
                } else {
                    setCollapser({
                        showCollapser: false,
                        collapserMessage: ''
                    })
                }
            }, 3000)

            return () => clearTimeout(timeOut)
        }
    }, [collapser.showCollapser]);

    const handleInputChange = (e, label) => {
        setFields({
            ...fields,
            [label]: e.target.value
        })
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSignIn = async () => {
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (fields.phone_email === '' || fields.password === '') {
            setCollapser({
                showCollapser: true,
                collapserMessage: 'Please, enter your email or phone number and password',
                error: true,
            })
        } else {
            let payload = {};

            if (phoneRegex.test(fields.phone_email)) {
                payload = { number: fields.phone_email };
            } else if (emailRegex.test(fields.phone_email)) {
                payload = { email: fields.phone_email };
            } else {
                setCollapser({
                    showCollapser: true,
                    collapserMessage: 'Please, enter a valid email or phone number',
                    error: true,
                })
                return;
            }

            payload = {
                ...payload,
                password: fields.password
            }

            const response = await login(payload);

            if (!response.error) {
                setCollapser({
                    showCollapser: true,
                    collapserMessage: 'Login Successful',
                    error: false
                })
                dispatch(setUserLoggedInInformation({
                    loggedInUserInformation: {
                        ...response.data.data
                    }
                }))
            } else {
                setCollapser({
                    showCollapser: true,
                    collapserMessage: response.message,
                    error: true
                })
            }
        }
    }

    return(
        <Box>
            <Container maxWidth='xl' sx={{padding: '3rem 0', minHeight: 'calc(90vh - 52px)', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                <Stack sx={{alignItems: 'center', justifyContent: 'start', rowGap: '1rem', width: '335px', maxWidth: '335px', marginX: 'auto'}}>
                    <Typography variant='h5' sx={{fontSize: '16px', fontWeight: '600'}}>Login</Typography>
                    <TextField variant={'filled'} sx={filledInputFieldsStyle} fullWidth label={'Email or Phone Number'} value={fields.phone_email} onChange={(e)=> handleInputChange(e, "phone_email")}/>
                    <TextField variant={'filled'} sx={filledInputFieldsStyle} fullWidth label={'Password'}
                               slotProps={{
                                   input: {
                                       endAdornment:
                                           <InputAdornment position="end">
                                               <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                                   edge="end"
                                               >
                                                   {showPassword ? <VisibilityOff/> : <Visibility/>}
                                               </IconButton>
                                           </InputAdornment>
                                   }}} type={showPassword ? 'text' : 'password'} value={fields.password} onChange={(e)=> handleInputChange(e, "password")}/>
                    {/*<Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', width: "100%"}}>*/}
                    {/*    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'center', columGap: '0.5rem'}}>*/}
                    {/*        <Checkbox sx={{height: '10px', width: '10px', marginRight: '0.5rem'}} size={'small'}/>*/}
                    {/*        <Typography variant='body2' sx={{fontSize: '12px'}}>Remember Me</Typography>*/}
                    {/*    </Stack>*/}
                    {/*    <Typography variant='body2' sx={{fontSize: '12px', textDecoration: 'underline', cursor: 'pointer'}} onClick={()=> setPage('forgotPassword')}>Forgot Password?</Typography>*/}
                    {/*</Stack>*/}
                    <Collapse in={collapser.showCollapser} unmountOnExit>
                        <Typography variant='body2' sx={{color: MAIN_GREEN_COLOR,fontSize: '0.9rem', fontWeight: '600'}}>{collapser.collapserMessage}</Typography>
                    </Collapse>
                    <Button sx={{backgroundColor: MAIN_GREEN_COLOR, color: 'white', padding: '0.8rem 1rem', textTransform: 'capitalize', fontSize: '14px', borderRadius: '10px'}} fullWidth onClick={()=> handleSignIn()}>Sign in</Button>
                    <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='body2' sx={{fontSize: '12px'}}>Don't have an account?</Typography>
                        <Typography variant='body2' sx={{fontSize: '14px', cursor: 'pointer', color: MAIN_GREEN_COLOR}} onClick={()=> navigate('/account/signUp')}>Sign Up</Typography>
                    </Stack>
                    <Button sx={{backgroundColor: grey[100], color: 'black', padding: '0.8rem 1rem', textTransform: 'capitalize', fontSize: '14px', borderRadius: '10px'}} fullWidth onClick={()=> navigate('/account/guest')}>Continue as Guest</Button>
                    <Button sx={{border: `1px solid ${grey[200]}`,marginTop: '1.5rem', backgroundColor: 'white', color: 'black', padding: '0.5rem 0.65rem', borderRadius: '20px'}}>
                        <GoogleIcon sx={{height: '20px', width: '20px', color: 'black'}} />
                        <Typography sx={{fontSize: '14px', marginX: '3rem'}}>Sign In with Google</Typography>
                    </Button>
                </Stack>
            </Container>
            <GoToTop />
        </Box>
    )
}

export default SignInPage