import {Box, Stack, Tab, Tabs, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {searchSection} from "../../css/restaurantPageStyles";
import {useSelector, useDispatch} from "react-redux";
import {useState} from "react";
import {RestaurantMenuData} from "../../features/restaurantSlice";
import {inputFieldsStyle} from "../../css/orderPlacementStyles";

const SearchSection = ({selectedTab ,setSelectedTabCategory}) => {
    const dispatch = useDispatch();
    const menu = useSelector((state)=> state.restaurantData.specificRestaurantMenuData)
    const [searchText, setSearchText] = useState("");
    const [unFilteredData, setUnFilteredData] = useState(null);

    const handleChange = (event, newValue) => {
        setSelectedTabCategory(newValue)

        const element = document.getElementById(`${newValue}`)
        if(element){
            const y = element.getBoundingClientRect().top + window.pageYOffset + (-50);

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
        if (searchText === "") {
            setUnFilteredData(menu)
        }

        if (value === "") {
            dispatch(RestaurantMenuData(unFilteredData));
            return;
        }

        let finalData;
        if (unFilteredData) {
            const filteredData = unFilteredData.menu_data.map((item) => {
                const data = item.Menu.filter((menuItem) => (
                    menuItem.menu_name.toLowerCase().includes(value.toLowerCase())
                ))
                if (data.length > 0) {
                    return {Category: item.Category, Menu: data};
                }
            })
            finalData = {
                menu_data: filteredData
            }
        } else {
            const filteredData = menu.menu_data.map((item) => {
                const data = item.Menu.filter((menuItem) => (
                    menuItem.menu_name.toLowerCase().includes(value.toLowerCase())
                ))
                if (data.length > 0) {
                    return {Category: item.Category, Menu: data};
                }
            })
            finalData = {
                menu_data: filteredData
            }
        }
        dispatch(RestaurantMenuData(finalData))
    }

    return(
        <Stack sx={searchSection.mainStack}>
            <TextField
                value={searchText}
                sx={{width: {md: '225px', lg: '225px', xl: '250px'}, ...inputFieldsStyle}}
                placeholder={'Search in menu'}
                variant='outlined'
                size='small'
                slotProps={{
                    input: {
                        startAdornment: <SearchIcon sx={{marginRight: '0.5rem'}}/>
                    }}}
                onChange={(e) => handleSearch(e.target.value)}
            />

            <Box>
                <Tabs
                    scrollButtons
                    value={selectedTab} onChange={handleChange}
                    sx={searchSection.tabsStyle} variant={'scrollable'}>
                    {
                        (menu && menu.menu_data) && menu.menu_data.map((item, index)=> (
                            item && (
                                <Tab label={item.Category.name} key={item.Category.category_id} value={item.Category.category_id} sx={searchSection.tabStyle}/>
                            )))
                    }
                </Tabs>
            </Box>

        </Stack>
    )
}

export default SearchSection