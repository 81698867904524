import SignIn from "./signIn";
import {useState} from "react";
import ForgotPassword from "./forgotPassword";
import SignUp from "./signUp";

const OrderPlacement = () => {
    const [page, setPage] = useState('signin')
    return(
        (
            page === 'signin' ?
                <SignIn setPage={setPage}/> : page === 'forgotPassword' ?
                <ForgotPassword setPage={setPage}/> :
                <SignUp setPage={setPage}/>
        )
    )
}

export default OrderPlacement