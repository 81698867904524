import {IconButton, Modal, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import Payment from "./payment";
import {grey} from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import {paymentModalStyles} from "../../css/checkoutStyles";

const PaymentModal = ({openPaymentModal, setOpenPaymentModal, keys, setSnackbarParams, setOrderData}) => {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        try {
            setStripePromise(loadStripe(keys.publicKey))
            setClientSecret(keys.clientSecret)
        } catch (err) {
            console.error(err)
            setSnackbarParams({
                loading: false,
                open: true,
                message: 'Failed to Load Payment Method',
                severity: 'error'
            })
            setOpenPaymentModal(false)
        }
    }, []);

    return(
        <Modal open={openPaymentModal} onClose={()=> {
            setOpenPaymentModal(false);
            setOrderData({
                loading: false,
                paymentStatus: 'ineligible'
            })
        }} sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Stack sx={paymentModalStyles.mainStack}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Order Payment</Typography>
                    <IconButton size='small' sx={{backgroundColor: grey[500]}} onClick={()=> {
                        setOpenPaymentModal(false);
                        setOrderData({
                        loading: false,
                        paymentStatus: 'ineligible'
                    })}}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'white'}} />
                    </IconButton>
                </Stack>
                {(stripePromise && clientSecret) &&
                    <Elements stripe={stripePromise} options={{clientSecret}}>
                        <Payment setOrderData={setOrderData} setSnackbarParams={setSnackbarParams} setOpenModal={setOpenPaymentModal} paymentIntent={keys.paymentIntentId}/>
                    </Elements>
                }
            </Stack>
        </Modal>
    )
}

export default PaymentModal