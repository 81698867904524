import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import Container from "@mui/material/Container";
import mblApp from "../../assets/images/homePage/mobile_app.png";
import ShopIcon from '@mui/icons-material/Shop';
import AppleIcon from '@mui/icons-material/Apple';
import {useTheme} from "@mui/material/styles";
import mblAppSmallScreen from "../../assets/mobileapp-half.png";
import {largeScreenDownloadApplicationStyles, smallScreenDownloadApplicationStyles} from "../../css/homePageStyles";

const DownloadApplications = () => {
    const theme = useTheme();

    const sm = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <Box sx={{backgroundColor: '#f8f8f8'}}>
            <Container maxWidth='xl'>
                {sm ?
                    (<Stack sx={smallScreenDownloadApplicationStyles.responsiveStackStyle}>
                        <Typography vairant='body2' sx={{fontWeight: '600', fontSize: {xs: '14px', sm: '16px'}}}>
                            Best restaurants
                        </Typography>
                        <Typography variant='h4' sx={{fontSize: {xs: '25px', sm: '32px'}}}>
                            In your pocket
                        </Typography>
                        <Typography variant='body2' sx={{fontSize: '12px'}}>
                            Order from your favorite restaurants & track on the go, with the all-new Servall app.
                        </Typography>
                        <Stack direction='row' columnGap='1rem' sx={{color: 'white', justifyContent: 'end', rowGap: '1rem'}}>
                            <Stack direction='row' sx={smallScreenDownloadApplicationStyles.downloadAppIconStyles}>
                                <AppleIcon sx={{height: '30px', width: '30px', color: 'white'}} />
                                <Stack sx={{padding: '0.25rem 0.5rem'}}>
                                    <Typography variant='body2' sx={{fontSize: '10px'}}>
                                        Download on the
                                    </Typography>
                                    <Typography variant='body2'>
                                        App Store
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction='row' sx={smallScreenDownloadApplicationStyles.downloadAppIconStyles}>
                                <ShopIcon sx={{height: '30px', width: '30px', color: 'white'}} />
                                <Stack sx={{padding: '0.25rem 0.55rem', borderRadius: '3px'}}>
                                    <Typography variant='body2' sx={{fontSize: '10px'}}>
                                        Get it on
                                    </Typography>
                                    <Typography variant='body2'>
                                        Google Play
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Box component='img' src={mblAppSmallScreen} alt={'Servall_Application'} sx={smallScreenDownloadApplicationStyles.mainImageStyle}/>
                    </Stack>)
                    : (<Stack direction={{xs: 'column', md: 'row'}} sx={largeScreenDownloadApplicationStyles.responsiveStack}>
                        <Stack width='30%' sx={{justifyContent: 'start', rowGap: '1rem', textAlign: 'start'}}>
                            <Typography vairant='body2' sx={{fontWeight: '600'}}>
                                Best restaurants
                            </Typography>
                            <Typography variant='h4' sx={{fontSize: '40px'}}>
                                In your pocket
                            </Typography>
                            <Typography variant='body2'>
                                Order from your favorite restaurants & track on the go, with the all-new Servall app.
                            </Typography>
                        </Stack>
                        <Stack width='30%' sx={{alignItems: 'center'}}>
                            <Box component='img' src={mblApp} alt={'mobile_app'} sx={{height: '400px', width: '200px'}}/>
                        </Stack>
                        <Stack width='30%' sx={{textAlign: 'end', rowGap: '1rem'}}>
                            <Typography vairant='body2' sx={{fontWeight: '600'}}>
                                Download
                            </Typography>
                            <Typography variant='h4' sx={{fontSize: '40px'}}>
                                Servall mobile app
                            </Typography>
                            <Stack direction={{xs: 'column', md: 'row'}} columnGap='1rem' sx={{color: 'white', justifyContent: 'end', rowGap: '1rem'}}>
                                <Stack direction='row' sx={smallScreenDownloadApplicationStyles.downloadAppIconStyles}>
                                    <AppleIcon sx={{height: '30px', width: '30px', color: 'white'}} />
                                    <Stack sx={{padding: '0.5rem 0.75rem'}}>
                                        <Typography variant='body2' sx={{fontSize: '10px'}}>
                                            Download on the
                                        </Typography>
                                        <Typography variant='body2'>
                                            App Store
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction='row' sx={smallScreenDownloadApplicationStyles.downloadAppIconStyles}>
                                    <ShopIcon sx={{height: '30px', width: '30px', color: 'white'}} />
                                    <Stack sx={{padding: '0.5rem 0.75rem', borderRadius: '3px'}}>
                                        <Typography variant='body2' sx={{fontSize: '10px'}}>
                                            Get it on
                                        </Typography>
                                        <Typography variant='body2'>
                                            Google Play
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>)}
            </Container>
        </Box>
    )
}

export default DownloadApplications