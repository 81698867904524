import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid, Grid2,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {useSelector} from "react-redux";
import {createReview, getReviews} from "../../services/ReviewService";
import CloseIcon from "@mui/icons-material/Close";
import {URLS} from "../../utils/constants";
import logo from "../../assets/Servall W logo.png";
import {PhotoCamera} from "@mui/icons-material";
import {gridView, largeScreenCart} from "../../css/restaurantPageStyles";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import GuestBookModal from "./guestBookModal";

const GuestBook = () => {
  const [openAddReviewModal, setOpenAddReviewModal] = useState(false);
  const [newReviewText, setNewReviewText] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [file, setFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [guestReviews, setGuestReviews] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedReview, setSelectedReview] = useState(null);
  const [openReviewDetailsModal, setOpenReviewDetailsModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const specificRestaurantData = useSelector(
      (state) => state.restaurantData.specificRestaurantData
  );

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      setLoading(true);
      const reviews = await getReviews(specificRestaurantData?.loc_id);
      setGuestReviews(reviews);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleOpenAddReviewModal = () => {
    setOpenAddReviewModal(true);
  };

  const handleCloseAddReviewModal = () => {
    setOpenAddReviewModal(false);
    setNewReviewText("");
    setEmail("");
    setPhone("");
    setRoomNumber("");
    setFromDate("");
    setToDate("");
    setFile(null);
    setSelectedImage(null);
    setTermsAgreed(false);
  };

  const handleAddReview = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{11}$/;
    const imageFormatRegex = /\.(png|jpg|jpeg)$/i;

    if (!newReviewText.trim()) {
      setSnackbarMessage("Review is mandatory");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (!termsAgreed) {
      setSnackbarMessage("Please agree to the terms and conditions");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    if (!phone) {
      setSnackbarMessage("Phone is required");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    let formData = new FormData();
    if (file) {
      if (!imageFormatRegex.test(file.name)) {
        setSnackbarMessage("Invalid image format (PNG, JPG, JPEG only)");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
      formData.append("file", file);
    }

    formData.append("review_text", newReviewText);

    if (email) {
      if (!emailRegex.test(email)) {
        setSnackbarMessage("Invalid email address");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
      formData.append("user_email", email);
    }

    if (phone) {
      if (!phoneRegex.test(phone)) {
        setSnackbarMessage("Invalid phone number (11 digits only)");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
      formData.append("phone_number", phone);
    }
    formData.append("room_number", roomNumber);
    formData.append("stay_date_start", fromDate);
    formData.append("stay_date_end", toDate);

    try {
      const response = await createReview(
          formData,
          specificRestaurantData?.loc_id
      );

      if (response) {
        setSnackbarMessage(
            "Review has been sent to hotel manager for approval "
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setOpenAddReviewModal(false);
        setNewReviewText("");
        setEmail("");
        setPhone("");
        setRoomNumber("");
        setFromDate("");
        setToDate("");
        setFile(null);
        setSelectedImage(null);
        setTermsAgreed(false);
        fetchReviews();
      } else {
        setSnackbarMessage("Error adding review", response);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCardClick = (review) => {
    setSelectedReview(review);
    setOpenReviewDetailsModal(true);
  };

  const handleCloseReviewDetailsModal = () => {
    setOpenReviewDetailsModal(false);
  };

  const handleTermsAgreeChange = (event) => {
    setTermsAgreed(event.target.checked);
  };

  return (<>
        <Stack sx={{rowGap: '2rem', width: '100%'}}>
          <Button variant="contained" onClick={handleOpenAddReviewModal} sx={{...largeScreenCart.checkoutButton, padding: '0.5rem 0.75rem', width: 'fit-content', justifyContent: 'center', fontSize: '0.7rem'}}>Add Review</Button>
          <Grid2 container spacing={3} sx={{width: '100%'}}>
            {guestReviews.map((review, index) => (
                <Grid2 size={{xs: 12, md: 4, lg: 3, xl: 2.4}} key={index} sx={{display: 'flex', alignItems: 'stretch', cursor: 'pointer'}}>
                  <Stack sx={{...gridView.itemMainStack, backgroundColor: 'white'}}>
                    <Box sx={gridView.itemImage} component={'img'} src={`${URLS.BaseUrlForGuestBook}hotel_data/reviews/${review?.image}`} alt={'guest_book'}/>
                    <Stack sx={gridView.infoStack}>
                      <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='body2' sx={{fontSize: '0.8rem', fontWeight: '400', color: 'black'}}>"{review.review_text}"</Typography>
                        <IconButton onClick={() => handleCardClick(review)}>
                          <ErrorOutlineOutlinedIcon sx={{height: '25px', width: '25px', color: 'black'}}/>
                        </IconButton>
                      </Stack>
                      {review.user_email && (
                          <Typography variant='body2' sx={{fontSize: '12px'}}>
                            {review.user_email}
                          </Typography>
                      )}
                      {review.phone_number && (
                          <Typography variant='body2' sx={{fontSize: '12px'}}>
                            {review.phone_number}
                          </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid2>
            ))}
          </Grid2>
        </Stack>


        <Dialog
            open={openAddReviewModal}
            onClose={handleCloseAddReviewModal}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiDialog-paper": {
                width: "calc(100% - 20px)",
                maxWidth: "600px",
              },
            }}
        >
          <DialogTitle>
            <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseAddReviewModal}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <img
                src={logo}
                width={150}
                style={{ display: "flex", margin: "auto" }}
                alt={"servalllogo"}
            />
            <Stack direction="column" spacing={2}>
              <TextField
                  fullWidth
                  label="Enter Review"
                  variant="outlined"
                  value={newReviewText}
                  onChange={(e) => setNewReviewText(e.target.value)}
                  margin="normal"
                  multiline // Allows multiline input
                  rows={4} // Adjust the number of visible rows as per your design
              />
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Room Number"
                    variant="outlined"
                    value={roomNumber}
                    onChange={(e) => setRoomNumber(e.target.value)}
                    margin="normal"
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                    fullWidth
                    label="From Date"
                    type="date"
                    variant="outlined"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
                <TextField
                    fullWidth
                    label="To Date"
                    type="date"
                    variant="outlined"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Stack>
            </Stack>
            <label
                htmlFor="fileInput"
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
            >
              <PhotoCamera
                  style={{
                    fontSize: "3rem",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
              />
              Upload Picture
            </label>

            <input
                id="fileInput"
                type="file"
                accept="image/*"
                capture="user"
                onChange={handleFileChange}
                style={{ display: "none" }}
            />

            {selectedImage && (
                <img
                    src={selectedImage}
                    alt="Selected"
                    style={{
                      maxWidth: "200px",
                      marginTop: "1rem",
                      display: "block",
                      margin: "0 auto",
                    }}
                />
            )}

            {/* <PhotoCamera />

          <input
            type="file"
            accept="image/*"
            capture="user"
            onChange={handleFileChange}
            style={{ marginTop: "1rem" }}
          />
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                maxWidth: "200px",
                marginTop: "1rem",
                display: "block",
                margin: "0 auto",
              }}
            />
          )} */}
            <DialogContentText>
              By submitting this review, you agree to the terms and conditions of
              the hotel. Your review may be subject to approval by the hotel
              management.
            </DialogContentText>
            <FormControlLabel
                control={
                  <Checkbox
                      checked={termsAgreed}
                      onChange={handleTermsAgreeChange}
                      color="primary"
                  />
                }
                label="I agree to the terms and conditions"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddReview}
                fullWidth
                style={{ marginTop: "1rem" }}
            >
              Add Review
            </Button>
          </DialogContent>
        </Dialog>

        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>


    {openReviewDetailsModal && <GuestBookModal open={openReviewDetailsModal} setOpen={setOpenReviewDetailsModal} review={selectedReview} />}
      </>
  );
};

export default GuestBook;
