import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Button, Checkbox, Divider, Grid2, IconButton, Stack, TextField, Tooltip, Typography} from "@mui/material";
import {filledInputFieldsStyle, inputFieldsStyle} from "../../css/orderPlacementStyles";
import {MAIN_GREEN_COLOR} from "../../colors";
import {performReservation} from "../../services/ReservationService";
import {useSelector} from "react-redux";
import SnackBar from "../../components/snackbar";
import {KeyboardArrowLeft} from "@mui/icons-material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {grey} from "@mui/material/colors";
import {getToken} from "../../services/AuthService";

const fields = [
    {
        label: 'Name',
        name: 'name',
        db_label: 'name',
        type: 'text',
        placeholder: 'Please, Enter your name',
        required: true,
        multiline: false,
        fullWidth: false,
    },

    {
        label: 'Email',
        name: 'email',
        type: 'text',
        db_label: 'email',
        placeholder: 'Please, Enter your email',
        required: true,
        multiline: false,
        fullWidth: false,
    },

    {
        label: 'Phone',
        name: 'phone',
        type: 'text',
        db_label: 'phone',
        placeholder: 'Please, Enter your phone number',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Number of Guests',
        name: 'numberOfGuests',
        type: 'text',
        db_label: 'number_of_guests',
        placeholder: 'Please, Enter number of guests',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Date',
        name: 'date',
        type: 'date',
        db_label: 'date',
        placeholder: 'Select, a Date',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Message',
        name: 'message',
        type: 'text',
        db_label: 'message',
        placeholder: 'Please, Enter your message',
        required: false,
        multiline: true,
        fullWidth: true
    }
]

const ReservationForm = ({setShowReservation}) => {
    const isUserLoggedIn = getToken()
    const [t, i18n] = useTranslation('global');
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        severity: '',
        message: '',
    })

    const restaurant = useSelector(state => state.restaurantData.specificRestaurantData);
    const originalTimeSlots = useSelector(state => state.restaurantData.specificOriginalTimeSlotsData);
    const user = useSelector(state => state.user.user);

    const [reservationData, setReservationData] = useState({
        name: isUserLoggedIn ? user.loggedInUserInformation.name : '',
        email: isUserLoggedIn ? user.loggedInUserInformation.email : '',
        phone: isUserLoggedIn ? user.loggedInUserInformation.number : '',
        number_of_guests: '',
        date: dayjs(),
        time: '',
        message: '',
    });

    const handleValueChange = (e, field) => {
        setReservationData({...reservationData, [field.db_label]: e.target.value})
    }

    const handleReservation = async () =>{
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!reservationData.name || reservationData.name === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Enter your name'
            })
        } else if (!reservationData.email || reservationData.email === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Enter your email'
            })
        } else if (!reservationData.phone || reservationData.phone === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Enter your phone number'
            })
        } else if (!reservationData.number_of_guests || reservationData.number_of_guests === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Enter number of guests'
            })
        } else if (!reservationData.date || reservationData.date === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Select a date'
            })
        } else if (!reservationData.location_slot_id || reservationData.location_slot_id === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Select a time slot'
            })
        } else if (!phoneRegex.test(reservationData.phone)) {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Enter correct phone number'
            })
        } else if (!emailRegex.test(reservationData.email)) {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Please, Enter correct email address'
            })
        } else {
            const data = {
                name: reservationData.name,
                email: reservationData.email,
                number: reservationData.phone,
                user_count: reservationData.number_of_guests,
                date: dayjs(reservationData.date).format("YYYY-MM-DD"),
                description: reservationData.message,
                location_slot_id: reservationData.location_slot_id
            }

            if (isUserLoggedIn) {
                data.customer_id = user.loggedInUserInformation.id
            }

            const response = await performReservation(restaurant.loc_id, data)
            if (!response.error) {
                setSnackbarParams({
                    open: true,
                    severity: 'success',
                    message: 'Reservation Successful. Thank you!',
                })
                setReservationData({
                    name: '',
                    email: '',
                    phone: '',
                    number_of_guests: '',
                    date: dayjs(),
                    time: '',
                    message: '',
                })
            } else {
                setSnackbarParams({
                    open: true,
                    severity: 'error',
                    message: response.message
                })
            }
        }
    }

    return(
        <>
            <Divider />
            <Stack direction={{xs: 'column', sm: 'row'}} sx={{columnGap: '1rem', paddingY: '1rem'}}>
                <Stack>
                    <Typography variant='h5' sx={{fontSize: '600'}}>Reservation</Typography>
                    <Grid2 container spacing={3}>
                        {fields.map((field, index) => (
                            <Grid2 key={index} size={{xs: 12, md: 6, xl: 4}}>
                                <Typography variant='h6' sx={{fontSize: '0.9rem'}}>{t(field.name)}</Typography>
                                {field.type === 'text' ?
                                    <TextField
                                        value={reservationData[field.db_label]}
                                        variant='filled'
                                        helperText={"required*"}
                                        fullWidth
                                        sx={filledInputFieldsStyle}
                                        label={field.label}
                                        multiline={field.multiline}
                                        minRows={field.multiline ? 3 : 1}
                                        onChange={(e)=> handleValueChange(e, field)}
                                    />
                                    : field.type === 'date' ?
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker format={'YYYY-MM-DD'} value={reservationData.date} onChange={(newValue) => setReservationData({...reservationData, date: newValue, location_slot_id: ''})} minDate={dayjs()} sx={{width: '100%', ...inputFieldsStyle}}/>
                                        </LocalizationProvider>
                                        :
                                        <></>
                                }
                            </Grid2>
                        ))}
                    </Grid2>
                </Stack>
                <Stack sx={{width: '450px'}}>
                    <Stack direction='row' sx={{width: '342px', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.75rem'}}>
                        <Typography variant='h5' sx={{fontSize: '600'}}>Timings</Typography>
                        <Tooltip title={"Go Back"}>
                            <IconButton sx={{backgroundColor: grey[200]}} onClick={()=> setShowReservation(false)}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    {
                        (reservationData.date.isSame(dayjs(), 'day')) ?
                            (
                                originalTimeSlots.map((slot, index)=> (
                                    reservationData.date.isSame(dayjs(`${slot.dbDate}`), 'day') && (
                                        (slot.location_slots.length <= 0) ?
                                            <Typography variant='body2' sx={{fontSize: "1rem"}}>
                                                No Slots Available
                                            </Typography> :
                                            slot.location_slots.map((item, index)=> (
                                                <Stack direction='row' sx={{cursor: 'pointer' ,alignItems: 'center', columnGap: '0.25rem', marginBottom: '0.5rem'}} onClick={()=> setReservationData({...reservationData, location_slot_id: item.location_slot_id})}>
                                                    <Checkbox color={'success'} size="small" checked={item.location_slot_id === reservationData.location_slot_id} sx={{borderRadius: '50%', color: (item.location_slot_id === reservationData.location_slot_id) ? MAIN_GREEN_COLOR : grey[500]}} />
                                                    <Typography variant='body2' sx={{fontSize: '1rem', textTransform: 'capitalize', color: (item.location_slot_id === reservationData.location_slot_id) ? MAIN_GREEN_COLOR : grey[500]}}>({item.start_time} - {item.end_time})</Typography>
                                                </Stack>
                                            ))
                                    )
                                ))
                            )
                            :
                            (originalTimeSlots.map((slot, index)=> (
                                ((dayjs(reservationData.date).format("dddd")).toLowerCase() === (slot.dayName).toLowerCase()) && (
                                    ((dayjs().format("dddd")) === (slot.dayName)) ?
                                        slot.original_location_slots.map((item, index)=> (
                                            <Stack direction='row' sx={{cursor: 'pointer', alignItems: 'center', columnGap: '0.25rem', marginBottom: '0.5rem'}} onClick={()=> setReservationData({...reservationData, location_slot_id: item.location_slot_id})}>
                                                <Checkbox color={'success'} size="small" checked={item.location_slot_id === reservationData.location_slot_id} sx={{borderRadius: '50%', color: (item.location_slot_id === reservationData.location_slot_id) ? MAIN_GREEN_COLOR : grey[500]}} />
                                                <Typography variant='body2' sx={{fontSize: '1rem', textTransform: 'capitalize', color: (item.location_slot_id === reservationData.location_slot_id) ? MAIN_GREEN_COLOR : grey[500]}}>({item.start_time} - {item.end_time})</Typography>
                                            </Stack>
                                        )) :
                                        slot.location_slots.map((item, index)=> (
                                            <Stack direction='row' sx={{cursor: 'pointer', alignItems: 'center', columnGap: '0.25rem', marginBottom: '0.5rem'}} onClick={()=> setReservationData({...reservationData, location_slot_id: item.location_slot_id})}>
                                                <Checkbox color={'success'} size="small" checked={item.location_slot_id === reservationData.location_slot_id} sx={{borderRadius: '50%', color: (item.location_slot_id === reservationData.location_slot_id) ? MAIN_GREEN_COLOR : grey[500]}} />
                                                <Typography variant='body2' sx={{fontSize: '1rem', textTransform: 'capitalize', color: (item.location_slot_id === reservationData.location_slot_id)  ? MAIN_GREEN_COLOR : grey[500]}}>({item.start_time} - {item.end_time})</Typography>
                                            </Stack>
                                        ))
                                ))))
                    }
                </Stack>
            </Stack>
            <Button sx={{marginTop: '1rem', color: 'white', fontSize: '0.8rem', textTransform: 'capitalize', backgroundColor: MAIN_GREEN_COLOR, '&:hover': {backgroundColor: MAIN_GREEN_COLOR}, width: 'fit-content'}} onClick={()=> handleReservation()}>{t("send")}</Button>
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default ReservationForm