import {Box, Collapse, Stack} from "@mui/material";
import Container from "@mui/material/Container";
import {cartStackStyles, checkoutStyles, informationStackStyles} from "../css/checkoutStyles";
import OrderTypeTime from "../sections/checkout/orderTypeTime";
import {grey} from "@mui/material/colors";
import {useState} from "react";
import DeliveryAddress from "../sections/checkout/deliveryAddress";
import CheckoutCart from "../components/checkout/checkoutCart";
import {useSelector} from "react-redux";
import GoToTop from "../components/goToTop";

const Checkout = () => {
    const user = useSelector((state) => state.user.user);
    const [type, setType] = useState({
        realType: user.order_type,
        sendingType: user.order_type
    });
    return(
        <Box sx={checkoutStyles.mainBoxStyle}>
            <Container maxWidth='xl' sx={checkoutStyles.mainContainerStyle}>
                <Stack sx={checkoutStyles.mainResponsiveStackStyle} direction={{xs: 'column', md: 'row'}}>
                    <Stack sx={informationStackStyles.mainStack}>
                        <OrderTypeTime type={type} setType={setType}/>
                        { type.realType === 'delivery' &&<Box sx={{height: '1px', backgroundColor: grey[300], width: '100%'}} /> }
                        <Collapse in={type.realType === 'delivery'} unmountOnExit timeout={750}>
                            <DeliveryAddress />
                        </Collapse>
                        {/*<Box sx={{height: '1px', backgroundColor: grey[300], width: '100%'}} />
                        <PaymentMethods type={type}/>*/}
                    </Stack>
                    <Stack sx={cartStackStyles.mainStack}>
                        <CheckoutCart />
                    </Stack>
                </Stack>
            </Container>
            <GoToTop />
        </Box>
    )
}

export default Checkout