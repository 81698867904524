import {ApiRoute, CONSTANTS} from '../utils/constants';
import axios from "axios";

export const createStripeOrder = async ({
                                          package_id,
                                          package_title,
                                          price,
                                          currency,
                                          discount,
                                          payment_method,
                                          products,
                                          license_key,
                                        }) => {
  let data = {
    package_id,
    package_title,
    price,
    currency,
    discount,
    payment_method,
    products,
  };

  try {
    const response = await fetch(`${ApiRoute.createStripeOrder(license_key)}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...CONSTANTS.REQUEST_HEADERS },
    });

    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error(`Could not create session: ${error.message}`);
  }
};

export const getOrderPaymentIntent = async (loc_id, total) => {
  try {
    const response = await axios.post(`${ApiRoute.getPaymentIntent(loc_id)}`, {loc_id, total},
        {
          headers: {...CONSTANTS.REQUEST_HEADERS},
        });

    if (!response.data.publicKey) {
      return ({error: true, message: 'Cannot get Order Public Key'})
    } else if (!response.data.clientSecret) {
      return ({error: true, message: 'Cannot get Order Client Secret'})
    } else {
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot get Order Payment Service'})
  }
}
