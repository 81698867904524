import placeholder_img from "../../assets/placeholder_restaurant.png";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {grey} from "@mui/material/colors";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import PlaceIcon from '@mui/icons-material/Place';
import StarIcon from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {useState} from "react";
import {URLS} from "../../utils/constants";
import {useSelector} from "react-redux";
import {topInformationStyles} from "../../css/restaurantPageStyles";
import SnackBar from "../../components/snackbar";

const TopInformation = () => {
    const [sp, setSnackbarParams] = useState({
        open: false,
        message: '',
        severity: 'error'
    })

    const restaurantData = useSelector((state)=> state.restaurantData.specificRestaurantData)

    const ratingData = [
        {
            data: "4.5 +22 ratings",
            icon: <StarIcon sx={{height: '18px', width: '18px', color: 'black'}}/>
        }, {
            data: "Open until 11:55 PM",
            icon: <HistoryToggleOffIcon sx={{height: '18px', width: '18px', color: 'black'}}/>
        }, {
            data: "0 miles away",
            icon: <PlaceIcon sx={{height: '18px', width: '18px', color: 'black'}}/>
        }
    ]

    // const handleOrderOption = (index) => {
    //     if (index === 2) {
    //         if (restaurantData.takeaway) {
    //             setSelectedOption(index)
    //             dispatch(setUserInformation({
    //                 order_type: 'takeaway'
    //             }))
    //         } else {
    //             setSnackbarParams({
    //                 open: true,
    //                 message: 'Sorry, this restaurant does not have a pickup option.',
    //                 severity: 'error'
    //             })
    //         }
    //     } else if (index === 0) {
    //         if (restaurantData.delivery) {
    //             setSelectedOption(index)
    //             dispatch(setUserInformation({
    //                 order_type: 'delivery'
    //             }))
    //         } else {
    //             setSnackbarParams({
    //                 open: true,
    //                 message: 'Sorry, this restaurant does not have a delivery option.',
    //                 severity: 'error'
    //             })
    //         }
    //     } else if (index === 1) {
    //         if (restaurantData.dine_in) {
    //             setSelectedOption(index)
    //             dispatch(setUserInformation({
    //                 order_type: 'dine_in'
    //             }))
    //         } else {
    //             setSnackbarParams({
    //                 open: true,
    //                 message: 'Sorry, this restaurant does not have a dine in option.',
    //                 severity: 'error'
    //             })
    //         }
    //     }
    // }

    return(<>
        <Box>
            <Container maxWidth='xl' sx={{paddingTop: '0.5rem'}}>
                <Box sx={topInformationStyles.responsiveStack}>
                    <Stack sx={{marginBottom: {xs: '0rem', md: '0.5rem'}, overflow: 'hidden'}}>
                        <Typography variant='body2' sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>Home  /  Services  /  {restaurantData.name} - {restaurantData.formattedAddress}, {restaurantData.city}, {restaurantData.state}, {restaurantData.country}</Typography>
                    </Stack>
                    <Stack sx={topInformationStyles.mainStack}>
                        <Stack sx={topInformationStyles.storeInfoStack}>
                            <Box sx={topInformationStyles.imageContainer}>
                                <Box component='img' src={restaurantData.img !== null ? (`${URLS.BaseUrlWithoutWebsite + "/" + restaurantData.img}`) : (`${placeholder_img}`)} alt={'hotel'} sx={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: '5px'}} />
                            </Box>
                            <Stack sx={{rowGap: '0.5rem', alignItems: 'start', justifyContent: 'space-between'}}>
                                <Stack direction='row'>
                                    <Typography variant='body2' sx={{color: grey[400], marginLeft: '0.25rem'}} >
                                        {restaurantData.category_name} .
                                    </Typography>
                                </Stack>
                                <Typography variant='h4' sx={{textTransform: 'capitalize'}}>
                                    {restaurantData.name}
                                </Typography>
                                <Stack direction={{xs: 'column', sm: 'row'}} sx={{alignItems: 'start'}}>
                                    {ratingData.map((item, index)=> (
                                        <Stack key={index} sx={topInformationStyles.ratingMainStack}>
                                            {item.icon}
                                            <Typography variant='body2' sx={{color: grey[800], fontSize: {xs: '12px', md: '14px'}}} >
                                                {item.data} .
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Stack>
                                {/*<Stack direction='row' sx={{borderRadius: '30px', backgroundColor: grey[200]}}>
                                    {buttons.map((item, index)=> (
                                        ((index === 0 && restaurantData.delivery) || (index === 1 && restaurantData.dine_in) || (index === 2 && restaurantData.takeaway)) &&
                                        <Typography onClick={()=> handleOrderOption(index)} key={index} variant='body2' sx={{backgroundColor: (index === selectedOption ? 'black' : 'transparent'), color: (index === selectedOption ? "white" : 'black'), ...topInformationStyles.optionButtonSelections}}>
                                            {item}
                                        </Typography>
                                    ))}
                                </Stack>*/}
                            </Stack>
                        </Stack>
                        <Box sx={topInformationStyles.sliderMainBox}>
                            <Stack sx={{position: 'relative'}}>
                                <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + restaurantData.img}`} alt={'slider'} sx={topInformationStyles.sliderImageStyle}/>
                                {/*<IconButton sx={{backgroundColor: 'white', position: 'absolute', top: '10px', right: '10px'}}>*/}
                                {/*    <FavoriteBorderIcon sx={{height: '20px', width: '20px', color: 'black'}} />*/}
                                {/*</IconButton>*/}
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Container>
        </Box>
        { sp.open && <SnackBar snackbarParams={sp} setSnackbarParam={setSnackbarParams}/> }
    </>)
}

export default TopInformation