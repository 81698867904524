import {green, grey} from "@mui/material/colors";
import joinUs from "../assets/images/homePage/banner.jpg";
import {MAIN_GREEN_COLOR} from "../colors";

export const heroStyles = {
    mainBox: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: {xs: '20vh', sm: '30vh', md: '55vh'},
        height: {xs: '20vh', sm: '30vh', md: '55vh'},
        display: 'flex',
        placeItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        borderBottom: `1px solid ${grey[200]}`,
        overflow: 'hidden'
    },

    gpsIconStyle: {
        width: '25px',
        height: '25px',
        cursor: 'pointer'
    },

    arrowForwardStyles: {
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        color: 'white'
    },

    iconButtonStyle: {
        backgroundColor: MAIN_GREEN_COLOR,
        borderRadius: '5px',
        padding: '0.5 0.75rem',
        '&:hover' :{backgroundColor: MAIN_GREEN_COLOR}
    },

    smallScreenMainStack: {
        columnGap: '1rem',
        width: {sm: '525px'},
        rowGap: {xs: '0.5rem', sm: '0'},
        maxWidth: {sm: '600px'},
        marginX: {sm: 'auto'}
    }
}

export const albumStyles = {
    responsiveStack: {
        marginX: 'auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: {md: '850px', lg: '1150px', xl: '1500px'}
    },

    responsiveSliderStack: {
        marginX: 'auto',
        maxWidth: {xs: '250px', sm: '550px', md: '850px', lg: '1150px', xl: '1500px'}
    },

    sliderImageStyle: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '175px',
        borderRadius: '0.25rem',
        cursor: 'pointer'
    },

    sliderTypeStyle: {
        fontSize: '11px',
        borderRadius: '5px',
        color: 'black',
        padding: '2px 5px',
        textAlign: 'center'
    },

    sliderCircleStyle: {
        fontSize: '12px',
        borderRadius: '50%',
        color: 'black',
        textAlign: 'center',
        padding: '0.25rem 0.65rem'
    }
}

export const proccessesStyles = {
    mainResponsiveStack: {
        marginX: 'auto', maxWidth: {sm: '550px',md: '850px', lg: '1150px', xl: '1500px'}
    },

    mainStack: {
        padding: '2rem 0',
        alignItems: 'start',
        justifyContent: 'end',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: {md: '350px', lg: '385px',xl: '400px'},
        backgroundRepeat: 'no-repeat',
        width: '100%',
    }
}

export const discountedSectionStyles = {
    responsiveStack: {
        marginX: 'auto', maxWidth: {sm: '550px',md: '850px', lg: '1150px', xl: '1500px'}
    },

    mainBox: {
        display: 'flex',
        alignItems: 'stretch',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        padding: '0 1rem'
    },

    innerStack: {
        justifyContent: 'space-between',
        alignItems: 'space-between',
        textAlign: 'start',
        height: {xs: 'auto', sm: '200px', md: '250px', lg: '300px'},
        marginY: '2rem'
    },

    buttonStyle: {
        backgroundColor: 'rgba(255,255,255,0.4)', width: '75px', fontSize: '11px', '&:hover': {backgroundColor: 'rgba(255,255,255,0.6)', color: green[500]}
    }
}

export const newRestaurantStyles = {
    responsiveStack: {
        marginX: 'auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: {xs: '250px', sm: '550px', md: '850px', lg: '1150px', xl: '1500px'}
    },

    imageBoxStyle: {
        backgroundSize: 'cover', backgroundPosition: 'center', height: '125px', width: '125px', borderRadius: '50%'
    },

    badgeStyle: {
        fontSize: '12px', borderRadius: '50%', color: 'black', textAlign: 'center', padding: '0.25rem 0.65rem', height: 'fit-content', width: 'fit-content'
    },

    typeBadgeStyle: {
        fontSize: '11px', borderRadius: '5px', color: 'black', padding: '0 5px', textAlign: 'center'
    }
}

export const joinUsStyles = {
    mainImageStackStyle: {
        rowGap: {xs: '0.5rem', md: '1rem'},
        backgroundImage : `url(${joinUs})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginX: 'auto',
        maxWidth: {sm: '550px',md: '850px', lg: '1150px', xl: '1500px'},
        borderRadius: '10px', padding: {xs: '1rem 2rem', md: '7.5rem 0'},
        alignItems: 'center',
        justifyContent: 'center'
    },

    buttonStyle: {
        fontSize: '13px',
        backgroundColor: 'rgba(255,255,255,1)',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.6)'
        },
        color: 'black',
        padding: '0.75rem 4rem'
    }
}

export const smallScreenDownloadApplicationStyles = {
    responsiveStackStyle: {
        rowGap : '1rem',
        marginX: 'auto',
        maxWidth: {sm: '550px'},
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2.5rem 0 0 0'
    },

    downloadAppIconStyles: {
        padding: '0rem 0.5rem', borderRadius: '3px', alignItems: 'center', justifyContent: 'center', backgroundColor: 'black', cursor: 'pointer'
    },

    mainImageStyle: {
        height: {xs: '150px', sm: '210px'}, width: {xs: '175px', sm: '250px'}, marginTop: '2rem'
    }
}

export const largeScreenDownloadApplicationStyles = {
    responsiveStack: {
        marginX: 'auto', maxWidth: {sm: '550px',md: '850px', lg: '1150px', xl: '1500px'}, alignItems: 'center', justifyContent: 'space-between', padding: '5rem 0'
    }
}