import {Box, Divider, IconButton, Stack, Typography} from "@mui/material";
import {largeScreenCart} from "../../css/restaurantPageStyles";
import {URLS} from "../../utils/constants";
import {brown, grey, yellow} from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearCart, removeItem} from "../../features/cartSlice";
import {useNavigate} from "react-router-dom";
import CartFromOtherLocation from "../../components/cartFromOtherLocation";
import {calculateTotalPrice, calculateVariantPrice} from "../../utils/cartDataHelpers";
import {useTranslation} from "react-i18next";
import SnackBar from "../../components/snackbar";
import {MAIN_GREEN_COLOR} from "../../colors";


const DeliveryTakeawayCart = () => {
    const [t, i18n] = useTranslation('global');
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: "",
        severity: "",
    })
    const [cartFromOtherLocation, setCartFromOtherLocation] = useState(false);
    const { cart, totalamount, loc_id } = useSelector((state) => state.allCart);

    const user = useSelector((state)=> state.user.user)
    const dispatch = useDispatch();
    const specificRestaurantData = useSelector(
        (state) => state.restaurantData.specificRestaurantData
    );

    const navigate = useNavigate()

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    const handleIncrement = (itemIndex) => {
        const updatedCart = cart.map((item, index) => {
            if (index === itemIndex) {
                const updatedItem = {
                    ...item,
                    itemCount: item.itemCount + 1,
                    order_menu_tax:
                        (item.order_menu_tax / item.itemCount) * (item.itemCount + 1),
                    MenuOptions: item.MenuOptions.map((menuOption) => ({
                        ...menuOption,
                        Option_Values: menuOption.Option_Values.map((optionValue) => {
                            let initialOptionCount = optionValue.initialOptionCount;
                            if (initialOptionCount === undefined) {
                                initialOptionCount = optionValue.optionCount;
                            }
                            const updatedOptionValue = {
                                ...optionValue,
                                optionCount: optionValue.optionCount + initialOptionCount,
                                initialOptionCount,
                            };
                            return updatedOptionValue;
                        }),
                    })),
                };
                return updatedItem;
            }
            return item;
        });
        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleDecrement = (itemIndex) => {
        const updatedCart = cart.map((item, index) => {
            if (index === itemIndex) {
                const updatedItem = {
                    ...item,
                    itemCount: item.itemCount > 1 ? item.itemCount - 1 : 1,
                    order_menu_tax:
                        (item.order_menu_tax / item.itemCount) * (item.itemCount - 1),
                    MenuOptions: item.MenuOptions.map((menuOption) => ({
                        ...menuOption,
                        Option_Values: menuOption.Option_Values.map((optionValue) => {
                            let initialOptionCount = optionValue.initialOptionCount;
                            if (initialOptionCount === undefined) {
                                initialOptionCount = optionValue.optionCount;
                            }
                            const newOptionCount =
                                optionValue.optionCount > initialOptionCount
                                    ? optionValue.optionCount - initialOptionCount
                                    : initialOptionCount;
                            return {
                                ...optionValue,
                                optionCount: newOptionCount,
                                initialOptionCount,
                            };
                        }),
                    })),
                };
                return updatedItem;
            }
            return item;
        });
        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleRemoveItem = (itemIndex) => {
        let remainItems = [];
        cart.map((item, index) => {
            if (index !== itemIndex) {
                remainItems.push(item);
            }
        });
        dispatch(removeItem({ itemIndex, items: remainItems }));
    };

    const handleCartNavigation = () => {
        if ((specificRestaurantData.loc_id !== loc_id) && cart.length > 0) {
            setCartFromOtherLocation(true);
        } else if (user.order_type === 'delivery' && totalamount < specificRestaurantData.min_delivery_amount) {
            setSnackbarParams({
                open: true,
                message: `${t("min_order_amount_for_delivery_is")} ${specificRestaurantData.currency}${specificRestaurantData.min_delivery_amount}`,
                severity: 'error',
            })
        } else {
            if (localStorage.getItem("servallAuthUserToken") && user && user.loggedInUserInformation && user.loggedInUserInformation.id) {
                navigate('/account/checkout')
            } else {
                navigate('/account/signin', {state: {goBack: '/account/checkout'}})
            }
        }
    }

    return(
        <Stack sx={{backgroundColor: 'white'}}>
            {user.order_type === 'delivery' && <Typography variant='body2' sx={{marginTop: '0.5rem', backgroundColor: MAIN_GREEN_COLOR, padding: '0.25rem 0.5rem', borderRadius: '5px', fontSize: '0.75rem', color: 'white'}}>Free delivery on orders greater than {specificRestaurantData.currency}{specificRestaurantData.amount_threshold}</Typography>}
            <Typography vairant='body2' sx={{fontSize: '16px', fontWeight: '600', padding: '1rem'}}>Cart</Typography>
            {
                (cart.length > 0) ? (
                    <Stack sx={{rowGap: '1rem'}}>
                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography variant='body2' sx={largeScreenCart.summaryText}>Summary</Typography>
                            <Typography variant='body2' sx={largeScreenCart.clearButton} onClick={()=> handleClearCart()}>Clear</Typography>
                        </Stack>
                        <Stack sx={{rowGap: '1rem', height: (user.order_type !== 'delivery' ? 'calc(100vh - 190px)' : 'calc(100vh - 250px)'), overflow: 'auto'}}>
                            {
                                cart.map((item, index)=> (
                                    <Stack key={index} direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Stack direction='row' sx={{columnGap: {md: '0.5rem', lg: '1rem'}}}>
                                            <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + item.menu_photo}`} sx={largeScreenCart.itemImage}/>
                                            <Stack sx={{rowGap: '0.25rem'}}>
                                                <Typography variant='body2' sx={largeScreenCart.itemNameText}>{item.menu_name}</Typography>
                                                <Typography variant='body2' sx={largeScreenCart.itemPriceText}>{specificRestaurantData.currency} {item.menu_price}</Typography>
                                                <Stack sx={{rowGap: '0.125rem'}}>
                                                    {
                                                        item.MenuOptions.length > 0 && (item.MenuOptions.map((menu_option, index) => (
                                                            <React.Fragment key={menu_option.option_id}>
                                                                {index !== 0 && <Divider />}
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    sx={{fontSize: '0.75rem', fontWeight: 'bold'}}
                                                                >
                                                                    {menu_option.option_name}
                                                                </Typography>
                                                                {menu_option.Option_Values.map(
                                                                    (menu_option_value) => (
                                                                        <Typography
                                                                            key={menu_option_value.option_id}
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            sx={{fontSize: '0.7rem', textTransform: 'capitalize'}}
                                                                        >
                                                                            {`${menu_option_value.optionCount}x ${
                                                                                menu_option_value.value
                                                                            } = ${
                                                                                menu_option_value.price === 0
                                                                                    ? "Free"
                                                                                    : specificRestaurantData?.currency +
                                                                                    calculateVariantPrice(
                                                                                        menu_option_value.price,
                                                                                        menu_option_value.order_item_tax_percentage,
                                                                                        menu_option_value.optionCount
                                                                                    ).toFixed(2)
                                                                            }`}
                                                                        </Typography>
                                                                    ))}
                                                            </React.Fragment>
                                                        )))
                                                    }
                                                </Stack>
                                                <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                                                    <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': {backgroundColor: grey[700]}}} onClick={() => handleIncrement(index)}>
                                                        <AddIcon sx={largeScreenCart.iconStyle} />
                                                    </IconButton>
                                                    <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600'}}>
                                                        {item.itemCount}
                                                    </Typography>
                                                    <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': {backgroundColor: grey[700]}}} onClick={() => handleDecrement(index)}>
                                                        <RemoveIcon sx={largeScreenCart.iconStyle}/>
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack sx={{rowGap: '0.5rem', alignItems: 'center'}}>
                                            <IconButton size='small' sx={{backgroundColor: grey[500], width: 'fit-content', '&:hover': {backgroundColor: grey[700]}}} onClick={() => handleRemoveItem(index)}>
                                                <CloseIcon sx={largeScreenCart.iconStyle}/>
                                            </IconButton>
                                            <Typography variant='body2' sx={{fontSize: {md: '12px', lg: '14px'}, fontWeight: '400'}}>{
                                                specificRestaurantData?.currency +
                                                calculateTotalPrice(
                                                    item.menu_price,
                                                    item.menu_tax_percentage,
                                                    item.itemCount
                                                )}</Typography>
                                        </Stack>
                                    </Stack>
                                ))
                            }
                        </Stack>
                        <Box sx={{height: '1px', backgroundColor: grey[300], width :'100%'}} />
                        <Stack rowGap={'0.5rem'}>
                            {user.order_type === 'delivery' && <Typography variant='body2' sx={{backgroundColor: yellow[100], padding: '0.25rem 0.5rem', borderRadius: '5px', fontSize: '0.75rem', color: brown[500]}}>{t("min_order_amount_for_delivery_is")} {specificRestaurantData.currency}{specificRestaurantData.min_delivery_amount}</Typography>}
                            <Stack direction='row' sx={largeScreenCart.checkoutButton} onClick={()=> handleCartNavigation()}>
                                <Typography variant='body2' sx={largeScreenCart.checkoutButtonText}>
                                    Checkout
                                </Typography>
                                <Typography variant='body2' sx={largeScreenCart.checkoutButtonText}>
                                    {specificRestaurantData.currency} {totalamount.toFixed(2)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                        <LocalMallIcon sx={{height: '50px', width: '50px', color: 'black', marginBottom: '0.5rem'}} />
                        <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>You don't have any orders here!</Typography>
                        <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '400'}}>Let's change that</Typography>
                    </Stack>
                )
            }
            { cartFromOtherLocation && <CartFromOtherLocation setCartFromOtherLocation={setCartFromOtherLocation} cartFromOtherLocation={cartFromOtherLocation} /> }
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </Stack>
    )
}

export default DeliveryTakeawayCart