import {Box, Button, Grid2, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import discount1 from "../../assets/images/homePage/p1.jpg";
import discount2 from "../../assets/images/homePage/rs.jpg";
import discount3 from "../../assets/images/homePage/fd.jpg";
import discount4 from "../../assets/images/homePage/pn.jpg";
import {discountedSectionStyles} from "../../css/homePageStyles";

const DiscountedRestaurants = () => {

    const data = [
        {
            img: discount1,
            title: 'Best promotions in your area',
            subTitle: 'Up to',
            discount: '50%'
        },

        {
            img: discount2,
            title: 'Rising stars restaurant',
            subTitle: 'Try Something',
            discount: 'New'
        },

        {
            img: discount3,
            title: 'Fastest Delivery for you!',
            subTitle: 'Best quick',
            discount: 'Lunch'
        },

        {
            img: discount4,
            title: 'Party Night?',
            subtitle: 'Maybe',
            discount: 'Snacks?'
        }
    ]

    return(
        <Box>
            <Container maxWidth='xl'>
                <Box sx={discountedSectionStyles.responsiveStack}>
                    <Grid2 container spacing={2}>
                        {
                            data.map((item, index)=> (
                                <Grid2 size={{xs: 12, sm: 6, md: 3}} sx={{display: 'flex', alignItems: 'stretch', justifyContent: 'stretch'}}>
                                    <Box sx={{...discountedSectionStyles.mainBox, backgroundImage: `url(${item.img})`}}>
                                        <Stack sx={discountedSectionStyles.innerStack}>
                                            <Typography variant='h5' sx={{color: 'white', fontWeight: '300', fontSize: '26px'}}>{item.title}</Typography>
                                            <Stack>
                                                <Typography variant='body2' sx={{color: 'white', fontWeight: '400', fontSize: '13px'}}>{item.subTitle}</Typography>
                                                <Typography variant='h6' sx={{color: 'white', fontWeight: '400', fontSize: '24px'}}>{item.discount}</Typography>
                                            </Stack>
                                            <Button variant='contained' sx={discountedSectionStyles.buttonStyle}>Check</Button>
                                        </Stack>
                                    </Box>
                                </Grid2>
                            ))
                        }
                    </Grid2>
                </Box>
            </Container>
        </Box>
    )
}

export default DiscountedRestaurants