import {ApiRoute, CONSTANTS} from "../utils/constants";
import axios from "axios";

export const createDirectOrder = async ({
                                            user_id,
                                            order_type,
                                            location_id,
                                            session_date,
                                            start_time,
                                            end_time,
                                            session_id,
                                            order_time,
                                            order_date,
                                            payment_intent_id,

                                            order_type_id,
                                            order_variant,
                                            discount_id,
                                            total_discount,
                                            totalPrice,
                                            comment,
                                            qrcode,
                                            table_no,
                                            total_items,
                                            qrcodedata,
                                            order_owner,
                                            promocode_id,
                                            payment_status_id,
                                            order_tax,
                                            session_owner,
                                            menu_items,
                                            delivery_fee,
                                            email
                                        }) => {
    let data = {
        user_id,
        order_type,
        location_id,
        session_date,
        start_time,
        end_time,
        session_id,
        order_time,
        order_owner,
        order_date,
        order_type_id,
        order_variant,
        discount_id,
        total_discount,
        totalPrice,
        comment,
        qrcode,
        payment_intent_id,
        table_no,
        total_items,
        qrcodedata,
        promocode_id,
        payment_status_id,
        order_tax,
        session_owner,
        delivery_fee,
        email,
        menu_items: menu_items,
    };

    try {
        const response = await axios.post(`${ApiRoute.createDirectOrder}`, {...data}, {
                headers: {...CONSTANTS.REQUEST_HEADERS},
            }
        );

        if (response) {
            return ({error: false, data: response.data});
        } else {
            return ({error: true, message: 'Cannot Create Direct Order'})
        }
    } catch (err) {
        return ({error: true, message: 'Cannot Create Direct Order'})
    }
};
