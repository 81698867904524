import {IconButton, Modal, Stack, Typography} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import {grey} from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

const AmenitiesModal = ({amenities, open ,setOpen}) => {

    return(
        <Modal open={open} onClose={()=> setOpen(false)} sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Stack sx={{rowGap: '0.75rem', borderRadius: '5px', height: 'fit-content', width: {xs: '90vw', sm: '550px', md: '550px'}, maxHeight: '90vh', overflow: 'auto', backgroundColor: 'white', padding: '1rem'}}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>All Amenities</Typography>
                    <IconButton size='small' sx={{backgroundColor: grey[500]}} onClick={()=> setOpen(false)}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'white'}} />
                    </IconButton>
                </Stack>
                <Grid container spacing={2}>
                    {amenities.map((amenity, index) => (
                        <Grid key={index} item xs={6} sm={6} sx={{display: 'flex', columnGap: '1rem'}}>
                            {amenity.icon}
                            <Typography variant='body2' sx={{fontSize: '0.9rem', fontWeight: '600'}}>{amenity.label}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Modal>
    )
}

export default AmenitiesModal