import {useState} from "react";
import PhoneSelection from "../sections/signUp/phoneSelection";
import {Box, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import CodeVerification from "../sections/signUp/codeVerification";
import InformationForm from "../sections/signUp/informationForm";
import GoToTop from "../components/goToTop";

const SignUp = ({setPage}) => {
    const [flow, setFlow] = useState('info')
    const [data, setData] = useState({
        // phone: ""
    })
    return(
        <Box>
            <Container maxWidth='xl' sx={{padding: '3rem 0', minHeight: 'calc(90vh - 52px)', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                <Stack sx={{alignItems: 'center', justifyContent: 'start', rowGap: '1rem', width: '335px', maxWidth: '335px', marginX: 'auto'}}>
                    <Typography variant='h5' sx={{fontSize: '22px', fontWeight: '600'}}>Let's get started</Typography>
                    {
                        flow === 'phone' ? <PhoneSelection setPage={setPage} setFlow={setFlow} setData={setData} data={data}/> :
                            flow === 'code' ? <CodeVerification setData={setData} setFlow={setFlow} data={data} /> :
                                <InformationForm setData={setData} setFlow={setFlow} data={data} setPage={setPage} />
                    }
                </Stack>
            </Container>
            <GoToTop />
        </Box>

    )
}

export default SignUp