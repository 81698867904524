import {grey} from "@mui/material/colors";
import {Avatar, Button, Grid2, Stack, Tab, Tabs, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Person from '@mui/icons-material/Person';
import {useDispatch, useSelector} from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockIcon from '@mui/icons-material/Lock';
import {useState} from "react";
import {MAIN_GREEN_COLOR} from "../../colors";
import {filledInputFieldsStyle} from "../../css/orderPlacementStyles";
import {updatePassword, updateUserProfile} from "../../services/UserProfileService";
import SnackBar from "../../components/snackbar";
import {setUserLoggedInInformation} from "../../features/userSlice";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {searchSection} from "../../css/restaurantPageStyles";
import DeleteAccountModal from "../../components/dashboard/deleteAccountModal";

const profileButtons = [
    {
        icon: <AccountCircleIcon sx={{height: '18px', width: '18px'}}/>,
        name: "Basic Details",
        fields: [
            {
                name: "name",
                label: "Name",
            },

            {
                name: "email",
                label: "Email",
            },

            {
                name: "number",
                label: "Phone Number",
            }
        ]
    },

    {
        icon: <LockIcon sx={{height: '18px', width: '18px'}}/>,
        name: "Change Password",
        fields: [
            {
                name: "oldPassword",
                label: "Old Password"
            },
            {
                name: "newPassword",
                label: "New Password",
            },

            {
                name: "confirmPassword",
                label: "Confirm Password",
            }
        ]
    },

    {
        icon: <ManageAccountsIcon sx={{height: '18px', width: '18px'}}/>,
        name: "Manage Account",
    }
]

const Profile = () => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const user = useSelector((state) => state.user.user.loggedInUserInformation);
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: '',
        severity: 'success'
    })
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openModal, setOpenModal] = useState(false);

    const [userData, setUserData] = useState({
        name: user.name,
        email: user.email,
        number: user.number,
        newPassword: '',
        confirmPassword: '',
        oldPassword: ''
    })

    const handleValueChange = (e, field) => {
        setUserData({...userData, [field.name]: e.target.value})
    }

    const handleUpdateUserInfo = async () => {
        if (selectedTab === 0) {
            const data = {
                name: userData.name,
                email: userData.email,
                number: userData.number
            }
            const response = await updateUserProfile(data);
            if (response.error) {
                setSnackbarParams({open: true, message: response.message, severity: 'error'})
            } else {
                setSnackbarParams({open: true, message: "Profile Updated Successfully", severity: 'success'})
                dispatch(setUserLoggedInInformation({
                    loggedInUserInformation: {
                        ...data
                    }
                }))
            }
        } else if (selectedTab === 1) {
            const data = {
                password: userData.oldPassword,
                new_password: userData.newPassword
            }
            const response = await updatePassword(data);
            if (response.error) {
                setSnackbarParams({open: true, message: response.message, severity: 'error'})
            } else {
                setSnackbarParams({open: true, message: "Password Updated Successfully", severity: 'success'})
            }
        }
    }

    const handleChange = (event, newValue) =>{
        setSelectedTab(newValue)
    }

    return(
        <>
            {!smallScreen ?
                <Stack sx={{height: 'inherit', width: '100%', backgroundColor: grey[100], padding: '0 1rem', rowGap: '1rem'}}>
                    <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center', marginTop: '1rem'}}>
                        <Person />
                        <Typography variant='h6' sx={{fontSize: '0.8rem', fontWeight: '400'}}>Profile</Typography>
                    </Stack>

                    <Stack direction='row' sx={{padding: '1.5rem', backgroundColor: 'white', borderRadius: '2px', columnGap: '1rem'}}>
                        <Stack sx={{rowGap: '1.25rem', width: '32.5%'}}>
                            <Avatar src={(user.image && user.image !== '') ? user.image : <Person /> } alt={user.name} sx={{height: '80px', width: '80px', borderRadius: '50%'}}/>
                            <Stack>
                                {profileButtons.map((item, index) => (
                                    <Stack direction='row' sx={{transition: '0.3s', padding: '0.65rem 1rem', borderRadius: '5px', alignItems: 'center', columnGap: '0.5rem', color: selectedTab === index ? 'white' : grey[800], backgroundColor: selectedTab === index ? MAIN_GREEN_COLOR : 'white', cursor: 'pointer'}} onClick={() => setSelectedTab(index)} key={index}>
                                        {item.icon}
                                        <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '600'}}>{item.name}</Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                        <Stack sx={{width: '67.5%', rowGap: '1rem'}}>
                            <Typography variant='h6' sx={{fontSize: '0.95rem', fontWeight: '600'}}>{selectedTab === 0 ? "Basic Details" : selectedTab === 1 ? "Change Password" : "Manage Account"}</Typography>
                            <Grid2 container rowSpacing={2} columnSpacing={4}>
                                {
                                    (selectedTab === 0 || selectedTab === 1) && (profileButtons[selectedTab].fields.map((field, index) => (
                                        <Grid2 size={{xs: 12, md: (selectedTab === 0 ? 6 : 12)}} key={index}>
                                            <TextField sx={filledInputFieldsStyle} variant='filled' fullWidth label={field.label} value={userData[field.name]} onChange={(e)=> handleValueChange(e, field)}/>
                                        </Grid2>
                                    )))
                                }
                            </Grid2>
                            {selectedTab === 2 &&
                                <Stack sx={{rowGap: '0.5rem', marginTop: '0.5rem', marginLeft: '0'}}>
                                    <Typography variant='h6' sx={{fontSize: '0.8rem'}}>Delete Account</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.75rem'}}>You can ask for your account and
                                        personal information to be deleted. This will impact all related data that we can verify
                                        as linked to your account.
                                    </Typography>
                                    <Typography onClick={()=> setOpenModal(true)} variant='body2' sx={{color: MAIN_GREEN_COLOR, fontSize: '0.75rem', cursor: 'pointer'}}>
                                        Delete Account
                                    </Typography>
                                </Stack>
                            }
                            {(selectedTab === 0 || selectedTab === 1) && <Button sx={{backgroundColor: MAIN_GREEN_COLOR, '&:hover': {backgroundColor: MAIN_GREEN_COLOR}, color: 'white', padding: '0.65rem 1rem', textTransform: 'capitalize', fontSize: '14px', borderRadius: '8px'}} fullWidth onClick={()=> handleUpdateUserInfo()}>Save</Button>}
                        </Stack>
                    </Stack>
                </Stack>
                :
                <Stack sx={{height: 'inherit', width: '100%', backgroundColor: grey[100], padding: '0 1rem', rowGap: '1rem'}}>
                    <Stack direction='column' sx={{rowGap: '1rem', padding: '1.5rem', backgroundColor: 'white', borderRadius: '2px', marginY: '1rem'}}>
                        <Stack direction='column' sx={{columnGap: '0.5rem', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                            <Avatar src={user.image ? user.image : <PersonOutlineOutlinedIcon/> } alt={user.name} sx={{height: '50px', width: '50px', borderRadius: '50%'}} />
                            <Typography variant='h6' sx={{fontSize: '1rem', color: 'black', fontWeight: '600', marginTop: '0.5rem'}}>{user.name}</Typography>
                            <Typography variant='body2' sx={{fontSize: '12px', color: grey[500]}}>{user.number}</Typography>
                            <Typography variant='body2' sx={{fontSize: '12px', color: grey[500]}}>{user.email}</Typography>
                        </Stack>
                        <Tabs
                            scrollButtons
                            value={selectedTab} onChange={handleChange}
                            sx={{...searchSection.tabsStyle, width: '70vw'}}
                            variant={'scrollable'}>
                            <Tab label="Basic Details" sx={searchSection.tabStyle} />
                            <Tab label="Change Password" sx={searchSection.tabStyle} />
                            <Tab label="Manage Account" sx={searchSection.tabStyle} />
                        </Tabs>
                        <Stack sx={{rowGap: '1rem'}}>
                            {
                                (selectedTab === 0 || selectedTab === 1) && (profileButtons[selectedTab].fields.map((field, index) => (
                                    <TextField key={index} sx={filledInputFieldsStyle} variant='filled' fullWidth label={field.label} value={userData[field.name]} onChange={(e)=> handleValueChange(e, field)}/>
                                )))
                            }
                            {selectedTab === 2 &&
                                <Stack sx={{rowGap: '0.5rem', marginTop: '0.5rem', marginLeft: '0'}}>
                                    <Typography variant='h6' sx={{fontSize: '0.8rem'}}>Delete Account</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.75rem'}}>You can ask for your account and
                                        personal information to be deleted. This will impact all related data that we can verify
                                        as linked to your account. </Typography>
                                    <Typography variant='body2' sx={{color: MAIN_GREEN_COLOR, fontSize: '0.75rem', cursor: 'pointer'}}>
                                        Delete Account
                                    </Typography>
                                </Stack>
                            }
                            {(selectedTab === 0 || selectedTab === 1) && <Button sx={{backgroundColor: MAIN_GREEN_COLOR, '&:hover': {backgroundColor: MAIN_GREEN_COLOR}, color: 'white', padding: '0.65rem 1rem', textTransform: 'capitalize', fontSize: '14px', borderRadius: '8px'}} fullWidth onClick={()=> handleUpdateUserInfo()}>Save</Button>}

                        </Stack>
                    </Stack>
                </Stack>
            }
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
            { openModal && <DeleteAccountModal openModal={openModal} setOpenModal={setOpenModal}/> }
        </>
    )
}

export default Profile;