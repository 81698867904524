import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  keyframes,
  Pagination as MuiPagination,
  Skeleton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import Loader from "./Loader";
import IconButton from "@mui/material/IconButton";
import {getLocations} from "../services/LocationService";
import {Link as RouterLink} from "react-router-dom";
import {URLS} from "../utils/constants";

// Define the fade-in animation

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Define the fade-out animation
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const CustomDialog = styled(Dialog)`
  /* Add transition effect for smooth animation */
  transition: opacity 0.3s;

  &.MuiDialog-root {
    /* Apply the fade-in animation when the dialog is opened */
    &.fade-enter {
      animation: ${fadeIn} 0.3s;
    }

    /* Apply the fade-out animation when the dialog is closed */
    &.fade-exit {
      animation: ${fadeOut} 0.3s;
    }
  }
`;
// Add some custom styling
const RestaurantCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
  },
});

const PaginationContainer = styled(Box)({
  display: "flex",
  justifyContent: "right",

  marginTop: "2rem",
});
const Pagination = styled(MuiPagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    borderRadius: "50%",
    padding: "0",
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
}));

const RestaurantImage = styled(CardMedia)({
  height: "200px",
  objectFit: "cover",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
});

const RestaurantName = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: "bold",
  marginBottom: "8px",
});

const dummyRestaurants = [
  // {
  //   id: 1,
  //   name: "Delicious Delights",
  //   description: "Experience a variety of delicious dishes in our restaurant.",
  //   image: "https://via.placeholder.com/300/FF5733/FFFFFF?text=Restaurant+1",
  //   type: "healthy",
  // },
  // {
  //   id: 2,
  //   name: "Tasty Treats",
  //   description: "Savor the taste of our mouthwatering treats and meals.",
  //   image: "https://via.placeholder.com/300/FFC300/FFFFFF?text=Restaurant+2",
  //   type: "asian",
  // },
  // {
  //   id: 3,
  //   name: "Cuisine Corner",
  //   description: "Explore a world of flavors in our culinary creations.",
  //   image: "https://via.placeholder.com/300/900C3F/FFFFFF?text=Restaurant+3",
  //   type: "spicy",
  // },
  // {
  //   id: 4,
  //   name: "Foodies Paradise",
  //   description: "Indulge in a paradise of delightful food choices.",
  //   image: "https://via.placeholder.com/300/2ECC71/FFFFFF?text=Restaurant+4",
  //   type: "mexican",
  // },
  // {
  //   id: 5,
  //   name: "Savory Spices",
  //   description: "Discover the perfect blend of spices in our dishes.",
  //   image: "https://via.placeholder.com/300/3498DB/FFFFFF?text=Restaurant+5",
  //   type: "asian",
  // },
  // {
  //   id: 6,
  //   name: "Gourmet Garden",
  //   description: "Enjoy a gourmet experience amidst nature-inspired settings.",
  //   image: "https://via.placeholder.com/300/FF5733/FFFFFF?text=Restaurant+6",
  //   type: "healthy",
  // },
  // {
  //   id: 7,
  //   name: "Ocean View Dining",
  //   description: "Enjoy delicious seafood with a view of the ocean.",
  //   image: "https://via.placeholder.com/300/00CED1/FFFFFF?text=Restaurant+7",
  //   type: "seafood",
  // },
  // {
  //   id: 8,
  //   name: "Spicy Bites",
  //   description: "Spice up your taste buds with our fiery dishes.",
  //   image: "https://via.placeholder.com/300/FF4500/FFFFFF?text=Restaurant+8",
  //   type: "spicy",
  // },
  // {
  //   id: 9,
  //   name: "Italian Delights",
  //   description: "Experience the flavors of Italy in every bite.",
  //   image: "https://via.placeholder.com/300/FF1493/FFFFFF?text=Restaurant+9",
  //   type: "italian",
  // },
  // {
  //   id: 10,
  //   name: "Asian Fusion",
  //   description: "Discover the fusion of Asian cuisines in our menu.",
  //   image: "https://via.placeholder.com/300/FFA500/FFFFFF?text=Restaurant+10",
  //   type: "asian",
  // },
  // {
  //   id: 11,
  //   name: "Healthy Greens",
  //   description: "Indulge in healthy and delicious green dishes.",
  //   image: "https://via.placeholder.com/300/32CD32/FFFFFF?text=Restaurant+11",
  //   type: "healthy",
  // },
  // // Add one more restaurant...
  // {
  //   id: 12,
  //   name: "Mexican Fiesta",
  //   description: "Celebrate the vibrant flavors of Mexico.",
  //   image: "https://via.placeholder.com/300/FFD700/FFFFFF?text=Restaurant+12",
  //   type: "mexican",
  // },
  // Add even more restaurants as needed...
];
const ITEMS_PER_PAGE = 6; // Set the number of restaurants per page

// Helper function to create a skeleton restaurant card

const renderSkeletonCard = () => {
  return (
    <RestaurantCard>
      <Skeleton variant="rectangular" height={200} />
      <CardContent>
        <Typography gutterBottom variant="h5">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "auto",
          mb: "16px",
        }}
      >
        <Skeleton variant="rectangular" width={80} height={36} />
      </Box>
    </RestaurantCard>
  );
};

const ListRestaurants = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState(dummyRestaurants);

  // Define a state for loading data from the API
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const locationList = await getLocations();

        const mergedRestaurants = locationList.data;
        setLocations(mergedRestaurants);
        setIsLoadingData(false); // Data loading is complete
      } catch (error) {
        console.error("Error fetching locations:", error);
        setIsLoadingData(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    // Simulate loading for 2 seconds (you can replace this with your actual loading logic)
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(delay);
  }, []);

  const filteredRestaurants = locations.filter((restaurant) => {
    const nameIncludesQuery = restaurant.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    if (selectedTypes.length === 0) {
      // If no type is selected, show all restaurants
      return nameIncludesQuery;
    }
    // Show restaurants that match both the search query and the selected types
    return nameIncludesQuery && selectedTypes.includes(restaurant.type);
  });
  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredRestaurants.length / ITEMS_PER_PAGE);

  // State to keep track of the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // Get the current page's restaurants
  const currentRestaurants = filteredRestaurants.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // Reset the current page to the first page when the search query changes
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  // Function to handle type filter change
  const handleTypeChange = (event) => {
    const selectedType = event.target.name;
    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.includes(selectedType)
        ? prevSelectedTypes.filter((type) => type !== selectedType)
        : [...prevSelectedTypes, selectedType]
    );
    // Reset the current page to the first page when the type filters change
    setCurrentPage(1);
  };
  // Function to open the modal when the "View" button is clicked
  const handleOpenModal = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedRestaurant(null);
    setModalOpen(false);
  };
  const RestaurantFilterCard = styled(Card)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: "16px", // Increase padding for a bigger card
    marginBottom: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ p: 8 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{xs: 12, md: 3}}>
              <TextField
                variant="outlined"
                label="Search Restaurants"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "16px" }}
                // Add the endAdornment to display the ClearIcon
                InputProps={{
                  endAdornment: searchQuery && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClearSearch}
                        aria-label="clear search"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <Grid2 size={{xs: 12, md: 9}}>
              <Grid2 container spacing={2}>
                {currentRestaurants.length > 0
                  ? currentRestaurants.map((restaurant) => (
                      <Grid2 size={{xs: 12, sm: 6, md: 4}} key={restaurant.id}>
                        <RouterLink
                          to={`/restaurants/${restaurant.slug}`}
                          style={{ textDecoration: "none" }}
                        >
                          <RestaurantCard>
                            <RestaurantImage
                              component="img"
                              image={
                                URLS.BaseUrlWithoutWebsite + restaurant.img
                              }
                              alt={restaurant.name}
                            />
                            <CardContent>
                              <RestaurantName
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {restaurant.name}
                              </RestaurantName>
                            </CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: "auto",
                                mb: "16px",
                              }}
                            >
                            </Box>
                          </RestaurantCard>
                        </RouterLink>
                      </Grid2>
                    ))
                  : Array.from({ length: ITEMS_PER_PAGE }).map(
                      (test, index) => (
                        <Grid2 size={{xs: 12, sm: 6, md: 4}} key={index}>
                          {renderSkeletonCard()}
                        </Grid2>
                      )
                    )}
              </Grid2>
            </Grid2>
          </Grid2>

          <CustomDialog open={isModalOpen} onClose={handleCloseModal}>
            {selectedRestaurant && (
              <>
                <DialogTitle>{selectedRestaurant.name}</DialogTitle>
                <DialogContent>
                  <RestaurantImage
                    component="img"
                    // image={selectedRestaurant.image}
                    image={
                      "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80s"
                    }
                    alt={selectedRestaurant.name}
                  />
                  <Typography variant="body1">
                    {selectedRestaurant.description}
                  </Typography>

                  {/* Add more details as needed */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseModal} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </>
            )}
          </CustomDialog>
          {/* Display the Pagination component */}
          <PaginationContainer>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </PaginationContainer>
        </Box>
      )}
    </>
  );
};

export default ListRestaurants;
