import {Box, Button, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {filledInputFieldsStyle, inputFieldsStyle} from "../../css/orderPlacementStyles";
import {grey} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import {verifyLocation} from "../../services/LocationService";
import {setUserLocation} from "../../features/userSlice";
import {deliveryOptionsStyles} from "../../css/checkoutStyles";

const DeliveryOptions = ({setView}) => {
    const [option, setOption] = useState('door');
    const [address, setAddress] = useState('home')

    const location = useSelector(state => state.user)
    const [deliveryInstructions, setDeliveryInstructions] = useState('')

    useEffect(() => {

    }, [location]);

    const dispatch = useDispatch()
    const restaurant_id = useSelector(state => state.restaurantData.specificRestaurantData.loc_id)

    const fields = [
        {
            label: 'Leave it at my door',
            value: 'door'
        },
        {
            label: 'Hand it to me',
            value: 'handover'
        },
        {
            label: 'Meet outside',
            value: 'meet'
        }
    ]

    const addresses = [
        {
            label: "Home",
            value: 'home'
        },

        {
            label: "Work",
            value: 'work'
        },

        {
            label: "School",
            value: 'school'
        },

        {
            label: "Other",
            value: 'other'
        },
    ]

    const handleSaveAddress = async () => {
        const response = await verifyLocation(restaurant_id, location.long, location.lat)
        dispatch(setUserLocation(
            {
                location: location.user.location,
                deliveryOptions: {
                    addressType: address,
                    deliveryInstructions: option,
                    deliveryInstructionDetail: deliveryInstructions
                },
                isDeliverable: response.code === 200 ? 'deliverable' : 'not_deliverable'
            }))
        setView('normalView')
    }

    return(
        <Stack rowGap='0.5rem'>
            <Stack>
                <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem'}}>Delivery Options</Typography>
                <Select
                    sx={inputFieldsStyle}
                    variant='outlined'
                    value={option}
                    onChange={(e)=> setOption(e.target.value)}
                >
                    {fields.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </Stack>
            <Stack>
                <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem'}}>Add delivery instructions</Typography>
                <TextField variant='filled' sx={filledInputFieldsStyle} multiline minRows={2} label='eg. ring the bell after dropoff, leave next to the porch, call upon arrival, etc' onChange={(e)=> setDeliveryInstructions(e.target.value)}/>
            </Stack>
            <Stack>
                <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}>Address label</Typography>
                <Stack direction='row' sx={{borderRadius: '5px', overflow: 'hidden', width: 'fit-content', marginTop: '0.5rem'}}>
                    {
                        addresses.map((item, index)=> (
                            <Box key={index} onClick={()=> setAddress(item.value)} sx={{ ...deliveryOptionsStyles.itemBox, backgroundColor: (address === item.value ? 'black' : grey[200])}}>
                                <Typography variant='body2' sx={{fontSize: '0.75rem', color: (address === item.value ? 'white' : 'black'), transition: '0.5s'}}>{item.label}</Typography>
                            </Box>
                        ))
                    }
                </Stack>
            </Stack>
            <Button sx={deliveryOptionsStyles.button} onClick={()=> handleSaveAddress()}>Save Address</Button>
        </Stack>
    )
}

export default DeliveryOptions