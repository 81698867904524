import {useTheme} from "@mui/material/styles";
import {IconButton, Stack, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {blue, green, pink, red} from "@mui/material/colors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ScheduleIcon from "@mui/icons-material/Schedule";
import * as React from "react";
import {useEffect, useRef} from "react";
import {URLS} from "../../utils/constants";
import placeholder_restaurant from "../../assets/placeholder_restaurant.png";
import 'swiper/swiper-bundle.css';
import Swiper from "swiper";

const SomethingNew = ({locations}) => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const swiperRef = useRef(null);

    useEffect(() => {
        // Initialize Swiper when component mounts
        if (swiperRef.current) {
            const swiper = new Swiper(swiperRef.current, {
                slidesPerView: xs ? 1 : sm ? 2.5 : md ? 4 : lg ? 5 : 6,
                spaceBetween: 20,
                loop: ((xs && locations.length > 1) || (sm && locations.length > 2) || (md && locations.length > 4) || (lg && locations.length > 5) || (locations.length > 6)),
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: true,
                centeredSlides: false,
                direction: 'horizontal',
            });
        }
    }, [xs, sm, md, lg]);

    const slidePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const slideNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return(
        <Box>
            <Container maxWidth='xl'>
                {locations.length > 0 ? (
                    <>
                        <Stack direction='row' sx={{marginX: 'auto', alignItems: 'center', justifyContent: 'space-between', maxWidth: {md: '850px', lg: '1150px', xl: '1500px'}}}>
                            <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600', color: 'black'}}>
                                Try something new in
                                <Box component='span' sx={{marginLeft: '0.25rem', color: pink[200]}}>
                                    .
                                </Box>
                            </Typography>
                            <Stack direction='row' columnGap='0.75rem'>
                                <IconButton onClick={()=> slidePrev()}>
                                    <KeyboardArrowLeftIcon sx={{color: 'black'}}/>
                                </IconButton>
                                <IconButton onClick={()=> slideNext()}>
                                    <KeyboardArrowRightIcon sx={{color: 'black'}}/>
                                </IconButton>
                            </Stack>
                        </Stack>
                        <Box sx={{marginX: 'auto', maxWidth: {xs: '250px',sm: '550px',md: '850px', lg: '1150px', xl: '1500px'}}}>
                            <Box ref={swiperRef} className="swiper-container" sx={{overflow: 'hidden'}}>
                                <Box className="swiper-wrapper">
                                    {locations.map((item, index) => (
                                        <Box key={index} className="swiper-slide" style={{ backgroundColor: 'transparent' }}>
                                            <Stack>
                                                <Stack sx={{backgroundImage: (item.img !== null  ? `url(${URLS.BaseUrlWithoutWebsite + "/" + item.img})` : `url(${placeholder_restaurant})`) , backgroundSize: 'cover', backgroundPosition: 'center', height: '175px', borderRadius: '0.25rem'}}>
                                                    <Stack direction='row' sx={{padding: '0.5rem 1rem',alignItems: 'center', justifyContent: 'space-between'}}>
                                                        <Box sx={{backgroundColor: 'black', fontSize: '11px', borderRadius: '5px', padding: '0 5px', textAlign: 'center', color: 'white', border: '1px solid white'}}>{item.category_name}</Box>
                                                    </Stack>
                                                </Stack>
                                                <Stack sx={{alignItems: 'start', padding: '0.5rem 0', rowGap: '0.5rem'}}>
                                                    <Typography variant='body2' sx={{color: 'black', fontSize: '14px', fontWeight: '600'}}>
                                                        {item.name}
                                                    </Typography>
                                                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                                        <Stack direction='row' columnGap='0.5rem' sx={{alignItems: 'center', justifyContent: 'center'}}>
                                                            <ScheduleIcon sx={{color: 'black', height: '15px', width: '15px'}} />
                                                            <Typography variant='body2' sx={{color: 'black', fontSize: '12px', fontWeight: '400'}}>
                                                                {item.wait_time}
                                                            </Typography>
                                                        </Stack>
                                                        <Typography variant='body2' sx={{color: 'black', fontSize: '12px', fontWeight: '400'}}>
                                                            {item.location_telephone}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}>
                        No Restaurants Found
                    </Typography>
                )}
            </Container>
        </Box>
    )
}

export default SomethingNew