
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    openCartDrawer: false
}

const variableSlice = createSlice({
    name: "variables",
    initialState,
    reducers: {
        setOpenCartDrawer(state, action) {
            state.openCartDrawer = action.payload
        }
    }
})

export const { setOpenCartDrawer } = variableSlice.actions;
export default variableSlice.reducer;
