// restaurantDataSlice.js

import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  specificHotelData: null,
};

const hoteltDataSlice = createSlice({
  name: "specificHotelData",
  initialState,
  reducers: {
    HotelData: (state, action) => {
      state.specificHotelData = action.payload;
    },
  },
});

export const { HotelData } = hoteltDataSlice.actions;
export default hoteltDataSlice.reducer;
