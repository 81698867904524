import "./App.css";
import Navbar from "./sections/navbar";
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import StickyFooter from "./sections/footer";
import ListRestaurants from "./components/ListRestaurants";
import CookieConsent from "react-cookie-consent";
import {THEME_COLOR_3} from "./colors";
import HotelPage from "./components/Hotel";
import Restaurant from "./pages/restaurant";
import OrderPlacement from "./pages/orderPlacement";
import GuestInformation from "./pages/guestInformation";
import Checkout from "./pages/checkout";
import Home from "./pages/home";
import HotelServices from "./pages/hotel_services";
import OrderSuccess from "./pages/orderSuccess";
import Error500 from "./pages/error500";
import Main from "./pages/dashboard/main";
import Layout from "./layouts/layout";
import Profile from "./pages/dashboard/profile";
import Reservations from "./pages/dashboard/reservations";
import Orders from "./pages/dashboard/orders";
import SignUp from "./pages/signUp";

function App() {
    const {pathname} = window.location
    const RedirectToNewFormat = () => {
        // Check if the URL matches the old format
        if (
            pathname !== "/" &&
            !pathname.startsWith("/hotel/") &&
            !pathname.startsWith("/restaurants/") &&
            !pathname.startsWith("/dashboard/")
        ) {
            const hotelName = pathname.replace("/", ""); // Extract the hotel name
            return <Navigate to={`/hotel/${hotelName}`} />;
        }

        return null;
    };

    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route exact path="/restaurants" element={<ListRestaurants />} />
                    <Route path="/hotel/:slug" element={<HotelPage />} />

                    <Route exact path="/" element={<Home />} />
                    <Route path="/account/signin" element={<OrderPlacement /> } />
                    <Route path="/account/signup" element={<SignUp />} />
                    <Route path="/account/guest" element={<GuestInformation /> } />
                    <Route path="/account/checkout" element={<Checkout /> } />
                    <Route path="/restaurants/:slug" element={<Restaurant />} />
                    <Route path="/services/:slug" element={<HotelServices />} />
                    <Route path="/order_success" element={<OrderSuccess />} />
                    <Route path="*" element={<RedirectToNewFormat />} />

                    <Route exact path="/dashboard" element={<Main />}>
                        <Route exact path="/dashboard" element={<Navigate to="/dashboard/profile" replace/>}/>
                        <Route exact path="profile" element={<Profile />} />
                        <Route path="reservations" element={<Reservations />} />
                        <Route path="orders" element={<Orders />} />
                    </Route>

                    <Route path="error" element={<Error500 />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
