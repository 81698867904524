import {ApiRoute, CONSTANTS} from "../utils/constants";
import axios from "axios";

export const getLocations = async () => {
  try {
    let response = await axios.get(`${ApiRoute.getLocations}`, {
      headers: {...CONSTANTS.REQUEST_HEADERS},
    });

    if (!response || !response.data) {
      return ({error: true, message: 'Cannot get Locations Data'})
    } else {
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot get Locations Data'})
  }
};

export const getLocationDetail = async (slug) => {
  try {
    let response = await axios.get(ApiRoute.getLocationDetail(slug), {
      headers: {
        ...CONSTANTS.REQUEST_HEADERS
      }
    })

    //("Location Details", response.data)
    if (!response || !response.data) {
      return ({error: true, message: 'Cannot get Location Details'})
    } else {
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot get Location Details', backendErrorMessage: err.response.data.message})
  }
};

export const verifyLocation = async (loc_id, lon, lat) => {
  try {
    let response = await axios.post(ApiRoute.verifyLocation(loc_id), {long: lon, lat: lat},
        {
          headers: {...CONSTANTS.REQUEST_HEADERS},
    });

    //(response.data)
    if (!response || !response.data) {
      return ({error: true, message: 'Cannot verify Location'})
    } else {
      if (response.data.message === 'Delivery available') {
        return ({error: false, delivery: true})
      } else {
        return ({error: false, delivery: false})
      }
    }
  } catch (err) {
    return ({error: true, message: 'Cannot verify Location'})
  }
}

export const getLocationData = async (lat, long, api_key) => {
  const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${api_key}`);
  if (response) return response
  throw new Error(`Could not Get the Location Data :  ${response}`)
}

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position); // Resolve the Promise with the position object
          },
          (err) => {
            reject(err); // Reject the Promise with the error object
          }
      );
    } else {
      reject(new Error('Geolocation is not supported'));
    }
  });
};
