import {ApiRoute, CONSTANTS} from "../utils/constants";
import axios from "axios";

export const getSurveyQuestions = async (location_id) => {
  try {
    const response = await axios.get(ApiRoute.getSurveyQuestions(location_id), {
      headers: {...CONSTANTS.REQUEST_HEADERS},
    });

    if (!response || !response.data) {
      return ({error: true, message: 'Cannot Get Survey Questions'})
    } else {
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot Get Survey Questions'})
  }
};

export const saveSurveyQuestions = async (location_id, data) => {
  try {
    let response1 = await axios.post(`${ApiRoute.saveSurveyQuestions(location_id)}`, {response: data} , {headers: {...CONSTANTS.REQUEST_HEADERS},});

    if (!response1 || !response1.data) {
      return ({error: true, message: 'Cannot Save Survey Questions'})
    } else {
      return ({error: false, data: response1.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot Save Survey Questions'})
  }
};