import {Box, Divider, Drawer, IconButton, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {grey, red} from "@mui/material/colors";
import dayjs from "dayjs";
import {URLS} from "../../utils/constants";
import {largeScreenCart} from "../../css/restaurantPageStyles";
import React from "react";
import {calculateTotalPrice} from "../../utils/cartDataHelpers";

const OrderDetailsDrawer = ({openDrawer, orderDetails, setOpenDrawer}) => {
    console.log("Order Details", orderDetails);

    return(
        (orderDetails ?
                <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor={'right'}>
                    <Stack sx={{width: '275px', height: '100%', backgroundColor: 'white', padding: '1rem 0.5rem', rowGap: '1rem'}}>
                        <IconButton size='small' onClick={() => setOpenDrawer(false)}
                                    sx={{height: 'fit-content', width: 'fit-content'}}>
                            <CloseIcon/>
                        </IconButton>

                        <Stack>
                            <Stack>
                                <Typography variant='body2' sx={{fontSize: '0.8rem', color: 'black'}}>Your order from</Typography>
                                <Typography variant='h6' sx={{
                                    fontSize: '1.1rem',
                                    color: 'black',
                                    fontWeight: '600'
                                }}>{orderDetails?.DirectOrder?.Location?.name}</Typography>
                                <Typography variant='body2' sx={{
                                    fontSize: '0.75rem',
                                    color: grey[600]
                                }}>{orderDetails?.DirectOrder?.Location?.formattedAddress}</Typography>
                            </Stack>
                        </Stack>

                        <Stack>
                            <Typography variant='body2' sx={{
                                fontSize: '0.75rem',
                                fontWeight: '600',
                                backgroundColor: red[200],
                                color: 'white',
                                width: 'fit-content',
                                borderRadius: '0.25rem',
                                textTransform: 'capitalize',
                                padding: '0.125rem 0.125rem'
                            }}>{orderDetails.DirectOrder.order_type}</Typography>
                            <Typography variant='h6' sx={{fontSize: '0.85rem', fontWeight: '600'}}>Order
                                # {orderDetails?.DirectOrder?.order_id}</Typography>
                            <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[500]}}>Paid through stripe</Typography>
                            <Typography variant='body2' sx={{fontSize: '0.8rem', color: grey[500]}}>Placed on {dayjs(orderDetails?.DirectOrder?.date_added).format("ddd, MMMM DD, YYYY hh:mm A")}</Typography>
                        </Stack>

                        <Divider />

                        <Stack direction='column' sx={{alignItems: 'start', columnGap: '1rem'}}>
                            <Typography variant='body2' sx={{fontSize: '0.8rem', color: 'black'}}>{orderDetails?.DirectOrder?.owner?.name}</Typography>
                            <Typography variant='body2' sx={{fontSize: '0.8rem', color: 'black'}}>{orderDetails?.DirectOrder?.owner?.number}</Typography>
                        </Stack>

                        <Divider />

                        <Typography sx={{fontSize: '0.9rem'}}>Summary</Typography>

                        <Stack sx={{rowGap: '1rem', overflow: 'auto'}}>
                            {
                                orderDetails.DirectOrder.OrderMenus.map((item, index)=> (
                                    <Stack key={index} direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Stack direction='row' sx={{columnGap: {md: '0.5rem', lg: '1rem'}}} alignItems='center'>
                                            <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + item.menu_photo}`} sx={largeScreenCart.itemImage}/>
                                            <Stack sx={{rowGap: '0.25rem'}}>
                                                <Typography variant='body2' sx={{fontSize: '0.75rem'}}>{item.name}</Typography>
                                                <Typography variant='body2' sx={{fontSize: '0.75rem'}}>{orderDetails.DirectOrder.Location.Currency.currency_symbol}{item.price}</Typography>
                                                {/*<Stack sx={{rowGap: '0.125rem'}}>*/}
                                                {/*    {*/}
                                                {/*        item.MenuOptions.length > 0 && (item.MenuOptions.map((menu_option, index) => (*/}
                                                {/*            <React.Fragment key={menu_option.option_id}>*/}
                                                {/*                {index !== 0 && <Divider />}*/}
                                                {/*                <Typography*/}
                                                {/*                    variant="body2"*/}
                                                {/*                    color="textSecondary"*/}
                                                {/*                    sx={{fontSize: '0.75rem', fontWeight: 'bold'}}*/}
                                                {/*                >*/}
                                                {/*                    {menu_option.option_name}*/}
                                                {/*                </Typography>*/}
                                                {/*                {menu_option.Option_Values.map(*/}
                                                {/*                    (menu_option_value) => (*/}
                                                {/*                        <Typography*/}
                                                {/*                            key={menu_option_value.option_id}*/}
                                                {/*                            variant="body2"*/}
                                                {/*                            color="textSecondary"*/}
                                                {/*                            sx={{fontSize: '0.7rem', textTransform: 'capitalize'}}*/}
                                                {/*                        >*/}
                                                {/*                            {`${menu_option_value.optionCount}x ${*/}
                                                {/*                                menu_option_value.value*/}
                                                {/*                            } = ${*/}
                                                {/*                                menu_option_value.price === 0*/}
                                                {/*                                    ? "Free"*/}
                                                {/*                                    : specificRestaurantData?.currency +*/}
                                                {/*                                        calculateVariantPrice(*/}
                                                {/*                                        menu_option_value.price,*/}
                                                {/*                                        menu_option_value.order_item_tax_percentage,*/}
                                                {/*                                        menu_option_value.optionCount*/}
                                                {/*                                    ).toFixed(2)*/}
                                                {/*                            }`}*/}
                                                {/*                        </Typography>*/}
                                                {/*                    ))}*/}
                                                {/*            </React.Fragment>*/}
                                                {/*        )))*/}
                                                {/*    }*/}
                                                {/*</Stack>*/}
                                            </Stack>
                                        </Stack>
                                        <Stack sx={{rowGap: '0.5rem', alignItems: 'center'}}>
                                            <Typography variant='body2' sx={{fontSize: {md: '12px', lg: '14px'}, fontWeight: '400'}}>
                                                {orderDetails.DirectOrder.Location.Currency.currency_symbol}
                                                {calculateTotalPrice(
                                                    item.price,
                                                    item.order_menu_tax,
                                                    item.quantity
                                                )}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                ))
                            }
                        </Stack>

                        <Divider />

                        <Stack>
                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                <Typography varian={'body2'} sx={{fontSize: '0.8rem'}}>
                                    Sub Total ({orderDetails.DirectOrder.total_items} {orderDetails.DirectOrder.total_items > 0 ? 'items' : 'item'})
                                </Typography>
                                <Typography variant='body2' sx={{fontSize: '0.8rem'}}>
                                    {orderDetails.DirectOrder.Location.Currency.currency_symbol}
                                    {orderDetails.DirectOrder.OrderTotals.map((item, index) => (
                                        (item.code === 'cart_total' ? item.value : null)
                                    ))}
                                </Typography>
                            </Stack>
                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                <Typography varian={'body2'} sx={{fontSize: '0.8rem'}}>
                                    Tax
                                </Typography>
                                <Typography variant='body2' sx={{fontSize: '0.8rem'}}>
                                    {orderDetails.DirectOrder.Location.Currency.currency_symbol}
                                    {orderDetails.DirectOrder.OrderTotals.map((item) => (
                                        (item.code === 'tax' ? item.value : null)
                                    ))}
                                </Typography>
                            </Stack>
                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                <Typography varian={'body2'} sx={{fontSize: '0.9rem', fontWeight: '600'}}>
                                    Total
                                </Typography>
                                <Typography variant='body2' sx={{fontSize: '0.9rem', fontWeight: '600'}}>
                                    {orderDetails.DirectOrder.Location.Currency.currency_symbol}
                                    {orderDetails.DirectOrder.OrderTotals.map((item) => (
                                        (item.code === 'order_total' ? item.value : null)
                                    ))}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Drawer> : <></>
        )
    )
}

export default OrderDetailsDrawer