import placeholder_img from "../../assets/placeholder_restaurant.png";
import {Box, Button, Collapse, Stack, Typography, useMediaQuery} from "@mui/material";
import Container from "@mui/material/Container";
import {grey, orange} from "@mui/material/colors";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import PlaceIcon from '@mui/icons-material/Place';
import StarIcon from '@mui/icons-material/Star';
import {useEffect, useRef, useState} from "react";
import LocationModal from "../../components/restaurant/locationModal";
import {URLS} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {topInformationStyles} from "../../css/restaurantPageStyles";
import SnackBar from "../../components/snackbar";
import {setUserInformation} from "../../features/userSlice";
import {MAIN_ORANGE_COLOR} from "../../colors";
import {useTheme} from "@mui/material/styles";
import Swiper from "swiper";

const TopInformation = ({selectedOption, setSelectedOption, showReservation, setShowReservation}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const swiperRef = useRef(null);
    const [openLocationModal, setOpenLocationModal] = useState(false)
    const [sp, setSnackbarParams] = useState({
        open: false,
        message: '',
        severity: 'error'
    })

    const restaurantData = useSelector((state)=> state.restaurantData.specificRestaurantData)
    const user = useSelector((state)=> state.user.user)

    useEffect(() => {
        if (swiperRef.current) {
            const swiper = new Swiper(swiperRef.current, {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: restaurantData.banners.length > 1,
                autoplay: restaurantData.banners.length > 1,
                centeredSlides: false,
            });
        }
    }, []);

    const ratingData = [
        {
            data: "4.5 +22 ratings",
            icon: <StarIcon sx={{height: '18px', width: '18px', color: 'black'}}/>
        }, {
            data: "Open until 11:55 PM",
            icon: <HistoryToggleOffIcon sx={{height: '18px', width: '18px', color: 'black'}}/>
        }, {
            data: "0 miles away",
            icon: <PlaceIcon sx={{height: '18px', width: '18px', color: 'black'}}/>
        }
    ]

    const buttons = [
        "Delivery", "Dining", 'Pickup'
    ]

    const handleOrderOption = (index) => {
        if (index === 2) {
            if (restaurantData.takeaway) {
                setSelectedOption(index)
                dispatch(setUserInformation({
                    order_type: 'takeaway'
                }))
            } else {
                setSnackbarParams({
                    open: true,
                    message: 'Sorry, this restaurant does not have a pickup option.',
                    severity: 'error'
                })
            }
        } else if (index === 0) {
            if (restaurantData.delivery) {
                setSelectedOption(index)
                dispatch(setUserInformation({
                    order_type: 'delivery'
                }))
            } else {
                setSnackbarParams({
                    open: true,
                    message: 'Sorry, this restaurant does not have a delivery option.',
                    severity: 'error'
                })
            }
        } else if (index === 1) {
            if (restaurantData.dine_in) {
                setSelectedOption(index)
                dispatch(setUserInformation({
                    order_type: 'dine_in'
                }))
            } else {
                setSnackbarParams({
                    open: true,
                    message: 'Sorry, this restaurant does not have a dine in option.',
                    severity: 'error'
                })
            }
        }
    }

    const handleMakeReservation = () => {
        setShowReservation(!showReservation)
    }

    return(<>
        <Box>
            {/*<Box component='marquee' sx={{padding: '0.5rem 0', backgroundColor: MAIN_ORANGE_COLOR, width: '100%'}}>*/}
            {/*    <Typography variant='body2' sx={{color: 'white', fontSize: '0.9rem'}}>*/}
            {/*        Free Delivery Above Orders {restaurantData.currency}{restaurantData.amount_threshold}*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            <Container maxWidth='xl' sx={{paddingTop: '0.5rem'}}>
                <Box sx={topInformationStyles.responsiveStack}>
                    <Stack sx={{marginBottom: {xs: '0rem', md: '0.5rem'}, overflow: 'hidden'}}>
                        <Typography variant='body2' sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>Home  /  Restaurant  /  {restaurantData.name} - {restaurantData.formattedAddress}, {restaurantData.city}, {restaurantData.state}, {restaurantData.country}</Typography>
                    </Stack>
                    <Stack sx={topInformationStyles.mainStack}>
                        <Stack sx={topInformationStyles.storeInfoStack}>
                            <Box sx={topInformationStyles.imageContainer}>
                                <Box component='img' src={restaurantData.img !== null ? (`${URLS.BaseUrlWithoutWebsite + "/" + restaurantData.img}`) : (`${placeholder_img}`)} alt={'restaurant'} sx={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: '5px'}} />
                            </Box>
                            <Stack sx={{rowGap: '0.5rem', alignItems: 'start', justifyContent: 'space-between'}}>
                                {/*<Stack direction='row'>*/}
                                {/*    <Typography variant='body2' sx={{color: grey[500], marginLeft: '0.25rem'}} >*/}
                                {/*        {restaurantData.category_name} .*/}
                                {/*    </Typography>*/}
                                {/*</Stack>*/}
                                <Typography variant='h4' sx={{textAlign: 'start', alignSelf: 'start', marginTop: '0.25rem'}}>
                                    {restaurantData.name}
                                </Typography>
                                {/*<Stack direction={{xs: 'column', sm: 'row'}} sx={{alignItems: 'start'}}>*/}
                                {/*    {ratingData.map((item, index)=> (*/}
                                {/*        <Stack key={index} sx={topInformationStyles.ratingMainStack}>*/}
                                {/*            {item.icon}*/}
                                {/*            <Typography variant='body2' sx={{color: grey[800], fontSize: {xs: '12px', md: '14px'}}} >*/}
                                {/*                {item.data} .*/}
                                {/*            </Typography>*/}
                                {/*        </Stack>*/}
                                {/*    ))}*/}
                                {/*</Stack>*/}
                                {(restaurantData && restaurantData.reservation) && <Button variant='contained' sx={{fontSize: '0.8rem', textTransform: 'capitalize', padding: '0.35rem 1rem', backgroundColor: MAIN_ORANGE_COLOR, '&:hover': {backgroundColor: orange[800]}, alignSelf: {xs: 'center', sm: 'start'}}} onClick={()=> handleMakeReservation()}>Make a Reservation</Button>}
                                <Stack direction='row' sx={{borderRadius: '30px', backgroundColor: grey[200], alignSelf: {xs: 'center', sm: 'start'}}}>
                                    {buttons.map((item, index)=> (
                                        ((index === 0 && restaurantData.delivery) || (index === 1 && restaurantData.dine_in) || (index === 2 && restaurantData.takeaway)) &&
                                        <Typography onClick={()=> handleOrderOption(index)} key={index} variant='body2' sx={{backgroundColor: (index === selectedOption ? 'black' : 'transparent'), color: (index === selectedOption ? "white" : 'black'), ...topInformationStyles.optionButtonSelections}}>
                                            {item}
                                        </Typography>
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Box sx={topInformationStyles.sliderMainBox}>
                            <Box ref={swiperRef} className="swiper-container" sx={{overflow: 'hidden'}}>
                                <Box className="swiper-wrapper">
                                {
                                    restaurantData.banners.map((item, index) => (
                                        <Box key={index} className="swiper-slide" style={{ backgroundColor: 'transparent' }}>
                                        <Stack sx={{position: 'relative'}}>
                                                <Box component='img'
                                                     src={`${URLS.BaseUrlWithoutWebsite + "/" + item.image}`}
                                                     alt={'slider'} sx={topInformationStyles.sliderImageStyle}/>
                                                {/*<IconButton sx={{
                                                    backgroundColor: 'white',
                                                    position: 'absolute',
                                                    top: '10px',
                                                    right: '10px'
                                                }}>
                                                    <FavoriteBorderIcon
                                                        sx={{height: '20px', width: '20px', color: 'black'}}/>
                                                </IconButton>*/}
                                            </Stack>
                                        </Box>
                                    ))
                                }
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
                <Collapse in={selectedOption === 0 && user.isDeliverable === 'not_defined'} unmountOnExit>
                    <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                        <Typography variant='h6'>Enter your address</Typography>
                        <Typography variant='body2'>We'll confirm that you can have this restaurant
                            delivered.</Typography>
                        <Typography variant='body2' sx={topInformationStyles.addressModalText}
                                    onClick={() => setOpenLocationModal(true)}>Add Address</Typography>
                    </Stack>
                </Collapse>
                <Collapse in={selectedOption === 0 && user.isDeliverable === 'not_deliverable'} unmountOnExit>
                    <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                        <Typography variant='h6'>You're out of range</Typography>
                        <Typography variant='body2'>This restaurant cannot deliver
                            to <b>{user.location.locationName}</b></Typography>
                        <Typography variant='body2' sx={topInformationStyles.addressModalText}
                                    onClick={() => setOpenLocationModal(true)}>Change Address</Typography>
                    </Stack>
                </Collapse>
                <LocationModal openLocationModal={openLocationModal} setOpenLocationModal={setOpenLocationModal}/>
            </Container>
        </Box>
        {sp.open && <SnackBar snackbarParams={sp} setSnackbarParam={setSnackbarParams}/> }
    </>)
}

export default TopInformation