import MuiAlert from "@mui/material/Alert";
import React from "react";
import {Snackbar} from "@mui/material";

const SnackBar = ({snackbarParams, setSnackbarParam}) => {


    return(
        <Snackbar sx={{height: (snackbarParams.location && snackbarParams.location === 'center') ? '100%' : 'fit-content'}} open={snackbarParams.open} autoHideDuration={2500} onClose={()=> setSnackbarParam({
            open: false,
            message: "",
            severity: "",
        })} anchorOrigin={(snackbarParams.location && snackbarParams.location === 'center') ? {vertical: 'top', horizontal: 'center'} : {vertical: 'bottom', horizontal: 'left'}}>
            <MuiAlert elevation={6} variant="filled" onClose={()=> setSnackbarParam({open: false, message: "", severity: ""})} severity={snackbarParams.severity}>
                {snackbarParams.message}
            </MuiAlert>
        </Snackbar>
    )
}

export default SnackBar