import {green, grey} from "@mui/material/colors";
import {tabsClasses} from "@mui/material/Tabs";
import {MAIN_GREEN_COLOR} from "../colors";

export const topInformationStyles = {
    responsiveStack: {
        marginX: 'auto',
        maxWidth: {sm: '550px', md: '850px', lg: '1150px', xl: '1500px'}
    },

    mainStack: {
        flexDirection: {xs: 'column-reverse', lg: 'row'},
        rowGap: '1rem' ,
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    storeInfoStack: {
        flexDirection: {xs: 'column', sm: 'row'},
        columnGap: '1rem',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%'
    },

    imageContainer: {
        height: '160px',
        width: '160px',
        padding: '0.25rem',
        borderRadius: '5px',
        border: `1px solid ${grey[200]}`
    },

    ratingMainStack: {
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: '0.25rem',
        marginLeft: '0.25rem',
        flexDirection: 'row'
    },

    sliderMainBox: {
        display: 'flex',
        marginLeft: 'auto',
        maxWidth: {xs: '100%', sm: '100%', md: '100%', lg: '550px', xl: '750px'}
    },

    sliderImageStyle: {
        height: {xs: '160px', md: '225px'},
        width: '100%',
        borderRadius: '5px'
    },

    addressModalText: {
        fontSize: '16px',
        fontWeight: '600',
        cursor: 'pointer',
        '&:hover': {color: green['A400']}
    },

    optionButtonSelections: {
        cursor: 'pointer',
        borderRadius: '30px',
        padding: '1rem 2rem',
        transition: '1s'
    }
}

export const locationModalStyles = {
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    mainStack: {
        padding: '1rem',
        backgroundColor: 'white',
        width: {xs: '90vw', sm:'550px', md: '700px'},
        borderRadius: '5px',
        rowGap: '1rem'
    }
}

export const searchSection = {
    mainStack: {
        rowGap: '0.5rem',
        alignItems: {md: 'start', lg: 'center'},
        justifyContent: 'space-between',
        columnGap: '1rem',
        position: 'sticky',
        top: '0',
        zIndex: '1',
        backgroundColor: 'white',
        flexDirection: {xs: 'column-reverse', lg: 'row'}
    },

    tabsStyle: {
        [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': {
                opacity: 0.3
            },
        },
        '& .MuiTabs-indicator': {
            backgroundColor: MAIN_GREEN_COLOR,
        },
        width: {xs: '90vw', sm: '550px', md: '585px', lg: '600px', xl: '850px'}
    },

    tabStyle: {
        fontSize: '14px',
        textTransform: 'capitalize',
        color: 'black',
        '&.Mui-selected': {
            color: MAIN_GREEN_COLOR
        }
    }
}

export const rowView = {
    itemMainStack : {
        border: `1px solid ${grey[200]}`,
        padding: '1rem',
        borderRadius: '5px',
        rowGap: '1rem',
        '&:hover': {
            boxShadow: '0 0 10px rgba(0,0,0,0.25)'
        },
        transition: '0.5s',
        justifyContent: 'space-between',
        width: '100%'
    },

    itemImage: {
        height: {xs: '80px', md: '80px'},
        width: {xs: '80px', md: '80px'},
        borderRadius: '5px'
    }
}

export const gridView = {
    itemMainStack: {
        border: `1px solid ${grey[200]}`,
        borderRadius: '5px',
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '0 0 10px rgba(0,0,0,0.25)'
        },
        transition: '0.5s',
        width: '100%'
    },

    itemImage: {
        width: '100%',
        height: {xs: '130px',md: '110px', lg: '120px', xl: '150px'}
    },

    infoStack: {
        padding: '0.5rem 1rem',
        rowGap: '0.25rem',
        justifyContent: 'space-between',
        flex: 1
    }
}

export const largeScreenCart = {
    summaryText : {
        fontSize: '15px',
        fontWeight: '600'
    },

    clearButton: {
        fontSize: '14px',
        textDecoration: 'underline',
        cursor: 'pointer'
    },

    itemImage: {
        height: {xs: '60px', sm: '65px', md: '65px', lg: '75px'},
        width: {xs: '60px', sm: '65px', md: '65px', lg: '75px'},
        borderRadius: '5px'
    },

    itemNameText: {
        fontSize: {xs: '14px', sm: '14px', md: '12px', lg: '14px'},
        fontWeight: '600'
    },

    itemPriceText: {
        fontSize: {xs: '12px', sm: '14px', md: '12px', lg: '14px'},
        fontWeight: '400'
    },

    iconStyle: {
        height: {xs: '15px', sm: '15px', md: '15px', lg: '17px', xl: '20px'},
        width: {xs: '15px', sm: '15px', md: '15px', lg: '17px', xl: '20px'},
        color: 'white'
    },

    checkoutButton: {
        cursor: 'pointer',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        padding: {xs: '0.75rem 1.25rem', md: '0.75rem 1.25rem', lg: '0.75rem 1.25rem',xl: '0.75rem 1.5rem'},
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: MAIN_GREEN_COLOR,
        textTransform: 'capitalize',
        fontSize: '16px',
        color: 'white'
    },

    checkoutButtonText: {
        fontSize: {xs: '15px', md: '15px', lg: '16px', xl: '18px'},
        textAlign: 'center'
    }
}

export const itemModal = {
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '1s'
    },

    mainStack: {
        height: {xs: '95vh', sm: '90vh'},
        width: {xs: '95vw', md: '50vw', lg: '35vw'},
        minWidth: {xs: '95vw', sm: '500px'},
        maxWidth: {xs: '95vw', sm: '500px'},
        backgroundColor: 'white',
        borderRadius: '5px'
    },

    priceSelectionButton: {
        backgroundColor: grey[200],
        textTransform: 'capitalize',
        color: 'black',
        borderRadius: '0.25rem',
        width: 'fit-content',
        paddingX: '1rem',
        paddingY: '0.35rem',
        transition: '0.5s',
        fontSize: '0.9rem'
    },

    addCartButton: {
        flex: 1,
        cursor: 'pointer',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        padding: '0.75rem 1.5rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: MAIN_GREEN_COLOR,
        textTransform: 'capitalize',
        fontSize: '16px',
        color: 'white'
    }
}

export const responsiveStack = {
    marginX: 'auto',
    maxWidth: {sm: '550px', md: '850px', lg: '1150px', xl: '1500px'}
}

export const cartMainBox= {
    minWidth: {md: '240px', lg: '285px', xl: '325px'},
    width: {md: '240px', lg: '285px', xl: '325px'},
    flexDirection: 'column',
    position: 'sticky',
    top: 0
}

export const cartSmallScreenButton = {
    maxWidth: '550px',
    cursor: 'pointer',
    transition: '0.5s',
    borderRadius: '25px',
    backgroundColor: 'black',
    padding: '0.5rem 0rem',
    width: '90vw',
    marginX: 'auto',
    position: 'sticky',
    zIndex: 5 ,
    bottom: '15px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
        backgroundColor: grey[700]
    }
}
