import {cardStyle} from "../../css/checkoutStyles";
import {Collapse, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Phone} from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PhoneModal from "./phoneModal";
import {useSelector} from "react-redux";

const PhoneSelection = () => {
    const user = useSelector((state) => state.user.user);
    const [phone, setPhone] = useState({
        realData: user.phoneNumber === "" || user.phoneNumber === null || user.phoneNumber === undefined ? "Enter a Phone Number" : user.phoneNumber,
        tempData: user.phoneNumber === "" || user.phoneNumber === null || user.phoneNumber === undefined ? "Enter a Phone Number" : user.phoneNumber,
    })
    const [openModal, setOpenModal] = useState(false)
    const [collapser, setCollapser] = useState(true)

    useEffect(()=> {
        setCollapser(false)
        const timeout = setTimeout(()=> {
            setCollapser(true)
            setPhone({...phone, realData: phone.tempData})
        }, 750)

        return ()=> clearTimeout(timeout)
    }, [user.phoneNumber])

    return(
        <>
            <Collapse in={collapser} timeout={750}>
                <Stack sx={cardStyle} direction='row' onClick={()=> setOpenModal(true)}>
                    <Stack direction='row' columnGap='1rem' sx={{alignItems: 'center'}}>
                        <Phone sx={{height: '25px', width: '25px', color: 'black'}} />
                        <Stack sx={{rowGap: '0.0625rem'}}>
                            <Typography variant='body2' sx={{fontSize: '0.9rem', textTransform: 'capitalize', color: 'black'}}>{phone.realData}</Typography>
                        </Stack>
                    </Stack>
                    <KeyboardArrowRightIcon sx={{height: '30px', width: '30px', color: 'black'}} />
                </Stack>
            </Collapse>
            { openModal && <PhoneModal phone={phone} setPhone={setPhone} setOpenModal={setOpenModal} openModal={openModal}/> }
        </>
    )
}

export default PhoneSelection