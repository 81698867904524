import {Box, Stack} from "@mui/material";
import {grey} from "@mui/material/colors";
import React, {useState} from "react";
import DynamicMaps from "./dynamicMaps";
import MoreLocationInformation from "./moreLocationInformation";
import LocationAutoComplete from "../generic/locationAutoComplete";
import StaticMap from "../generic/staticMap";

const DeliveryDetailsModal = ({setView, view}) => {
    const [location, setLocation] = useState({})

    return(
        <>
            <Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
            {view === 'autoComplete' && <LocationAutoComplete usage={"navbar"} setView={setView} setLocation={setLocation} location={location}/>}
            {view === 'mapSelection' &&
                <Stack sx={{width: '100%', borderRadius: '5px', border: `1px solid ${grey[200]}`}}>
                    <DynamicMaps setView={setView} location={location} setLocation={setLocation}/>
                </Stack>
            }
            {view === 'moreInfo' &&
                <>
                    <StaticMap latitude={location.lat} longitude={location.long} usage={"fromProps"} />
                    <MoreLocationInformation setView={setView} setLocation={setLocation} location={location}/>
                </>
            }
        </>
    )
}

export default DeliveryDetailsModal;