import {green, grey } from "@mui/material/colors";

export const inputFieldsStyle = {
    '& .MuiOutlinedInput-root': {
        backgroundColor: grey[100],
        borderRadius: '0.5rem',
        fontSize: '0.8rem',
        '& fieldset': {
            fontSize: '0.8rem',
            borderColor: grey[100],
            borderRadius: '0.5rem'
        },
        '&:hover fieldset': {
            borderColor: grey[600],
        },
        '&.Mui-focused fieldset': {
            border: `1px solid ${grey[800]}`
        },
    },
}

export const filledInputFieldsStyle = {
    '& .MuiFilledInput-root': {
        backgroundColor: grey[100],
        borderRadius: '0.5rem',
        fontSize: '0.9rem',
        overflow: 'hidden',
        '&:before': {
            borderRadius: '0.5rem',
            borderBottom: 'none'
        },
        '&:after': {
            borderRadius: '0.5rem',
            borderBottom: `2px solid ${green[400]}`
        },
        '&:hover:before': {
            borderBottom: 'none'
        },
        '&.Mui-focused:before': {
            borderRadius: '0.5rem'
        },
        '&.Mui-focused:after': {
            borderRadius: '0.5rem',
            borderBottom: `2px solid ${green[400]}`
        },
    },
    '& label': {
        color: grey[500],
        fontSize: '0.9rem'
    },
    '& label.Mui-focused': {
        color: green[400],
    },
}

export const selectFieldStyle = {
    '& .MuiOutlinedInput-root': {
        backgroundColor: grey[100],
        borderRadius: '0.5rem',
        '& fieldset': {
            borderColor: grey[100],
            borderRadius: '0.5rem'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: grey[600],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: grey[800],
        },
    },
    '& .MuiInputBase-input': {
        backgroundColor: grey[100],
    },
}