import Container from "@mui/material/Container";
import {Box, Skeleton, Stack, Typography} from "@mui/material";
import {cartMainBox, responsiveStack, rowView, searchSection, topInformationStyles} from "../css/restaurantPageStyles";
import Grid from "@mui/material/Grid";
import React from "react";


const RestaurantPageSkeleton = () => {
    return(
        <>
            <Box>
                <Container maxWidth='xl' sx={{paddingTop: '0.5rem'}}>
                    <Box sx={topInformationStyles.responsiveStack}>
                        <Stack sx={{marginBottom: {xs: '0rem', md: '0.5rem'}, overflow: 'hidden'}}>
                            <Skeleton variant="rectangular" height={10} width={'75%'} />
                        </Stack>
                        <Stack sx={topInformationStyles.mainStack}>
                            <Stack sx={topInformationStyles.storeInfoStack}>
                                <Box sx={topInformationStyles.imageContainer}>
                                    <Skeleton variant={'rounded'} sx={{height: '100%', width: '100%'}} />
                                </Box>
                                <Stack sx={{rowGap: '0.5rem', alignItems: 'start', justifyContent: 'space-between'}}>
                                    <Stack direction='row'>
                                        <Skeleton variant={'text'} width={'125px'} height={'15px'} />
                                    </Stack>
                                    <Typography variant='h4'>
                                        <Skeleton variant={'text'} width={'150px'} height={'30px'} />
                                    </Typography>
                                    <Stack direction={{xs: 'column', sm: 'row'}} sx={{alignItems: 'start'}}>
                                        <Stack direction={'row'} sx={{alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem'}}><Skeleton variant={'circular'} sx={{height: '15px', width: '15px'}}/> <Skeleton variant={'text'} width={'100px'} /></Stack>
                                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem'}}><Skeleton variant={'circular'} sx={{height: '15px', width: '15px'}}/><Skeleton variant={'text'} width={'100px'} /></Stack>
                                        <Stack direction={'row'} sx={{alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem'}}> <Skeleton variant={'circular'} sx={{height: '15px', width: '15px'}}/><Skeleton variant={'text'} width={'100px'} /></Stack>
                                    </Stack>
                                    <Stack direction='row' sx={{borderRadius: '30px', overflow: 'hidden'}}>
                                        <Skeleton variant='rounded' sx={{height: '50px', width: '300px'}}/>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Box sx={topInformationStyles.sliderMainBox}>
                                <Skeleton variant={'rectangular'} sx={{height: {xs: '160px', md: '225px'}, width: {xs: '100%', sm: '100%', md: '100%', lg: '550px', xl: '750px'}}} />
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>
            <Container maxWidth='xl'>
                <Stack direction='row' columnGap='0.75rem' sx={responsiveStack}>
                    <Stack rowGap='1rem'>
                        <Stack sx={searchSection.mainStack}>
                            <Skeleton
                                variant='text'
                                sx={{width: {md: '225px', lg: '225px', xl: '250px'}, marginRight: '0.5rem', height: '60px'}}
                            />

                            <Skeleton variant='rounded'
                                      sx={{...searchSection.tabsStyle, height: '30px', marginTop: '1rem'}}
                            />

                        </Stack>
                        <Stack>
                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                <Skeleton variant='text' sx={{height: '65px', width: '100px'}} />
                                <Stack direction='row' sx={{columnGap: '0.5rem'}}>
                                    <Skeleton variant='rounded' sx={{height: '35px', width: '35px'}} />
                                    <Skeleton variant='rounded' sx={{height: '35px', width: '35px'}} />
                                </Stack>
                            </Stack>
                            <Stack sx={{rowGap: '1rem'}}>
                                {[...Array(5).keys()].map((item, index)=>(
                                    <Stack key={index}>
                                        <Stack sx={{rowGap: '0.5rem'}}>
                                            <Skeleton variant='text' sx={{height: '45px', width: '150px'}} />
                                            <Grid container spacing={2}>
                                                {[...Array(7).keys()].map((catItems, catItemsIndex)=>(
                                                    <Grid key={catItemsIndex} item xs={12} sm={12} md={12} lg={6} xl={4} sx={{display: 'flex', alignItems: 'stretch'}}>
                                                        <Stack sx={rowView.itemMainStack}>
                                                            <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                                                                <Skeleton variant='rectangular' sx={rowView.itemImage} />
                                                                <Stack sx={{justifyContent: 'center', width: '100%'}}>
                                                                    <Skeleton variant='text' sx={{height: '45px', width: '35%'}} />
                                                                    <Skeleton variant='text' sx={{height: '20px', width: '100%'}} />
                                                                    <Skeleton variant='text' sx={{height: '20px', width: '100%'}} />
                                                                    <Skeleton variant='text' sx={{height: '20px', width: '70%'}} />
                                                                </Stack>
                                                            </Stack>
                                                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                                                <Skeleton variant='circular' sx={{height: '30px', width: '30px'}} />
                                                                <Skeleton variant='text' sx={{height: '45px', width: '50px'}} />
                                                                <Skeleton variant='circular' sx={{height: '35px', width: '35px'}} />
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box sx={{padding: '0.95rem 0'}}>
                        <Stack sx={cartMainBox}>
                            <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem'}}>
                                <Skeleton sx={{...searchSection.tabsStyle, height: '30px'}} variant='rounded'/>
                            </Stack>
                            <Stack sx={{backgroundColor: 'white'}}>
                                <Skeleton vairant='text' sx={{fontSize: '16px', fontWeight: '600', padding: '0.75rem', width: '65px'}} />
                                <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                                    <Skeleton variant='rounded' sx={{height: '50px', width: '50px', marginBottom: '0.5rem'}} />
                                    <Skeleton variant='text' sx={{fontSize: '14px', fontWeight: '600', width: '80%'}} />
                                    <Skeleton variant='text' sx={{fontSize: '12px', fontWeight: '400', width: '60%'}} />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Container>

        </>
    )
}

export default RestaurantPageSkeleton