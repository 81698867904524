import {Stack, Typography} from "@mui/material";
import OrderType from "../../components/checkout/orderType";
import PhoneSelection from "../../components/checkout/phoneSelection";

const OrderTypeTime = ({type, setType}) => {
    return(
        <Stack sx={{rowGap: '1rem'}}>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '1rem'}}>
                <Typography variant='body2' sx={{fontSize: '10px', borderRadius: '50%', backgroundColor: 'black', color: 'white', padding: '7px 12px'}}>1</Typography>
                <Typography variant='h6' sx={{fontSize: '17px', fontWeight: '600'}}>Order Type and Time</Typography>
            </Stack>
            <OrderType type={type} setType={setType}/>
            <PhoneSelection />
            { /*<PromotionSelection />
            <CourierTipSelection /> */}
        </Stack>
    )
}

export default OrderTypeTime