import React, {useEffect, useState} from "react";
import {getUpcomingEvents} from "../../services/EventService";
import {useSelector} from "react-redux";

const ReviewCard = ({ review }) => (
  <div className="card">
    <div className="card-body">
      <h5 className="card-title">{review.event_title}</h5>
      <p
        className="card-text"
        dangerouslySetInnerHTML={{ __html: review.event_desc }}
      />
    </div>
  </div>
);

const UpcomingEvents = () => {
  const specificRestaurantData = useSelector(
    (state) => state.restaurantData.specificRestaurantData
  );
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const reviewsData = await getUpcomingEvents(
        specificRestaurantData?.loc_id
      );
      setReviews(reviewsData);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  return (
    <div>
      <h2>Upcoming Events</h2>
      <div className="card-container">
        {reviews.map((review) => (
          <ReviewCard key={review.id} review={review} />
        ))}
      </div>
    </div>
  );
};

export default UpcomingEvents;
