import Navbar from "../sections/navbar";
import StickyFooter from "../sections/footer";
import {useLocation} from "react-router-dom";
import {THEME_COLOR_3} from "../colors";
import CookieConsent from "react-cookie-consent";

const Layout = ({children}) => {
    const location = useLocation();
    const shouldShowFooter = !location.pathname.includes('dashboard');

    return (
        <>
            <Navbar />
            {children}
            {shouldShowFooter && <StickyFooter/>}
            <CookieConsent
                buttonText="I UNDERSTAND"
                cookieName="Waiter As Service"
                style={{ background: THEME_COLOR_3, fontSize: "17px", zIndex: 10000 }}
                declineButtonStyle={{
                    color: "#fff",
                    fontSize: "13px",
                    background: "#FF0000",
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px 16px",
                    marginLeft: "16px",
                }}
                buttonStyle={{ color: "#4E503B", fontSize: "13px" }}
                expires={150}
                enableDeclineButton
                onDecline={() => window.location.reload()}
            >
                This website uses cookies to enhance the user experience.See our{" "}
                <a href="/cookies" style={{ fontWeight: 800, color: "white" }}>
                    Cookies Policy
                </a>
            </CookieConsent>
        </>
    )
}

export default Layout;