import {Stack, Typography} from "@mui/material";
import MapSelection from "../../components/checkout/mapSelection";
import DeliveryOptions from "../../components/checkout/deliveryOptions";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import DeliveryAddressLocations from "../../components/checkout/deliveryAddressLocations";

const DeliveryAddress = () => {
    const user = useSelector((state) => state.user.user);
    const [view, setView] = useState('')

    useEffect(() => {
        if (user.location.address === 'Select an Address' || user.isDeliverable === 'not_defined') {
            setView('detailedView')
        }
    }, []);

    return(
        <Stack sx={{rowGap: '1rem'}}>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '1rem'}}>
                <Typography variant='body2' sx={{fontSize: '10px', borderRadius: '50%', backgroundColor: 'black', color: 'white', padding: '7px 12px'}}>2</Typography>
                <Typography variant='h6' sx={{fontSize: '17px', fontWeight: '600'}}>Choose a delivery address</Typography>
            </Stack>
            {
                view === 'detailedView' ?
                    <>
                        <MapSelection />
                        <DeliveryOptions setView={setView}/>
                    </>
                    :
                    <>
                        <DeliveryAddressLocations user={user} setView={setView}/>
                    </>
            }
        </Stack>
    )
}

export default DeliveryAddress