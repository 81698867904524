import {ApiRoute, CONSTANTS, URLS} from "../utils/constants";

export const createReview = async (formData, location_id) => {
  try {
    const response = await fetch(`${ApiRoute.addReview(location_id)}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      const errorMessage = JSON.parse(errorText).creationError;
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error("Error:", error.message);
    throw new Error(error.message);
  }
};

export const getReviews = async (location_id) => {
  try {
    const response = await fetch(
      `${URLS.BaseUrl}review/hotels/approved/${location_id}`,
      {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS },
      }
    );
    if (!response.ok) {
      const errorText = await response.text();
      const errorMessage = JSON.parse(errorText).message;
      throw new Error(errorMessage);
    }

    // if (!response.ok) {
    //   throw new Error(`Failed to fetch reviews: ${response.status}`);
    // }

    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};
