import {AdvancedMarker, APIProvider, Map} from '@vis.gl/react-google-maps';
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const StaticMap = ({latitude, longitude, usage}) => {
    const location = useSelector((state) => state.user.user.location);
    const GOOGLE_MAPS_API_KEY = 'AIzaSyBlNITR7jNNamUPFBzPa8q7r4sz9yJ43IU'

    const [loc, setLoc] = useState({
        lat: usage === "fromProps" ? latitude : location.lat,
        lng: usage === "fromProps" ? longitude : location.long
    })

    useEffect(() => {
        setLoc({
            lat: usage === "fromProps" ? latitude : location.lat,
            lng: usage === "fromProps" ? longitude : location.long
        })
    }, [latitude, longitude]);

    const handleMapChange = (e) => {
        const newLocation = {
            lat: e.map.center.lat(),
            lng: e.map.center.lng()
        }
        setLoc(newLocation)
    }

    return (
        ((loc.lat && loc.lng) && (
            <Box sx={{height: '225px', maxHeight: '225px', minHeight: '225px', width: '100%', borderRadius: '5px'}}>
                <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
                    <Map
                        mapId={'513e271a218029d4'}
                        style={{width: '100%', height: '100%'}}
                        defaultCenter={{lat: loc.lat * 1 , lng: loc.lng * 1}}
                        center={{lat: loc.lat * 1 , lng: loc.lng * 1}}
                        defaultZoom={15}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        onCenterChanged={(e) => handleMapChange(e)}
                    >
                        <AdvancedMarker position={{
                            lat: usage === "fromProps" ? latitude * 1 : location.lat * 1,
                            lng: usage === "fromProps" ? longitude * 1 : location.long * 1
                        }} />
                    </Map>
                </APIProvider>
            </Box>
        ))
    );
};

export default StaticMap;