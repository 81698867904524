import {useSelector} from "react-redux";
import {Box, Stack, Typography} from "@mui/material";
import {blue, grey} from "@mui/material/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CottageIcon from "@mui/icons-material/Cottage";
import {MAIN_GREEN_COLOR} from "../../colors";
import {URLS} from "../../utils/constants";
import placeholder_img from "../../assets/placeholder_restaurant.png";
import {useLocation, useNavigate} from "react-router-dom";

const OrderInfo = () => {
    const restaurant = useSelector((state)=> state.restaurantData.specificRestaurantData)
    const user = useSelector((state)=> state.user.user)
    const {state} = useLocation();
    const navigate = useNavigate()

    const steps = [
        <ArrowForwardIcon sx={{padding: '0.20rem', height: '1.25rem', width: '1.25rem', color: 'white', borderRadius: '50%', backgroundColor: MAIN_GREEN_COLOR}}/>,
        <RestaurantIcon sx={{padding: '0.20rem', height: '1.25rem', width: '1.25rem', color: 'white', borderRadius: '50%', backgroundColor: grey[400]}}/>,
        <DirectionsCarIcon sx={{padding: '0.20rem', height: '1.25rem', width: '1.25rem', color: 'white', borderRadius: '50%', backgroundColor: grey[400]}}/>,
        <CottageIcon sx={{padding: '0.20rem', height: '1.25rem', width: '1.25rem', color: 'white', borderRadius: '50%', backgroundColor: grey[400]}}/>
    ];
    return(
        (state ? <Stack sx={{minWidth: {md: '250px', lg: '275px', xl: '300px'}, width: {md: '250px', lg: '275px', xl: '300px'}, padding: '1.5rem 0' }}>
            <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600', color: 'black'}}>Confirming your order</Typography>
            <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[700]}}>We sent your order to {restaurant.name} for final confirmation.</Typography>
            <Stack sx={{alignItems: 'center', flexDirection: 'row', marginY: '0.5rem'}}>
                {
                    steps.map((item, index)=> (<>
                        {item}{index !== 3 && <Box sx={{flexGrow: 1, height: '5px', borderRadius: '-5px', backgroundColor: grey[400]}}/>}
                    </>))
                }
            </Stack>

            <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem', cursor: 'pointer'}} onClick={()=> navigate(`/restaurants/${restaurant.slug}`)}>
                <Box sx={{fontSize: '0.8rem', height: '50px', width: '50px', borderRadius: '50%', border: `1px solid ${grey[300]}`, objectFit: 'cover', objectPosition: 'center'}} component='img' src={restaurant.img !== null ? (`${URLS.BaseUrlWithoutWebsite + "/" + restaurant.img}`) : (`${placeholder_img}`)} alt={restaurant.name}/>
                <Stack>
                    <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.4rem'}}>
                        <Typography variant='body2' sx={{fontSize: '0.9rem', fontWeight: '600', color: 'black'}}>{restaurant.name}</Typography>
                        <Typography variant='body2' sx={{fontSize: '0.55rem', backgroundColor: blue[200], color: 'black', borderRadius: '0.25rem', padding: '0.125rem 0.25rem', height: 'fit-content'}}>{restaurant.category_name}</Typography>
                    </Stack>
                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800]}}>
                        {restaurant.formattedAddress}
                    </Typography>
                </Stack>
            </Stack>

            <Box sx={{height: '1.5px', backgroundColor: grey[300], width: '100%', marginY: '0.85rem'}} />

            {/*<Stack direction='row' sx={{alignItems: 'center', columnGap: '0.25rem', marginBottom: '0.5rem'}}>
                <Typography variant='body2' sx={{fontSize: '0.9rem', fontWeight: '600', color: 'black'}}>Congratulations!</Typography>
                <RedeemIcon sx={{height: '1.25rem', width: '1.25rem', color: MAIN_GREEN_COLOR}} />
            </Stack>

            <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800], marginBottom: '0.5rem'}}>
                This order will earn you 5 points upon completion!
            </Typography>*/}

            <Stack sx={{rowGap: '0.25rem'}}>
                <Typography variant='body2' sx={{fontSize: '0.85rem', fontWeight: '600', color: 'black'}}>{restaurant.name}</Typography>
                <Typography variant='body2' sx={{fontSize: '0.75rem', fontWeight: '600', color: grey[800]}}>Order #{state.orderData.data.order.order_id}</Typography>
                <Typography variant='body2' sx={{fontSize: '0.75rem', fontWeight: '600', color: grey[800]}}>{state.orderData.data.order.total_items} items</Typography>
                <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800]}}>{restaurant.currency} {state.orderData.data.order.order_total.toFixed(2)} Subtotal</Typography>
            </Stack>

            {
                state.orderData.data.directOrderMenu.map((item, index)=> (
                    <Typography key={index} sx={{fontSize: '0.75rem', color: grey[800]}}>
                        {item.quantity} x {item.name}
                    </Typography>
                ))
            }

            <Box sx={{height: '1.5px', backgroundColor: grey[300], width: '100%', marginY: '0.85rem'}} />

            {state.orderData.data.order.order_type === 'delivery' && <>
                <Typography variant='body2' sx={{fontSize: '0.95rem', fontWeight: '600', color: 'black', marginBottom: '0.4rem'}}>Delivery Address</Typography>
                <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800]}}>
                    {user.location.address}
                </Typography>
            </>}
        </Stack> : navigate('/'))
    )
}

export default OrderInfo