import {ApiRoute, CONSTANTS} from '../utils/constants';
import axios from "axios";

export const login = async (data) => {
  try {
    const response = await axios.post(`${ApiRoute.signInUser}`, {...data}, {headers: {...CONSTANTS.REQUEST_HEADERS}});

    if (!response || !response.data || !response.data.token) {
      return ({error: true, message: 'Cannot Login User'})
    } else {
      localStorage.removeItem('servallAuthUserToken');
      localStorage.setItem('servallAuthUserToken', JSON.stringify(response.data.token));
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot Login User'})
  }
};

export const loginWithEmail = async ({ email, password }) => {
  let data = { email, password };
  let response = await fetch(`${ApiRoute.signInUserWithMail}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not login: ${response}`);
};

export const register = async ( data ) => {
  try {
    let response = await axios.post(`${ApiRoute.signUpUser}`, {...data},
        {headers: {...CONSTANTS.REQUEST_HEADERS}},
    );

    if (!response || !response.data || !response.data.token) {
      return ({error: true, message: 'Cannot Register User'})
    } else {
      localStorage.setItem('servallAuthUserToken', JSON.stringify(response.data.token));
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot Register User'})
  }
};

export const deleteAccount = async () => {
  let token = JSON.parse(localStorage.getItem('servallAuthUserToken'));
  if (token) {
    try {
      const response = await axios.delete(ApiRoute.deleteUserAccount, {
        headers: {
          ...CONSTANTS.REQUEST_HEADERS,
          "x-access-token": token
        },
      });

      if (!response || !response.data) {
        return ({error: true, message: 'Cannot Delete Account'})
      } else {
        return ({error: false, data: response.data})
      }
    } catch (err) {
      console.log("Error While Deleting Account", err);
      return ({error: true, message: 'Cannot Delete Account'})
    }
  } else {
    return ({error: true, message: 'Cannot Delete Account'})
  }
}

export const getToken = () => {
  let token = localStorage.getItem('servallAuthUserToken');

  if (token) {
    return true;
  } else {
    return false;
  }
};

