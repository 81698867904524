import {CONSTANTS, URLS} from "../utils/constants";

export const getUpcomingEvents = async (location_id) => {
  try {
    const response = await fetch(`${URLS.BaseUrl}events/${location_id}`, {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS },
    });
    if (!response.ok) {
      const errorText = await response.text();
      const errorMessage = JSON.parse(errorText).message;
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};
