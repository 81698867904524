import numeral from "numeral";

export const calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice =
        tax > 0
            ? (itemPrice + (itemPrice * tax) / 100) * itemCount
            : itemPrice * itemCount;

    let price = parseFloat(formatPrice(totalPrice.toFixed(2)));
    price = isNaN(price) ? 0 : price.toFixed(2);
    return price;
};

export const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
    const itemPrice = optionPrice || 0;
    const tax = taxPercent || 0;
    const variantPrice =
        tax > 0
            ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
            : itemPrice * itemCount;

    return variantPrice;
};

const formatPrice = (price) => {
    if (price >= 1000) {
        const formattedValue = numeral(price).format("0.0a");
        return formattedValue;
    } else {
        return numeral(price).format("0.00");
    }
};

export const handleCartIncrement = (itemIndex, cart) => {
    const updatedCart = cart.map((item, index) => {
        if (index === itemIndex) {
            const updatedItem = {
                ...item,
                itemCount: item.itemCount + 1,
                order_menu_tax:
                    (item.order_menu_tax / item.itemCount) * (item.itemCount + 1),
                MenuOptions: item.MenuOptions.map((menuOption) => ({
                    ...menuOption,
                    Option_Values: menuOption.Option_Values.map((optionValue) => {
                        let initialOptionCount = optionValue.initialOptionCount;

                        if (initialOptionCount === undefined) {
                            initialOptionCount = optionValue.optionCount;
                        }

                        const updatedOptionValue = {
                            ...optionValue,
                            optionCount: optionValue.optionCount + initialOptionCount,
                            initialOptionCount,
                        };

                        return updatedOptionValue;
                    }),
                })),
            };
            return updatedItem;
        }
        return item;
    });

    return updatedCart
};

export const handleCartDecrement = (itemIndex, cart) => {
    const updatedCart = cart.map((item, index) => {
        if (index === itemIndex) {
            const updatedItem = {
                ...item,
                itemCount: item.itemCount > 1 ? item.itemCount - 1 : 1,
                order_menu_tax:
                    (item.order_menu_tax / item.itemCount) * (item.itemCount - 1),
                MenuOptions: item.MenuOptions.map((menuOption) => ({
                    ...menuOption,
                    Option_Values: menuOption.Option_Values.map((optionValue) => {
                        let initialOptionCount = optionValue.initialOptionCount;

                        if (initialOptionCount === undefined) {
                            initialOptionCount = optionValue.optionCount;
                        }

                        const newOptionCount =
                            optionValue.optionCount > initialOptionCount
                                ? optionValue.optionCount - initialOptionCount
                                : initialOptionCount;

                        return {
                            ...optionValue,
                            optionCount: newOptionCount,
                            initialOptionCount,
                        };
                    }),
                })),
            };
            return updatedItem;
        }
        return item;
    });

    return updatedCart
};



