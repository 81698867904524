import React, {useMemo} from "react";
import {Box, Button, Stack} from "@mui/material";
import {AdvancedMarker, APIProvider, Map} from "@vis.gl/react-google-maps";
import {grey} from "@mui/material/colors";
import {MAIN_GREEN_COLOR} from "../../colors";
import {getLocationData} from "../../services/LocationService";

const DynamicMaps = ({location, setLocation, setView}) => {
    const GOOGLE_MAPS_API_KEY = 'AIzaSyBlNITR7jNNamUPFBzPa8q7r4sz9yJ43IU'

    const center = useMemo(() => ({ lat: location.lat, lng: location.long }), [location]);

    const handleChangeLocation = (e) => {
        const newLocation = {
            lat: e.map.center.lat(),
            long: e.map.center.lng()
        }
        setLocation(newLocation)
    }

    const handleCancelEvent = () => {
        setView('normal')
    }

    const handleSaveAddress = async () => {
        try {
            const response = await getLocationData(center.lat, center.lng, GOOGLE_MAPS_API_KEY)
            const userLocation = {
                locationName: response.data.results[0].formatted_address,
                address: response.data.results[0].formatted_address,
                lat: response.data.results[0].geometry.location.lat,
                long: response.data.results[0].geometry.location.lng,
                country: '',
                state: '',
                city: '',
                streetName: '',
                streetNumber: '',
                postal_code: '',
                place_id: response.data.results[0].place_id
            }

            response.data.results[0].address_components.map((item) => {
                if (item.types.includes('country')) {
                    userLocation.country = item.long_name
                }
                if (item.types.includes('administrative_area_level_1')) {
                    userLocation.state = item.long_name
                }
                if (item.types.includes('locality')) {
                    userLocation.city = item.long_name
                }
                if (item.types.includes('route')) {
                    userLocation.streetName = item.long_name
                }
                if (item.types.includes('street_number')) {
                    userLocation.streetNumber = item.long_name
                }
                if (item.types.includes('postal_code')) {
                    userLocation.postal_code = item.long_name
                }
            })
            setLocation(userLocation)
            setView('normal')
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Stack sx={{width: '100%', rowGap: '1rem'}}>
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
                <Map
                    mapId={'513e271a218029d4'}
                    style={{width: '100%', height: '350px'}}
                    defaultCenter={center}
                    defaultZoom={15}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    onDrag={(e) => handleChangeLocation(e)}
                >
                    <AdvancedMarker position={center} />
                </Map>
            </APIProvider>
            <Box sx={{height: '2px', minHeight: '2px', backgroundColor: grey[200], width: '100%'}} />
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', columnGap: '1rem'}}>
                <Button sx={{backgroundColor: 'black', color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem', width: '50%'}} onClick={()=> handleCancelEvent()}>Cancel</Button>
                <Button sx={{backgroundColor: MAIN_GREEN_COLOR, color: 'white', textTransform: 'capitalize', fontSize: '0.9rem', height: '56px', borderRadius: '8px', paddingX: '1rem', width: '50%'}} onClick={()=> handleSaveAddress()}>Set Pin</Button>
            </Stack>
        </Stack>
    );
}

export default DynamicMaps
