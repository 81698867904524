import {useEffect, useState} from "react";
import {Collapse, Stack, Typography} from "@mui/material";
import {cardStyle} from "../../css/checkoutStyles";
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {brown, grey, yellow} from "@mui/material/colors";
import OrderTypeSelectionModal from "./orderTypeSelectionModal";
import {useSelector} from "react-redux";
import {MAIN_GREEN_COLOR} from "../../colors";
import {DeliveryDining} from "@mui/icons-material";

const OrderType = ({type, setType}) => {
    const user = useSelector((state) => state.user.user);
    const restaurant = useSelector((state) => state.restaurantData.specificRestaurantData);
    const [openModal, setOpenModal] = useState(false)
    const [collapser, setCollapser] = useState({
        mainCollapser: true,
        subCollapser: true,
        subCollapserMessage: '',
        subCollapserSubMessage: ''
    })
    const [distance, setDistance] = useState('0')

    useEffect(()=> {
        setCollapser({...collapser, mainCollapser: false})
        const timeout = setTimeout(()=> {
            setCollapser({...collapser, mainCollapser: true})
            setType({...type, realType: type.sendingType})
        }, 750)

        return ()=> clearTimeout(timeout)
    }, [type.sendingType])

    /*const getDistance = async () => {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=${location.lat},${location.long}&destinations=${restaurant.latitude},${restaurant.longitude}&key=${GOOGLE_MAPS_API_KEY}`)
            //(response.data)
        } catch (err) {
            //(err)
        }
    } to find the distance using google maps api
    */

    const getDistance = (lattitude1, lattitude2, longittude1, longittude2) => {
        const toRadian = n => (n * Math.PI) / 180

        let lat2 = lattitude2
        let lon2 = longittude2
        let lat1 = lattitude1
        let lon1 = longittude1

        let R = 6371  // km
        let x1 = lat2 - lat1
        let dLat = toRadian(x1)
        let x2 = lon2 - lon1
        let dLon = toRadian(x2)
        let a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        let d = R * c
        d = d.toFixed(3)
        setDistance(d)
    }

    useEffect(()=> {
        getDistance(user.location.lat, restaurant.latitude, user.location.long, restaurant.longitude)
    },[user.location])

    useEffect(() => {
        setCollapser({...collapser, subCollapser: false})
        const timeout = setTimeout(()=> {
            setCollapser({...collapser, subCollapser: true,
                subCollapserMessage: user.isDeliverable === 'not_defined' ? "Please, Select an Address" : user.isDeliverable === 'deliverable' ? 'You are in range' : 'You are out of range',
                subCollapserSubMessage: user.isDeliverable === 'not_defined' ? "" : user.isDeliverable === 'deliverable' ? ' This restaurant can deliver to your location.' : 'This restaurant cannot deliver to your location'
        })
            }, 750)

        return ()=> clearTimeout(timeout)
    }, [user.isDeliverable]);

    return(
        <>
            <Collapse in={collapser.mainCollapser} timeout={750}>
                <Stack sx={cardStyle} direction='row' onClick={()=> setOpenModal(true)}>
                    {
                        type.realType === 'delivery' ? (
                            <Stack direction='row' columnGap='1rem' sx={{alignItems: 'center'}}>
                                <DirectionsBikeIcon sx={{height: '25px', width: '25px', color: 'black'}} />
                                <Stack sx={{rowGap: '0.0625rem'}}>
                                    <Typography variant='body2' sx={{fontSize: '0.9rem', textTransform: 'capitalize', color: 'black'}}>{type.realType}</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[600]}}>{user.orderDateTime.schedule === 'now' ? `Now ${user.orderDateTime.formattedDate} ${user.orderDateTime.start_time} - ${user.orderDateTime.end_time}` : `Schedule for later ${user.orderDateTime.formattedDate} ${user.orderDateTime.start_time} - ${user.orderDateTime.end_time}`}</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[600]}}>{distance > 1 ? `${distance} kms` : `${distance} km`}</Typography>
                                    <Collapse in={collapser.subCollapser}>
                                        <Stack sx={{backgroundColor: (user.isDeliverable === 'deliverable' ? MAIN_GREEN_COLOR : yellow[100]), padding: '0.25rem 0.5rem', rowGap: '0.125rem', borderRadius: '5px'}}>
                                            <Typography variant='body2' sx={{fontSize: '0.75rem', color: (user.isDeliverable === 'deliverable' ? 'white' : brown[500])}}>{collapser.subCollapserMessage}</Typography>
                                            <Typography variant='body2' sx={{fontSize: '0.75rem', color: (user.isDeliverable === 'deliverable' ? 'white' : brown[500])}}>{collapser.subCollapserSubMessage}</Typography>
                                        </Stack>
                                    </Collapse>
                                </Stack>
                            </Stack>
                        ) : type.realType === 'takeaway' ? (
                            <Stack direction='row' columnGap='1rem' sx={{alignItems: 'center'}}>
                                <DirectionsWalkIcon sx={{height: '25px', width: '25px', color: 'black'}} />
                                <Stack sx={{rowGap: '0.0625rem'}}>
                                    <Typography variant='body2' sx={{fontSize: '0.9rem', textTransform: 'capitalize', color: 'black'}}>{type.realType === 'takeaway' ? 'Pickup' : type.realType}</Typography>
                                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[600]}}>{user.orderDateTime.schedule === 'now' ? 'Now' : 'Schedule for later'} </Typography>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack direction='row' columnGap='1rem' sx={{alignItems: 'center'}}>
                                <DeliveryDining sx={{height: '25px', width: '25px', color: 'black'}} />
                                <Stack sx={{rowGap: '0.0625rem'}}>
                                    <Typography variant='body2' sx={{fontSize: '0.9rem', textTransform: 'capitalize', color: 'black'}}>Please, select a Delivery Type</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    <KeyboardArrowRightIcon sx={{height: '30px', width: '30px', color: 'black'}} />
                </Stack>
            </Collapse>
            { openModal && <OrderTypeSelectionModal type={type} setType={setType} setOpenModal={setOpenModal} openModal={openModal}/> }
        </>
    )
}

export default OrderType