import {useEffect, useState} from "react";
import {getUserOrderHistory} from "../../services/OrderService";
import {blue, grey} from "@mui/material/colors";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {URLS} from "../../utils/constants";
import dayjs from "dayjs";
import OrderDetailsDrawer from "../../components/dashboard/orderDetailsDrawer";
import Grid from '@mui/material/Grid2';
import {MAIN_ORANGE_COLOR} from "../../colors";

const Orders = () => {
    const [ordersHistory, setOrderHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
    const [orderDetail, setOrderDetail] = useState(null);

    const fetUserOrderHistory = async () => {
        setLoading(true);
        const response = await getUserOrderHistory();

        if (response.error) {
            setOrderHistory([])
        } else {
            setOrderHistory(response.data.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetUserOrderHistory().then(r => "")

    }, []);

    const handleOpenOrderDetailDrawer = (order) => {
        setOpenDetailDrawer(true);
        setOrderDetail(order)
    }

    return(
        <>
            <OrderDetailsDrawer setOpenDrawer={setOpenDetailDrawer} orderDetails={orderDetail} openDrawer={openDetailDrawer}/>
            <Stack sx={{height: 'inherit', width: '100%', backgroundColor: grey[100], padding: '0 1rem', rowGap: '1rem', overflowY: 'auto'}}>
                <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center', marginTop: '1rem'}}>
                    <LocalMallOutlinedIcon />
                    <Typography variant='h6' sx={{fontSize: '0.8rem', fontWeight: '400'}}>Orders</Typography>
                </Stack>

                <Stack direction='row' sx={{backgroundColor: 'white', padding: {xs: '1rem 0.5rem', sm: '1rem 2rem'}, borderRadius: '0.25rem', boxShadow: '0 0 1px rgba(0,0,0,0.25)', alignItems: 'center', justifyContent: 'space-between'}}>
                    <LocalMallOutlinedIcon sx={{height: '70px', width: '70px'}} />
                    <Stack alignItems='start' justifyContent='center'>
                        <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>
                            {ordersHistory.length > 0 ? "We like each other" : "You don't have any order yet"}
                        </Typography>
                        <Typography variant='body2' sx={{fontSize: '0.75rem'}}>
                            {ordersHistory.length > 0 ? "Let's not change that" : "Let's change that"}
                        </Typography>
                    </Stack>
                    <Stack alignItems='start' justifyContent='center'>
                        <Typography variant='body2' sx={{fontSize: '0.75rem'}}>
                            Orders
                        </Typography>
                        <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>
                            {ordersHistory.length}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack sx={{alignItems: 'center', justifyContent: 'center', width: '100%', rowGap: '0.5rem'}}>
                    {loading ? <CircularProgress color='success'/> :
                        (ordersHistory.length > 0 ?
                                ordersHistory.map((item, index) => (
                                    <Grid spacing={2} container key={index} sx={{rowGap: '1rem', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', width: '100%', padding: '0.5rem 1rem', borderRadius: '0.25rem', cursor: 'pointer'}} onClick={()=> handleOpenOrderDetailDrawer(item)}>
                                        <Grid size={{xs: 12, sm: 12, md: 4, lg: 4, xl: 4}}>
                                            <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem'}}>
                                                <Box component={'img'} sx={{borderRadius: '50%', height: '40px', width: '40px'}} src={`${URLS.BaseUrlWithoutWebsite + "/" + item.DirectOrder.Location.img}`}/>
                                                <Stack>
                                                    <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem', justifyContent: 'space-between'}}>
                                                        <Typography variant='h6' sx={{fontSize: '0.9rem', fontWeight: '600'}}>{item.DirectOrder.Location.name}</Typography>
                                                        <Typography variant='body2' sx={{color: 'white', fontSize: '0.7rem', textTransform: 'capitalize', backgroundColor: MAIN_ORANGE_COLOR, padding: '0.0625rem 0.25rem', borderRadius: '0.25rem'}}>{item.DirectOrder.order_type}</Typography>
                                                    </Stack>
                                                    <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800]}}>{item.DirectOrder.Location.formattedAddress}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>

                                        <Grid size={{xs: 12, sm: 6, md: 4, lg: 4, xl: 4}}>
                                            <Typography variant='h6' sx={{fontSize: '0.70rem', fontWeight: '400', color: grey[500]}}>{item.DirectOrder.order_id}</Typography>
                                            <Stack>
                                                <Typography variant='body2' sx={{fontWeight: '600', color: grey[700], fontSize: '0.7rem', textTransform: 'capitalize', backgroundColor: grey[100], padding: '0.0625rem 0.25rem', borderRadius: '0.25rem', width: 'fit-content'}}>{item.DirectOrder.total_items} {item.DirectOrder.total_items > 1 ? "items" : "item"}</Typography>
                                                <Typography variant='body2' sx={{fontSize: '0.85rem', color: grey[800], textTransform: 'capitalize'}}>
                                                    {item.DirectOrder.OrderMenus.map((menuItem) => (
                                                        `${menuItem.name} , `
                                                    ))}
                                                </Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid size={{xs: 12, sm: 6, md: 4, lg: 4, xl: 4}}>
                                            <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem'}}>
                                                {/*<Typography variant='body2' sx={{color: grey[500], fontSize: '0.85rem'}}>Total: </Typography>*/}
                                                <Typography variant='body2' sx={{color: grey[800], fontSize: '0.85rem', fontWeight: '600'}}>{item.DirectOrder.Location.Currency.currency_symbol}{item.DirectOrder.order_total}</Typography>
                                            </Stack>
                                            <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[500]}}>Placed on {dayjs(`${item.DirectOrder.order_date} ${item.DirectOrder.order_time}`).format("ddd, MMMM MM, YYYY hh:mm A")}</Typography>
                                        </Grid>
                                    </Grid>
                                )) :
                                <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>No Orders Yet</Typography>
                        )}
                </Stack>
                <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[700], marginX: 'auto', marginBottom: '1rem'}}>end of results</Typography>
            </Stack>
        </>
    )
}

export default Orders;