import {IconButton, Stack, Typography} from "@mui/material";
import WindowIcon from '@mui/icons-material/Window';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import {useState} from "react";
import ItemsRowView from "../../components/hotel_services/itemsRowView";
import ItemsGridView from "../../components/hotel_services/itemsGridView";

const MenuSection = ({setSelectedTab}) => {

    const [selectedView, setSelectedView] = useState("row");

    return(
        <Stack>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                <Typography varirant='body2' sx={{fontSize: '24px', fontWeight: '800'}}>
                    Services
                </Typography>
                <Stack direction='row'>
                    <IconButton onClick={()=> setSelectedView('grid')}>
                        <WindowIcon sx={{height: '25px', width: '25px', color: 'black'}}/>
                    </IconButton>
                    <IconButton onClick={()=> setSelectedView('row')}>
                        <ViewStreamIcon sx={{height: '25px', width: '25px', color: 'black'}} />
                    </IconButton>
                </Stack>
            </Stack>
            {
                selectedView === 'row' ?
                    <ItemsRowView setSelectedTab={setSelectedTab}/> :
                    <ItemsGridView setSelectedTab={setSelectedTab}/>
            }
        </Stack>
    )
}

export default MenuSection