import {useEffect, useState} from "react";
import {getUserReservations} from "../../services/ReservationService";
import {blue, grey} from "@mui/material/colors";
import {Stack} from "@mui/material";
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {URLS} from "../../utils/constants";
import {MAIN_ORANGE_COLOR} from "../../colors";

const Reservations = () => {
    const [userReservations, setUserReservations] = useState([]);
    const [loading, setLoading] = useState(false);

    const getUserReservationsHistory = async () => {
        setLoading(true);
        const response = await getUserReservations();

        if (response.error) {
            setUserReservations([])
        } else {
            setUserReservations(response.data.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        getUserReservationsHistory()
    }, []);

    return(
        <Stack sx={{height: 'inherit', width: '100%', backgroundColor: grey[100], padding: '0 1rem', rowGap: '1rem', overflowY: 'auto'}}>
            <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center', marginTop: '1rem'}}>
                <RoomServiceOutlinedIcon />
                <Typography variant='h6' sx={{fontSize: '0.8rem', fontWeight: '400'}}>Reservations</Typography>
            </Stack>

            <Stack direction='row' sx={{backgroundColor: 'white', padding: {xs: '1rem 0.5rem', sm: '1rem 2rem'}, borderRadius: '0.25rem', boxShadow: '0 0 1px rgba(0,0,0,0.25)', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.5rem'}}>
                <RoomServiceOutlinedIcon sx={{height: '70px', width: '70px'}} />
                <Stack alignItems='start' justifyContent='center'>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>
                        {userReservations.length > 0 ? "We like each other" : "You don't have any reservations yet"}
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: '0.75rem'}}>
                        {userReservations.length > 0 ? "Let's not change that" : "Let's change that"}
                    </Typography>
                </Stack>
                <Stack alignItems='start' justifyContent='center'>
                    <Typography variant='body2' sx={{fontSize: '0.75rem'}}>
                        Reservations
                    </Typography>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>
                        {userReservations.length}
                    </Typography>
                </Stack>
            </Stack>

            <Stack sx={{alignItems: 'center', justifyContent: 'center', width: '100%', rowGap: '0.5rem'}}>
                {loading ? <CircularProgress color='success'/> : (
                    userReservations.length > 0 ? (
                        userReservations.map((item, index) => (
                            <Stack key={index} direction={{xs: 'column', sm: 'row'}} sx={{rowGap: '1rem', alignItems: {xs: 'start', sm: 'center'}, justifyContent: 'space-between', backgroundColor: 'white', width: 'calc(100% - 2rem)', padding: '0.5rem 1rem', borderRadius: '0.25rem'}}>
                                <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem'}}>
                                    <Box component={'img'} sx={{borderRadius: '50%', height: '40px', width: '40px'}} src={`${URLS.BaseUrlWithoutWebsite + "/" + item.loc_id.img}`}/>
                                    <Stack>
                                        <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem'}}>
                                            <Typography variant='h6' sx={{fontSize: '0.9rem', fontWeight: '600'}}>{item.loc_id.name}</Typography>
                                            {/*<Typography variant='body2' sx={{color: 'white', fontSize: '0.7rem', textTransform: 'capitalize', backgroundColor: blue[200], padding: '0.0625rem 0.25rem', borderRadius: '0.25rem'}}>{item.DirectOrder.order_type}</Typography>*/}
                                        </Stack>
                                        <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800]}}>{item.loc_id.formattedAddress}</Typography>
                                    </Stack>
                                </Stack>

                                <Stack columnGap='0.5rem'>
                                    <Typography variant='h6' sx={{fontSize: '0.70rem', fontWeight: '400', color: grey[500]}}>{item.reservation_id}</Typography>
                                    <Stack>
                                        <Typography variant='body2' sx={{fontWeight: '600', color: grey[700], fontSize: '0.7rem', textTransform: 'capitalize', borderRadius: '0.25rem', width: 'fit-content'}}>status: <Box component='span' sx={{backgroundColor: MAIN_ORANGE_COLOR, padding: '0.0625rem 0.25rem', borderRadius: '0.25rem', color: 'white'}}>{item.status}</Box></Typography>
                                        <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[800], textTransform: 'capitalize'}}>
                                            Reserved By: {item.name}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        ))) :
                        <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>No Orders Yet</Typography>
                )}
            </Stack>
            <Typography variant='body2' sx={{fontSize: '0.75rem', color: grey[700], marginX: 'auto', marginBottom: '1rem'}}>end of results</Typography>
        </Stack>
    )
}

export default Reservations;