import {ApiRoute, CONSTANTS} from "../utils/constants";
import axios from "axios";

export const getLocationMenu = async (slug) => {
  try {
    let response = await axios.get(`${ApiRoute.getLocationMenu(slug)}`, {
      headers: {...CONSTANTS.REQUEST_HEADERS},
    });

    if (!response || !response.data) {
      return ({error: true, message: 'Cannot get Location Menu'})
    } else {
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot get Location Menu'})
  }
};

export const getSession = async (table_id) => {
  let response = await fetch(`${ApiRoute.getSession(table_id)}`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Location Menu: ${response}`);
};

export const getSessionRoom = async (room_id, loc_id) => {
  let response = await fetch(`${ApiRoute.getSessionRoom(room_id, loc_id)}`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Location Menu: ${response}`);
};
