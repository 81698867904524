import * as React from "react";
import {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useTheme} from "@mui/material/styles";
import {IconButton, Stack, useMediaQuery} from "@mui/material";
import {blue, green, pink, red} from "@mui/material/colors";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ScheduleIcon from '@mui/icons-material/Schedule';
import placeholder_restaurant from "../../assets/placeholder_restaurant.png";
import {register} from 'swiper/element/bundle';
import {URLS} from "../../utils/constants";
import {albumStyles} from "../../css/homePageStyles";
import Swiper from "swiper";
import dayjs from "dayjs";

register();

function Album({locations}) {

    const todayDate = dayjs().format('YYYY-MM-DD');
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const swiperRef = useRef(null);
    const navigate = useNavigate()

    useEffect(() => {
        if (swiperRef.current) {
            const swiper = new Swiper(swiperRef.current, {
                slidesPerView: xs ? 1 : sm ? 2.5 : md ? 4 : lg ? 5 : 6,
                spaceBetween: 20,
                loop: ((xs && locations.length > 1) || (sm && locations.length > 2) || (md && locations.length > 4) || (lg && locations.length > 5) || (locations.length > 6)),
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: true,
                centeredSlides: false,
                direction: 'horizontal',
            });
        }
    }, [xs, sm, md, lg]);

    const handleRestaurantSelect = (slug) => {
        navigate(`/restaurants/${slug}`)
    }

    const slidePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const slideNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (<>
            <Box>
                <Container maxWidth='xl'>
                    {locations.length > 0 ? (
                        <>
                            <Stack direction='row' sx={albumStyles.responsiveStack}>
                                <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600', color: 'black'}}>
                                    Popular nearby
                                    <Box component='span' sx={{marginLeft: '0.25rem', color: pink[200]}}>
                                        .
                                    </Box>
                                </Typography>
                                <Stack direction='row' columnGap='0.75rem'>
                                    <IconButton onClick={()=> slidePrev()}>
                                        <KeyboardArrowLeftIcon sx={{color: 'black'}}/>
                                    </IconButton>
                                    <IconButton onClick={()=> slideNext()}>
                                        <KeyboardArrowRightIcon sx={{color: 'black'}}/>
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Box sx={albumStyles.responsiveSliderStack}>
                                <Box ref={swiperRef} className="swiper-container" sx={{overflow: 'hidden'}}>
                                    <Box className="swiper-wrapper">
                                        {locations.map((item, index) => (
                                            <Box key={index} className="swiper-slide" style={{ backgroundColor: 'transparent' }}>
                                                <Stack>
                                                    <Stack
                                                        sx={{backgroundImage: (item.img !== null ? `url(${URLS.BaseUrlWithoutWebsite + "/" + item.img})` : `url(${placeholder_restaurant})`), ...albumStyles.sliderImageStyle}}
                                                        onClick={() => handleRestaurantSelect(item.slug)}>
                                                        <Stack direction='row' sx={{
                                                            padding: '0.5rem 1rem',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <Box sx={{
                                                                backgroundColor: 'black', ...albumStyles.sliderTypeStyle,
                                                                color: 'white',
                                                                border: '1px solid white'
                                                            }}>{item.category_name}</Box>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack sx={{alignItems: 'start', padding: '0.5rem 0', rowGap: '0.5rem'}}>
                                                        <Typography variant='body2' sx={{
                                                            color: 'black',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            cursor: 'pointer'
                                                        }} onClick={() => handleRestaurantSelect(item.slug)}>
                                                            {item.name}
                                                        </Typography>
                                                        <Stack direction='row' sx={{
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            width: '100%'
                                                        }}>
                                                            <Stack direction='row' columnGap='0.5rem'
                                                                   sx={{alignItems: 'center', justifyContent: 'center'}}>
                                                                <ScheduleIcon sx={{color: 'black', height: '15px', width: '15px'}}/>
                                                                <Typography variant='body2' sx={{
                                                                    color: 'black',
                                                                    fontSize: '12px',
                                                                    fontWeight: '400'
                                                                }}>
                                                                    {item.location_times.length <= 0 ? 'Closed' : dayjs().isBetween(dayjs(`${todayDate} ${item.location_times[0].start_time}`), dayjs(`${todayDate} ${item.location_times[0].end_time}`)) ? dayjs(`${todayDate} ${item.location_times[0].start_time}`).format('hh:mm a') + ' - ' + dayjs(`${todayDate} ${item.location_times[0].end_time}`).format('hh:mm a') : 'Closed'}
                                                                </Typography>
                                                            </Stack>
                                                            <Typography variant='body2' sx={{color: 'white', fontSize: '12px', fontWeight: '400', backgroundColor: (item.location_times.length <= 0 ? red[200] : dayjs().isBetween(dayjs(`${todayDate} ${item.location_times[0].start_time}`), dayjs(`${todayDate} ${item.location_times[0].end_time}`)) ? green[200] : red[200]), padding: '0.125rem 0.5rem', borderRadius: '15px'}}>
                                                                {item.location_times.length <= 0 ? 'closed' : dayjs().isBetween(dayjs(`${todayDate} ${item.location_times[0].start_time}`), dayjs(`${todayDate} ${item.location_times[0].end_time}`)) ? 'open' : 'closed'}
                                                            </Typography>
                                                        </Stack>
                                                        <Typography variant='body2'
                                                                    sx={{color: 'black', fontSize: '12px', fontWeight: '400'}}>
                                                            {item.location_telephone}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}>
                            No Restaurants Found
                        </Typography>
                    )}
                </Container>
            </Box>
        </>
    );
}

export default Album;
