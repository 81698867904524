import io from "socket.io-client";
import {URLS} from "../utils/constants";

// let URL = "https://server.servall.be";
//let URL = "http://192.168.100.69:4000";

class SocketService {
  constructor(url, token, location) {
    this.url = url;
    this.socket = null;
    this.token = token;
    // this.location = location;
    this.location = localStorage.getItem("location_id");
    this.tries = 0;
    this.connect();
  }

  connect() {
    this.socket = io(this.url, {
      auth: {
        token: null,
        location_id: this.location,
        role: "customer",
        type: "customer",
      },
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 15,
    });
    this.socket.on("connect", () => {
      this.socket.emit("joinSession");
      this.socket.emit("room");
    });

    this.socket.on("disconnect", () => {

    });

    this.socket.on("connect_error", (error) => {
    });
    this.socket.on("connect_timeout", (timeout) => {
    });

    this.socket.on("reconnect", (attemptNumber) => {
    });

    this.socket.on("reconnect_failed", () => {
    });
  }
  on(eventName, callback) {
    this.socket.on(eventName, callback);
  }

  emit(eventName, data) {
    this.socket.emit(eventName, data);
  }
  removeListener(eventName, callback) {
    this.socket.removeListener(eventName, callback);
  }

  disconnect() {
    this.socket.disconnect();
  }

  isConnected() {
    return this.socket && this.socket.connected;
  }
}

const SOCKET_URL = URLS.socketURL;

const socketService = new SocketService(SOCKET_URL, null, null);
export default socketService;
