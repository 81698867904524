import {useTheme} from "@mui/material/styles";
import {Stack, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import addon1 from "../../assets/images/homePage/1-01.jpg";
import addon2 from "../../assets/images/homePage/1-03.jpg";
import addon3 from "../../assets/images/homePage/1-02.jpg";
import {proccessesStyles} from "../../css/homePageStyles";

const ProcessesSection = () => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));

    const data = [
        {
            number: "01",
            title: "No Minimum Order",
            description: "Order in for yourself or for the group, with no restrictions on order value.",
            image: addon1
        },
        {
            number: "02",
            title: "Live Order Tracking",
            description: "Know where your order is at all times, from the restaurant to your app.",
            image: addon2
        },
        {
            number: "03",
            title: "Lightning-Fast Delivery",
            description: "Experience fast and reliable delivery at every stage of your order.",
            image: addon3
        }
    ]

    return(
        <Box>
            <Container maxWidth='xl'>
                <Box sx={proccessesStyles.mainResponsiveStack}>
                    <swiper-container style={{
                        "--swiper-navigation-color": "#000",
                        "--swiper-pagination-color": "#000"
                    }}
                                      loop="true" navigation="false" space-between={20} autoplay="true"
                                      centered-slides="false" slides-per-view={xs ? 1 :sm ? 1 : md ? 2 : lg ? 3 : 3}
                    >
                        {data.map((item, index) => (
                            <swiper-slide key={index} style={{backgroundColor: 'transparent'}}>
                                <Box sx={{borderRadius: '5px', overflow: 'hidden'}}>
                                    <Stack sx={{...proccessesStyles.mainStack, backgroundImage: 'url(' + item.image + ')'}}>
                                        <Typography variant='body2' sx={{fontSize: '40px', fontWeight: '200', color: 'white', marginX: '2rem'}}>
                                            {item.number}
                                        </Typography>
                                        <Typography variant='body2' sx={{marginBottom: '0.5rem',fontSize: '17px', fontWeight: '500', color: 'white', marginX: '2rem'}}>
                                            {item.title}
                                        </Typography>
                                        <Typography variant='body2' sx={{fontSize: '13px', fontWeight: '400', color: 'white', textAlign: 'start', marginX: '2rem'}}>
                                            {item.description}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </swiper-slide>
                        ))}
                    </swiper-container>
                </Box>
            </Container>
        </Box>
    )
}

export default ProcessesSection