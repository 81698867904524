import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import {QRCodeSVG} from "qrcode.react";
import {MAIN_GREEN_COLOR} from "../colors";
import {saveSurveyQuestions} from "../services/surveyServices";
import SnackBar from "./snackbar";
import {CloseOutlined} from "@mui/icons-material";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {inputFieldsStyle} from "../css/orderPlacementStyles";

const SurveyModal = ({openSurveyModal, setOpenSurveyModal, surveyQuestions, location_id, tempSessionId}) => {
    const [ratings, setRatings] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [qrCodeValue, setQRCodeValue] = useState({ title: "", question: "" });
    const isMobile = useMediaQuery("(max-width:600px)");

    const [snackbarparams, setSnackbararams] = useState({
        open: false,
        message: "",
        severity: "",
    })

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const openQRCodeModal = (value) => {
        setQRCodeValue(value);
        toggleModal();
    };

    const handleRatingChange = (value, index, id) => {
        const newRatings = [...ratings];
        newRatings[index] = {
            response: value,
            question_id: id,
            location_id,
            session_id: tempSessionId,
        };
        setRatings(newRatings);};

    const saveSurvey = async () => {

        const data = await saveSurveyQuestions(location_id, ratings);

        if (!data.error) {
            setSnackbararams({
                open: true,
                message: "Response Submitted Successfully",
                severity: "success",
            })
            setOpenSurveyModal(false);
        } else {
            setSnackbararams({
                open: true,
                message: "Error Submitting Survey",
                severity: "error",
            })
        }
    };

    return(
        <>
            <Dialog
                maxWidth="md"
                PaperProps={{
                    style: isMobile
                        ? {
                            margin: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                        }
                        : { minWidth: "400px" },
                }}
                open={openSurveyModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{
                        backgroundColor: MAIN_GREEN_COLOR,
                        color: "#fff",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0.75rem 1rem",
                    }}
                >
                    <Typography variant='h6' style={{ fontSize: '1rem', fontWeight: '600' }}>
                        Survey Questions
                    </Typography>
                    <IconButton
                        size={'small'}
                        style={{
                            color: "#fff",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
                        }}
                        onClick={()=> setOpenSurveyModal(false)}
                    >
                        <CloseOutlined sx={{height: '1rem', width: '1rem'}} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {surveyQuestions.length > 0 ? (
                        <>
                            {/* Render questions other than type "Link" */}
                            {surveyQuestions
                                .filter(
                                    (question) =>
                                        question.status !== "disabled" && question.type !== "Link"
                                )
                                .map((question, index) => (
                                    <div key={index}>
                                        <Typography variant='body2' style={{ textTransform: 'capitalize', fontSize: "0.9rem", marginTop: "10px", fontWeight: '600' }}>
                                            {question.question}
                                        </Typography>

                                        <Stack direction='row' sx={{alignItems: 'center'}}>
                                            {question.type === "Ratings" &&
                                                [1, 2, 3, 4, 5].map((starValue) => (
                                                    <StarRoundedIcon key={starValue} style={{color: starValue <= ratings[index]?.response ? "gold" : "gray", cursor: "pointer", height: '20px', width: '20px', transition: '0.45s'}}
                                                                     className={starValue <= ratings[index]?.response ? "star filled" : "star"}
                                                                     onClick={() => handleRatingChange(starValue, index, question.question_id)}>
                                                    </StarRoundedIcon>
                                                ))}
                                        </Stack>

                                        {question.type !== "Ratings" && (
                                            <OutlinedInput sx={inputFieldsStyle}
                                                           placeholder="Add your feedback here"
                                                           fullWidth
                                                           size={'small'}
                                                           onChange={(e) =>
                                                               handleRatingChange(
                                                                   e.target.value,
                                                                   index,
                                                                   question.question_id
                                                               )
                                                           }
                                            />
                                        )}
                                    </div>
                                ))}
                            {surveyQuestions
                                .filter(
                                    (question) =>
                                        question.status !== "disabled" && question.type === "Link"
                                )
                                .reduce((rows, question, index) => {
                                    if (index % 5 === 0) {
                                        rows.push([]);
                                    }
                                    rows[rows.length - 1].push(question);
                                    return rows;
                                }, [])
                                .map((row, rowIndex) => (
                                    <Stack direction='row' key={rowIndex} sx={{alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem'}}>
                                        {row.map((question, index) => (
                                            <Box key={index}>
                                                <Button outline className="btn-icon" color="primary" onClick={() => openQRCodeModal(question)}>
                                                    <Box>
                                                        <QRCodeSVG value={question.question} size={80} fgColor="black"/>
                                                        <Typography sx={{color: "black", fontSize: "10px", textTransform: "capitalize", fontFamily: "Arial, sans-serif", fontWeight: "bold"}}>
                                                            {"Follow Us on "}
                                                            <Box component={'span'} sx={{fontStyle: "italic", fontSize: "13px", textDecoration: "underline", color: "green",}}>{question.title}</Box>
                                                        </Typography>
                                                    </Box>
                                                </Button>
                                            </Box>
                                        ))}
                                    </Stack>
                                ))}

                            <Modal open={modalOpen} onClose={toggleModal} sx={{display: "flex", alignItems: "center", justifyContent: "center",}}>
                                <Stack sx={{ backgroundColor: 'white', padding: '1rem 1.5rem', borderRadius: '0.25rem' }}>
                                    <IconButton size='small' aria-label="close" onClick={toggleModal} sx={{ marginLeft: 'auto' }}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" sx={{marginBottom: '0.5rem',textAlign: "center", textTransform: "capitalize", fontFamily: "Arial, sans-serif", fontWeight: "bold",}}>
                                        {qrCodeValue.title}
                                    </Typography>
                                    <QRCodeSVG value={qrCodeValue.question} size={200} />
                                </Stack>
                            </Modal>

                        </>
                    ) : (
                        <p>No questions found.</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={saveSurvey}
                        color="primary"
                        autoFocus
                        style={{
                            textTransform: 'capitalize',
                            backgroundColor: MAIN_GREEN_COLOR, // Use your primary color here
                            color: "#fff",
                            width: "100%",
                        }}
                    >
                        Submit Survey
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar snackbarParams={snackbarparams} setSnackbarParam={setSnackbararams} />
        </>
    )
}

export default SurveyModal;