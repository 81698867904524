import {Button, IconButton, Snackbar, SnackbarContent} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {clearCart} from "../features/cartSlice";
import {useDispatch} from "react-redux";
import {red} from "@mui/material/colors";

const CartFromOtherLocation = ({setCartFromOtherLocation, cartFromOtherLocation}) => {
    const dispatch = useDispatch()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCartFromOtherLocation(false);
    };

    const action = (
        <React.Fragment>
            <Button sx={{marginRight: '0.5rem', backgroundColor: 'white', textTransform: 'capitalize', fontSize: '0.9rem', fontWeight: '600', color: 'black', textAlign: 'center', '&:hover': {backgroundColor: 'white'}}} size="small" onClick={()=> {dispatch(clearCart()); setCartFromOtherLocation(false)}}>
                Clear
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                onClick={handleClose}
                sx={{color: 'white'}}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return(
        <Snackbar
            sx={{width: '400px'}}
            open={cartFromOtherLocation}
            onClose={handleClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        >
            <SnackbarContent sx={{backgroundColor: red[700]}}
                             action={action}
                             onClose={handleClose}
                             message="Your cart contains item from other restaurant or hotel. Please, clear the cart first to continue."
            />
        </Snackbar>
    )
}

export default CartFromOtherLocation