import {Button, Stack, TextField, Typography} from "@mui/material";
import {green} from "@mui/material/colors";
import {filledInputFieldsStyle, inputFieldsStyle} from "../../css/orderPlacementStyles";
import {MAIN_GREEN_COLOR} from "../../colors";

const PhoneSelection = ({setFlow, setData, data, setPage}) => {
    const handleInputChange = (e, label) => {
        setData({
            ...data,
            [label]: e.target.value
        })
        //(e.target.value, label)
    }

    return(
        <>
            <Stack sx={{width: '100%'}}>
                <Typography variant='body2' sx={{fontSize: '12px'}}>Enter your phone number</Typography>
                <TextField variant='filled' sx={filledInputFieldsStyle} label={'Phone Number'} fullWidth type={'phone'} value={data.email} onChange={(e)=> handleInputChange(e, "phone")}/>
            </Stack>
            <Button sx={{backgroundColor: MAIN_GREEN_COLOR, color: 'white', padding: '0.75rem 1rem', textTransform: 'capitalize', fontSize: '16px', borderRadius: '10px'}} fullWidth onClick={()=> setFlow('code')}>Next</Button>
            <Stack sx={{alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem'}} direction='row'>
                <Typography variant='body2' sx={{fontSize: '12px'}}>Have an account?</Typography>
                <Typography variant='body2' sx={{fontSize: '14px', cursor: 'pointer', color: green['A400']}} onClick={()=> setPage('signin')}>Sign in</Typography>
            </Stack>
        </>
    )
}

export default PhoneSelection