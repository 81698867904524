import {Drawer, IconButton, Stack} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import DeliveryPickupMobileCartItems from "./deliveryPickupMobileCartItems";
import {useDispatch, useSelector} from "react-redux";
import {setOpenCartDrawer} from "../../features/variablesSlice";

const DeliveryPickupMobileCart = () => {
    const dispatch = useDispatch();
    const openCartDrawer = useSelector(state => state.variablesData.openCartDrawer);

    return(
        <Drawer open={openCartDrawer} onClose={()=> dispatch(setOpenCartDrawer(false))} anchor={'right'}>
            <Stack sx={{width: {xs: '90vw', sm: '320px'}, maxWidth: '320px', padding: '1rem', rowGap: '0.5rem'}}>
                <Stack sx={{display: 'flex', alignItems: 'end', justifyContent: 'center'}}>
                    <IconButton size='small' onClick={()=> dispatch(setOpenCartDrawer(false))}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}} />
                    </IconButton>
                </Stack>
                <DeliveryPickupMobileCartItems />
                </Stack>
        </Drawer>
    )
}

export default DeliveryPickupMobileCart