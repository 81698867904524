export const largeNavbarStyles = {

}

export const navbarSelect = {
    '& .MuiOutlinedInput-root': {
        border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSelect-select': {
        padding: '0px 14px',
        fontSize: '14px',
        fontWeight: '800'
    }
}