import React, {useEffect, useState} from "react";
import SearchHero from "../sections/home/searchHero";
import Album from "../sections/home/album";
import CuisineType from "../sections/home/cuisineType";
import ProcessesSection from "../sections/home/processesSection";
import DiscountedRestaurants from "../sections/home/discountedRestaurants";
import JoinUs from "../sections/home/joinUs";
import NewRestaurants from "../sections/home/newRestaurants";
import DownloadApplications from "../sections/home/downloadApplications";
import SomethingNew from "../sections/home/somethingNew";
import {getLocations} from "../services/LocationService";
import Loader from "../components/Loader";
import GoToTop from "../components/goToTop";
import dayjs from "dayjs";
import {Stack} from "@mui/material";

const Home = () => {

    const [locations, setLocations] = useState([]);
    const [loading, setIsLoading] = useState(true);

    const fetchData = async () => {
        setIsLoading(true)
        let response = await getLocations();

        if (response.error) {
            console.error(response.message)
        } else {
            response = getFilteredLocations(response.data)
            setLocations(response);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getFilteredLocations = (locationList) => {
        const data = locationList.filter(location => (location.visible && location.is_hotel === 0))

        data.forEach((location, index) => {
            let flag = false
            location.location_times = location.location_times.filter(item => {
                if ((((dayjs().format('dddd')).toLowerCase()) === item.day_name) && item.status === true) {
                    flag = true
                    return item
                }
            });
            if (!flag) {
                location.location_times = []
            }
        });

        return data;
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (<>
                <SearchHero />
                <Stack sx={{rowGap: '2rem', paddingY: '2rem'}}>
                    <CuisineType />
                    <Album locations={locations}/>
                    <ProcessesSection />
                    <DiscountedRestaurants />
                    <JoinUs />
                    <NewRestaurants locations={locations}/>
                    <DownloadApplications />
                    <SomethingNew locations={locations}/>
                    <GoToTop />
                </Stack>
            </>)}
        </>
    );
}

export default Home