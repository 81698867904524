import {useState} from "react";
import {Box, Stack, TextField, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import {inputFieldsStyle} from "../css/orderPlacementStyles";

const ForgotPassword = ({setPage}) => {
    const [fields, setFields] = useState({
        email: '',
    })

    const handleInputChange = (e, label) => {
        setFields({
            [label]: e.target.value
        })
    }
    return(
        <Box>
            <Container maxWidth='xl' sx={{padding: '3rem 0'}}>
                <Stack sx={{alignItems: 'center', justifyContent: 'start', rowGap: '1rem', width: 'fit-content', maxWidth: '335px', marginX: 'auto'}}>
                    <Typography variant='h5' sx={{fontSize: '16px', fontWeight: '600'}}>Forgot Password</Typography>
                    <Typography variant='body2' sx={{fontSize: '12px', width: 'inherit'}}>Please specify your email address to receive instructions for resetting it. If an account exists by that email, we will send a password reset</Typography>
                    <TextField sx={inputFieldsStyle} fullWidth placeholder={'Email'} variant={'outlined'} type={'email'} value={fields.email} onChange={(e)=> handleInputChange(e, "email")}/>
                    <Stack sx={{alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem'}} direction='row'>
                        <Typography variant='body2' sx={{fontSize: '12px'}}>Have an account?</Typography>
                        <Typography variant='body2' sx={{fontSize: '14px', cursor: 'pointer', color: green['A400']}} onClick={()=> setPage('signin')}>Sign in</Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

export default ForgotPassword