// userSlice.js
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  user: {
    user_type: 'guest',
    phoneNumber: '',
    order_type: 'non',
    location: {
      lat: 51.509865,
      long: -0.118092,
      streetName: '',
      streetNumber: '',
      house: '',
      city: '',
      country: '',
      address: 'Select an Address'
    },
    deliveryOptions: {},
    orderDateTime: {
      schedule: 'now',
      date: '',
      timeSlot: '',
      start_time: '',
      end_time: '',
      formattedDate: ''
    },
    firstName: '',
    lastName: '',
    email: '',
    isDeliverable: 'not_defined',
    loggedInUserInformation: {}
  },
  selectedTab: null,
  selectedTabMobile: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLocation(state, action) {
      state.user.location = {...state.user.location, ...action.payload.location};
    },
    setUserDeliveryOptions(state, action) {
      state.user.deliveryOptions = {...state.user.deliveryOptions, ...action.payload.deliveryOptions};
    },
    setUserDeliveryStatus(state, action) {
      state.user.isDeliverable = action.payload.isDeliverable
    },
    setUserInformation(state, action) {
      state.user = {...state.user, ...action.payload}
    },

    setOrderDateTime(state, action) {
      state.user.orderDateTime = {...state.user.orderDateTime, ...action.payload.orderDateTime}
    },

    setUser(state, action) {
      state.user = action.payload;
    },

    setUserLoggedInInformation(state, action) {
      state.user.loggedInUserInformation = {...state.user.loggedInUserInformation, ...action.payload.loggedInUserInformation}
      state.user.user_type = "logged_in";
      state.user.phoneNumber = action.payload.loggedInUserInformation.number;
    },

    setTab(state, action) {
      state.selectedTab = action.payload;
    },
    setTabForMobile(state, action) {
      state.selectedTabMobile = action.payload;
      // state.user = action.payload;
    },
  },
});

export const { setUser, setTab, setTabForMobile, setUserLocation, setUserInformation, setUserDeliveryOptions, setUserDeliveryStatus, setOrderDateTime, setUserLoggedInInformation } = userSlice.actions;
export default userSlice.reducer;
