import React from 'react';
import {Box, IconButton, Stack, Typography} from '@mui/material';
import bgImg from "../../assets/images/homePage/header_banner_1.jpg";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {grey} from "@mui/material/colors";
import {heroStyles} from "../../css/homePageStyles";
import LocationAutoComplete from "../../components/generic/locationAutoComplete";

const SearchHero = () => {
    return (
        <Box sx={{maxWidth: '100vw', overflow: 'hidden'}}>
            <Box sx={{background: 'url(' + bgImg + ')', ...heroStyles.mainBox}}>
                <Stack sx={{rowGap: '1rem', display: {xs: 'none', md: 'flex'}}}>
                    <Typography variant='h4'>Let's find best food for you</Typography>
                    <Stack direction='row' columnGap={'1rem'} sx={{width: {md: '500px', lg: '525px'}}}>
                        <LocationAutoComplete usage={"hero"}/>
                        <IconButton sx={heroStyles.iconButtonStyle}>
                            <ArrowForwardIcon sx={heroStyles.arrowForwardStyles} />
                        </IconButton>
                    </Stack>
                </Stack>
            </Box>
            <Stack sx={{rowGap: '1rem', display: {xs: 'flex', md: 'none', padding: '1rem 2rem', borderBottom: `1px solid ${grey[200]}`}}}>
                <Typography variant='h4' sx={{fontSize: {xs: '20px', sm: '23px'}}}>Let's find best food for you</Typography>
                <Stack direction={{xs: 'column', sm: 'row'}} sx={heroStyles.smallScreenMainStack}>
                    <LocationAutoComplete usage={"hero"}/>
                    <IconButton sx={heroStyles.iconButtonStyle}>
                        <ArrowForwardIcon sx={heroStyles.arrowForwardStyles} />
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    );
};

export default SearchHero;
